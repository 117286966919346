import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDropdown from "./CustomDropdown";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import {
  createNewTask,
  getMilestonesForCampaign,
  getOrganicInfluencers,
  getTasks,
  updateCurrentTask,
} from "../../../../services/tasks";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import FormDateRangeCustomPicker from "../../../common/formdaterangepicker";
import moment from "moment";
import { getListsForTemplate } from "../../../../services/contracts";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import TransferList from "../../../common/TransferList";
import { Button } from "react-bootstrap";
import { Close, Edit, Person } from "@mui/icons-material";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../../common/StyledComponents";
import { notify } from "../../../../redux/features/system/systemAlert";

const TaskForm = ({
  cid,
  handleClose,
  open,
  setOpen,
  formType,
  setFormType,
  taskId,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [milestones, setMilestones] = React.useState([]);
  const [milestoneId, setMilestoneId] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(""); // To store the selected option's ID
  const [backdropShow, setBackdropShow] = React.useState(false);
  const [loaderMsg, setLoaderMsg] = React.useState("");
  const [charCount, setCharCount] = React.useState(100);
  const [listData, setListData] = React.useState(null);
  const [openTl, setOpenTl] = React.useState(false);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [manageAssignee, setManageAssignee] = React.useState(false);
  const [listInfCount, setListInfCount] = React.useState(0);
  const [listMsg, setListMsg] = React.useState("");
  const [showTransfer, setShowTransfer] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [existingIids, setExistingIids] = React.useState([]);
  const [list, setList] = React.useState(null);
  const [maxLength, setMaxLength] = React.useState(1000);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust the width as needed
    backgroundColor: "white",
    boxShadow: 24,
  };
  const quillRef = React.useRef(null);
  const [content, setContent] = React.useState("");
  const [state, setState] = React.useState({
    title: "",
    html: "",
    startDate: moment().clone().add(1, "day"),
    endDate: moment().clone().add(31, "days"),
    priority: "medium",
    list: null,
  });
  const toTitleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const closeTransferList = (e) => {
    setRight([]);
    setOpenTl(false);
    setManageAssignee(false);
  };
  React.useEffect(() => {
    getAllLists();
    getAllMilestones();
    getGroupData();
  }, [cid, formType, loaderMsg]);
  React.useEffect(() => {
    const msg =
      right.length > 0
        ? right.length === listInfCount
          ? "All influencer(s) selected"
          : `${right.length} influencer(s) selected`
        : "";
    setListMsg(msg);
  }, [right]);
  React.useEffect(() => {
    if (state.list !== null) {
      if (openTl === true || manageAssignee === true) {
        getInfluencers(cid, 0, state.list.id);
      }
    }
  }, [state.list, openTl, manageAssignee]);

  const getGroupData = async () => {
    if (formType === "Create" || formType === "View") {
      if (formType === "View") {
        setBackdropShow(true);
        setLoaderMsg("Fetching task data... please wait.");
      }
      const response = await getListsForTemplate(cid, user, dispatch);
      const listData = response.response;
      if (formType === "View" && listData) {
        const response = await getTasks(taskId, user, dispatch);
        const data = response.response;
        const sDate = new Date(data[0].startdate);
        const eDate = new Date(data[0].enddate);
        const { title, description, priority } = data[0];
        setMilestoneId(data[0].mid);
        setSelectedOption({ id: data[0].mid, label: data[0].milestone });
        const item = listData.find((item) => item.id === data[0].lid);
        setList(item);
        setState({
          ...state,
          title: title,
          html: description,
          priority: priority,
          startDate: sDate,
          endDate: eDate,
          list: item,
        });
        const resp = await getInfluencers(0, 0, item.id);
        setContent(description);
        var result = data[0].iids.split(",").map(Number);
        setExistingIids(result);

        setBackdropShow(false);
      }
    }
  };
  const getInfluencers = async (cid, template, id) => {
    const response = await getOrganicInfluencers(id, user, dispatch);
    const res = response.response;
    const influencers = res?.length > 0 ? res : [];
    const left = [];
    const right = [];
    setListInfCount(influencers.length);
    influencers.forEach((influencer) => {
      const inf = {};
      inf["id"] = influencer.iid;
      if (influencer.infName === "") {
        return;
      }
      inf["label"] = influencer.infName;
      inf["disabled"] = false;
      if (!existingIids.includes(influencer.iid)) {
        left.push(inf);
      } else {
        right.push(inf);
      }
    });

    const msg =
      right.length > 0
        ? right.length === listInfCount
          ? "All influencer(s) selected"
          : `${right.length} influencer(s) selected`
        : "";
    setListMsg(msg);
    setLeft(left);
    setRight(right);
    setShowTransfer(true);
    if (formType !== "View") {
      setOpenTl(true);
    }
    setIsLoaded(true);
  };
  const getAllLists = async () => {
    const response = await getListsForTemplate(cid, user, dispatch);
    const listData = response.response;
    setListData(listData ? listData : []);
  };
  const getAllMilestones = async () => {
    const milestones = await getMilestonesForCampaign(cid, user, dispatch);
    if (milestones.status === true) {
      setMilestones(milestones.response);
    }
  };
  const handlePostInput = (content) => {
    setState({ ...state, html: content });
  };

  const createTask = async (e) => {
    dispatch(
      notify({
        message: "Please wait... we are creating your task",
        type: "info",
      })
    );
    e.preventDefault();
    if (state.html === "") {
      alert("Please add a description for the task");
      return;
    }
    const id_list = right.map((item) => item.id);
    const { title, html, startDate, endDate, priority, list } = state;
    const payload = {
      title,
      description: html,
      startdate: formatDate(startDate),
      enddate: formatDate(endDate),
      priority,
      lid: list.id,
      milestone: milestoneId,
      iid: id_list,
    };
    const response = await createNewTask(payload, user, dispatch);
    if (response.status) {
      dispatch(
        notify({
          message: "Task Created Succesfully",
          type: "success",
        })
      );
      setOpen(false);
      handleClose(false);
    }
  };

  const updateTask = async (e) => {
    dispatch(
      notify({
        message: "Please wait... we are updating your task",
        type: "info",
      })
    );
    e.preventDefault();
    if (state.html === "") {
      alert("Please add a description for the task");
      return;
    }
    const { title, html, startDate, endDate, priority, list } = state;
    const payload = {
      id: taskId,
      title,
      description: html,
      startdate: formatDate(startDate),
      enddate: formatDate(endDate),
      priority,
      lid: list.id,
      milestone: milestoneId,
      iid: existingIids,
    };
    const response = await updateCurrentTask(payload, user, dispatch);
    if (response.status) {
      dispatch(
        notify({
          message: "Task Updated Succesfully",
          type: "success",
        })
      );
      setOpen(false);
      handleClose();
    }
  };

  const formatDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    // Extract year, month, and day components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(inputDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const sendSelectedDates = ({ startDate, endDate }) => {
    setState((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };
  return (
    <>
      {" "}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>{loaderMsg}</Box>
      </Backdrop>
      {isLoaded && showTransfer && (
        <Modal open={openTl} onClose={handleClose}>
          <Fade in={openTl} timeout={500}>
            <Box sx={modalStyle}>
              <div className="form-group" style={{ padding: "10px" }}>
                <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                  <FormControl fullWidth>
                    <TransferList
                      left={left}
                      setLeft={setLeft}
                      right={right}
                      setRight={setRight}
                    />
                  </FormControl>

                  <FormHelperText
                    style={{ marginTop: "1rem", marginLeft: "2rem" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        className="postReview-btn"
                        id="postReview-approve-btn"
                        onClick={() => {
                          closeTransferList();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        className="postReview-btn"
                        id="postReview-proideFeedback-btn"
                        onClick={() => {
                          setOpenTl(false);
                          setManageAssignee(false);
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                    <br />* The transfer list does not include any direct
                    influencers
                  </FormHelperText>
                </Grid>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
      <Box
        m={3}
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        position={"static"}
      >
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <h2
              id="modal-add_user-heading-text"
              style={{ fontSize: "2em", fontWeight: "bold", margin: "0" }}
            >
              {formType} Task
            </h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                setFormType("Edit");
              }}
            >
              <Tooltip title={"Edit Task"}>
                <Edit fontSize="small" />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box
        m={3}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        position={"static"}
      >
        <Grid container item spacing={1}>
          <Grid item md={6} xs={6}>
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              <b>Task Title *</b>
            </p>
            <TextField
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Choose a title for the task you are creating"
              type="text"
              value={state.title}
              disabled={formType === "View" ? true : false}
              required
              onChange={(e) => {
                const titleCaseValue = toTitleCase(e.target.value);
                setState({ ...state, title: titleCaseValue });
              }}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              <b>Task's Milestone *</b>
            </p>
            <CustomDropdown
              milestones={milestones}
              cid={cid}
              milestoneId={milestoneId}
              setMilestoneId={setMilestoneId}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              disabled={formType === "View" ? true : false}
              setBackdropShow={setBackdropShow}
              setLoaderMsg={setLoaderMsg}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              <b>Start and End Date *</b>
            </p>
            <FormDateRangeCustomPicker
              sendSelectedDates={sendSelectedDates}
              parentStartDate={state.startDate}
              parentEndDate={state.endDate}
              needRange={false}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              <b>Priority *</b>
            </p>
            <Select
              sx={{ width: "100%" }}
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.priority}
              disabled={formType === "View" ? true : false}
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  priority: event.target.value,
                }));
              }}
            >
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </Grid>
          <Grid item md={12} xs={12}>
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              <b>Task Description *</b>
            </p>
            <ReactQuill
              ref={quillRef}
              value={state.html}
              onChange={(event) => handlePostInput(event)}
              readOnly={formType === "View" ? true : false}
              modules={{
                toolbar: {
                  container: [["bold", "italic", "underline", "strike"]],
                },
              }}
              style={{ height: "200px", marginBottom: "40px" }} // Add spacing here
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              position={"static"}
            >
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Group Name *</b>
              </p>
              {formType !== "Create" && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* {formType === "View" && (
                    <span>
                      {" "}
                      <label>
                        <Person /> Assignee:{" "}
                      </label>{" "}
                      {listMsg}
                    </span>
                  )} */}
                  {formType === "Edit" && (
                    <span>
                      <Button
                        style={{ backgroundColor: "#BDBDBD" }}
                        disabled={formType === "View" ? true : false}
                        variant="contained"
                        size="small"
                        className="postReview-btn"
                        id="postReview-approve-btn"
                        onClick={() => {
                          setOpenTl(true);
                          setManageAssignee(true);
                        }}
                      >
                        Manage Assignee
                      </Button>
                    </span>
                  )}
                </div>
              )}
            </Box>
            <Autocomplete
              disablePortal
              disableClearable
              id="combo-box-demo"
              getOptionLabel={(option) => option.name}
              options={listData ? listData : []}
              value={state.list}
              disabled={formType === "View" ? true : false}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography sx={textColor[option.platform]}>
                    {snapshotHeaderIcons[option.platform]}
                  </Typography>
                  &nbsp;
                  {option.name}
                </Box>
              )}
              onChange={(e, value) => {
                setState({ ...state, list: value });
                // getInfluencers(cid, 0, value.id);
                setOpenTl(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose the group of influencers for the task"
                />
              )}
            />
          </Grid>
          <Grid item md={8.5} xs={8.5}></Grid>
          <Grid item md={3.5} xs={3.5}>
            <StyledStaticButtonGroup
              color="primary"
              size="small"
              exclusive
              aria-label="Campaign"
              disabled={
                !state.title ||
                !state.html ||
                !milestoneId ||
                !state.priority ||
                !state.list ||
                formType === "View"
              }
            >
              <StyledStaticButton
                value="active"
                className={styles.staticButton}
                onClick={formType === "Create" ? createTask : updateTask}
              >
                <b>{formType !== "Create" ? "Update Task" : "Create Task"}</b>
              </StyledStaticButton>
            </StyledStaticButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TaskForm;
