import { Circle, Warning } from "@mui/icons-material";
import React from "react";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import CampaignGroupsFilter from "./CampaignGroupsFilter";
import { groupCodes } from "../../../../utility/status";
import { tableHeaders } from "./CommonAssets";
import { getDetailedGroupsForCampaign, updateGroup } from "../../../../services/group";
import moment from "moment";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useLocation } from "react-router-dom";
import { InfoToolTip } from "../../../common/toolTip";
import { getEngagementBreakup } from "../../../../utility/postOpertions";
import { capitalizeFirstLetter, convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import Loader from "../../../common/loader";
import { onDownloadHandle, downloadNotification } from "../../../../utility/exportData";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignName } from "../../../../utility/sessionData";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import { getCampaignOverview } from "../../../../services/campaignAnalytics";
import CampaignGroupsActionButton from "./CampaignGroupsActionButton";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { getTimeZone } from "../../../../services/common";

const CampaignGroupsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);
  const [allAllowedPlatforms, setAllAllowedPlatforms] = React.useState([]);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("infCount");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [totalRows, setTotalRows] = React.useState(10);
  const [startDate, setStartDate] = React.useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(Object.keys(snapshotHeaderIcons));
  const [headers, setHeaders] = React.useState(tableHeaders);
  const [filter, setFilter] = React.useState("");
  const [lastRefreshed, setLastRefreshed] = React.useState("");
  const [mimeType, setMimeType] = React.useState("");
  const [campaignName] = React.useState(getCampaignName);
  const [fileName, setFileName] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const dispatch = useDispatch();
  const timeZone = getTimeZone();
  const universal = params.get("universal");
  React.useEffect(() => {
    setRefresh(false);
    loadBasicData();
    getGroupDetails(
      +params.get("id"),
      selectedPlatforms,
      page,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      null,
      null,
      false,
      mimeType,
      fileName,
      timeZone
    );
  }, [filter, refresh, selectedPlatforms, startDate, endDate, headers, params.get("id")]);

  const loadBasicData = async () => {
    setLoading(true);
    setDataFound(false);
    const campaignDetails = await getCampaignOverview(params.get("id"), user, dispatch);
    if (campaignDetails.response) {
      const { platforms } = campaignDetails.response.overallSummary[0];
      setAllAllowedPlatforms(platforms);
    }
  };

  const setReload = () => {
    setRefresh(true)
  }

  //extract group details
  const getGroupDetails = async (
    id,
    selectedPlatforms,
    page,
    rows,
    sortCol,
    sortOrder,
    exportValue,
    filter,
    startDate,
    endDate,
    isRefresh,
    mimeType,
    fileName,
    timezone
  ) => {
    if (exportValue === 1) {
      downloadNotification(dispatch);
      const fileName = "Group_" + campaignName;
      setFileName(fileName);
      const downloadData = await getDetailedGroupsForCampaign(
        id,
        selectedPlatforms,
        page + 1,
        rows,
        sortCol,
        sortOrder,
        exportValue,
        filter,
        startDate,
        endDate,
        isRefresh,
        mimeType,
        fileName,
        timezone,
        user,
        dispatch
      );
      if (downloadData.response !== undefined) {
        onDownloadHandle(new Blob([downloadData.response]), fileName + "." + mimeType);
      } else {
        dispatch(
          notify({
            message: "No data to download",
            type: "error",
          })
        );
      }
    } else {
      setLoading(true);
      const groups = await getDetailedGroupsForCampaign(
        id,
        selectedPlatforms,
        page + 1,
        rows,
        sortCol,
        sortOrder,
        exportValue,
        filter,
        startDate,
        endDate,
        isRefresh,
        mimeType,
        fileName,
        timeZone,
        user,
        dispatch
      );
      setLoading(false);
      const {
        response: { records, lastRefreshed, total_records },
        status,
      } = groups;
      if (status === true && records?.length > 0) {
        setLastRefreshed(lastRefreshed);
        setTotalRows(total_records);
        createData(records);
        setDataFound(true);
      } else {
        setDataFound(false);
        if (groups.response?.message?.includes("expired")) {
          dispatch(logout());
        }
        dispatch(
          notify({
            message: groups.response?.message,
            type: "error",
          })
        );
      }
    }
  };

  //create table data
  const createData = (records) => {
    const columns = [];
    records.forEach((data) => {
      let object = {
        id: data.id,
        groupname: (
          <Grid container spacing={4}>
            <Grid item xs={2}>
              {data.country === "" && (
                <Tooltip title={"Country is not assigned to this list"}>
                  <Warning sx={{ width: 25, height: 25, color: "#FED330" }} />
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}>
              <Typography sx={textColor[data.platform]}>{snapshotHeaderIcons[data.platform]}</Typography>
            </Grid>
            <Grid item xs={8} className={styles.bodycell} style={{ marginTop: 5 }}>
              {data.groupName}
            </Grid>
          </Grid>
        ),
        influencers: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {data.totalInf}
            </Grid>
          </Grid>
        ),
        totalpost: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {data.totalPost}
            </Grid>
          </Grid>
        ),
        lastpost: (
          <Grid container spacing={1}>
            <Grid item xs={8} className={styles.bodycell}>
              {data.lastPostedOn
                ? data.lastPostedOn?.split(" - ")?.length > 1
                  ? data.lastPostedOn?.split(" - ")[1]
                  : data.lastPostedOn?.split(" at ")[0]
                : "-"}
            </Grid>
          </Grid>
        ),

        totalengagement: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={8} className={styles.bodycell}>
              {data.engagement > 0 ? convertToInternationalNumberSystem(data.engagement) : "-"}
              &nbsp;&nbsp;
              {data.engagement > 0 && <InfoToolTip text={getEngagementBreakup(data)} />}
            </Grid>
          </Grid>
        ),
        totalreach: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={6} className={styles.bodycell}>
              {data.totalReach > 0 ? convertToInternationalNumberSystem(data.totalReach ? data.totalReach : 0) : "-"}

            </Grid>
          </Grid>
        ),
        status: (
          <span style={{ position: "absolute", marginTop: "-20px", marginLeft: "-25px" }}>
            <DropdownButton
              as={ButtonGroup}
              key={data.status.toLowerCase()}
              id={`dropdown-variants-${data.status.toLowerCase()}`}
              variant={data.status.toLowerCase()}
              title={
                <span>
                  <Circle
                    htmlColor={groupCodes[data.status.toLowerCase()]?.color}
                    style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                  />
                  <span className={styles.statusCell} style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                    {data.status}
                  </span>
                </span>
              }
            >
              {Object.keys(groupCodes)?.map((value) => {
                return (
                  <Dropdown.Item
                    key={value}
                    eventKey={value}
                    className={styles.dropdownItem}
                    onClick={() => handleStatusChange(data, groupCodes[value])}
                  >
                    <span>
                      <Circle
                        htmlColor={groupCodes[value.toLowerCase()].color}
                        style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                      />
                      <span className={styles.statusCell} style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                        {capitalizeFirstLetter(value)}
                      </span>
                    </span>
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </span>
        ),
        action: (
          <Grid container spacing={1}>
            {/* <Grid item xs={7}>
              <Button
                variant="contained"
                type="submit"
                size="small"
                style={{
                  background: "#e3e0e0",
                  borderRadius: "22.5px",
                  color: "#464e5f",
                  width: "93.37px",
                  height: "37.1px",
                }}
              >
                View
              </Button>
            </Grid> */}
            {/* <Grid item>
              <LockOpen htmlColor="#757575" />
            </Grid> */}
            <Grid item>
              <CampaignGroupsActionButton group={data} setReload={setReload} />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });
    setBodyColumns(columns);
  };

  const handleStatusChange = async (data, value) => {
    dispatch(
      notify({
        message: "Please wait... We are updating the status",
        type: "info",
      })
    );
    const listStatusUpdate = {
      listId: data.id,
      status: value.id,
    };
    const response = await updateGroup(listStatusUpdate, user, dispatch);
    if (response?.response) {
      getGroupDetails(
        +params.get("id"),
        selectedPlatforms,
        page,
        rows,
        sortCol,
        sortOrder,
        0,
        filter,
        startDate,
        "",
        false,
        mimeType,
        fileName,
        timeZone
      );
      dispatch(
        notify({
          message: "Group status updated successfully",
          type: "success",
        })
      );
    }
  };
  const changeHeaders = (event) => {
    setHeaders(event);
  };

  //apply the selected filters
  const sendSelectedFilters = (event) => {
    setSelectedPlatforms(event.selectedPlatforms);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    getGroupDetails(
      +params.get("id"),
      event.selectedPlatforms,
      page,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      event.startDate,
      event.endDate,
      false,
      event.mimeType,
      event.fileName,
      timeZone
    );
  };

  const sendRefresh = (event) => {
    dispatch(
      notify({
        message: "Please wait... We are refreshing the data",
        type: "info",
      })
    );
    setRefresh(true);
  };

  //function that caters to page changes
  const sendInformation = (event) => {
    setPage(event.page);
    setRows(event.rowsPerPage);
    setSortOrder(event.order);
    setSortCol(event.orderBy);
    setMimeType(event.mimeType);
    setFileName(event.fileName);
    getGroupDetails(
      +params.get("id"),
      selectedPlatforms,
      event.page,
      event.rowsPerPage,
      event.orderBy,
      event.order,
      event.export,
      filter,
      startDate,
      endDate,
      false,
      event.mimeType,
      event.fileName,
      timeZone
    );
  };

  const getSearchValue = (event) => {
    setFilter(event);
  };

  const sendPlatformUpdate = (event) => {
    setSelectedPlatforms(event);
  };

  const updateSelectedDates = (event) => {
    setStartDate(event.startDate);
    setEndDate(event.endDate);
  };

  const handleModalClose = () => {
    setRefresh(true);
  };

  const start = page * rows + 1;
  const end = Math.min((page + 1) * rows, totalRows);


  return (
    <Box>
      <CampaignGroupsFilter
        sendInformation={sendInformation}
        changeHeaders={changeHeaders}
        selectedPlatform={selectedPlatforms}
        tableHeaders={tableHeaders}
        filter={filter}
        getSearchValue={getSearchValue}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        sendRefresh={sendRefresh}
        sendSelectedFilters={sendSelectedFilters}
        sendPlatformUpdate={sendPlatformUpdate}
        updateSelectedDates={updateSelectedDates}
        allAllowedPlatforms={allAllowedPlatforms}
        pageNo={page}
        rowsPerPageNo={rows}
        sortOrderBy={sortCol}
        sortOrder={sortOrder}
        mimeType={mimeType}
        fileName={fileName}
        handleModalClose={handleModalClose}
      />
      <br />
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <br />
          <AdjustCampaignDates />
          <div className={styles.background}>
            <p>
              Showing {start} to {end} of {totalRows} groups
            </p>
            <CustomizedTable
              tableHeaders={headers}
              bodyColumns={bodyColumns}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
              sendInformation={sendInformation}
            />
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.child}> No groups added on this campaign</div>
        </div>
      )}
    </Box>
  );
};

export default CampaignGroupsSnapshot;
