import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  createCampaign,
  editCampaign,
  getBrandsForAccount,
  getCampaignTypesForAccount,
  getDetailedCampaign,
  getTimezoneData,
} from "../../../services/campaigns";
import { useDispatch, useSelector } from "react-redux";
import {
  IdPlatformMapping,
  PlatformIdMapping,
  snapshotHeaderIcons,
  textColor,
} from "../../../utility/plaformIcons";
import CreateBrand from "./CreateBrand";
import { notify } from "../../../redux/features/system/systemAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoToolTip } from "../../common/toolTip";
import { useStyles } from "./staticStyling";
import FormDateRangeCustomPicker from "../../common/formdaterangepicker";
import { original } from "@reduxjs/toolkit";
import { getCreditInfo } from "../../../services/credit";
import Loader from "../../common/loader";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../common/StyledComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


const CampaignWizardStep1 = ({ goToNextStep, getCampaignId, campaign, setCampaignData, sendEditMode, setPlatforms, allPlatforms, isCampStatusDraft }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const classes = useStyles();

  const [state, setState] = React.useState({
    campaignName: "",
    brandName: "",
    platforms: [],
    originalPlatforms: [],
    campaignType: "",
    campaignTimezone: "",
    campaignTimezoneText: "",
    startDate: moment().clone().add(5, "days"),
    endDate: moment().clone().add(36, "days"),
    campaignKeywords: "",
    campaignId: "",
    campaignStatus: "",
  });
  const campaignNameCount = state.campaignName?.length;

  const [allBrands, setAllBrands] = React.useState([
    { id: 0, name: `+ Create a brand` },
  ]);
  const [createBrandDialog, setCreateBrandDialog] = React.useState(false);
  const [campaignTypes, setCampaignTypes] = React.useState([]);
  const [campaignTimezones, setCampaignTimezones] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [campaignId, setCampaignId] = React.useState("");
  const [dateComponent, setDateComponent] = React.useState([]);
  const [isCampaignSaveAsDraft, setIsCampaignSaveAsDraft] = React.useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDraft, setIsDraft] =  React.useState(true);

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const eventType = params.get("eventType");

  const [chipData, setChipData] = React.useState([]);

  const handleKeywordChange = (e) => {
    const smallCaseValue = e.target.value.toLowerCase();
    setState({ ...state, campaignKeywords: smallCaseValue });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addChip();
    }
  };

  const handleInputBlur = (e) => {
    addChip(e);
  };

  const addChip = () => {
    if (state.campaignKeywords.trim() !== "") {
      const smallCaseKeyword = state.campaignKeywords.trim().toLowerCase();
      setChipData((prevChips) => [...prevChips, smallCaseKeyword]);
      setState({ ...state, campaignKeywords: " " });
    }
  };

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips?.filter((chip) => chip !== chipToDelete));
  };

  React.useEffect(() => {
    setLoading(true);
    if (campaign) {
      setEditMode(true);
      setCampaignId(campaign.campaignId);
      getCampaignDetails(campaign.campaignId);
    }
    setDateComponent(
      <FormDateRangeCustomPicker
        sendSelectedDates={sendSelectedDates}
        parentStartDate={state.startDate}
        parentEndDate={state.endDate}
        needRange={false}
      />
    );
    if (+params.get("id") > 0) {
      setCampaignId(+params.get("id"));
      dispatch(
        notify({
          message: "Please wait... We are loading your campaign",
          type: "info",
        })
      );
      setEditMode(true);
      sendEditMode(true);
      getCampaignDetails(+params.get("id"));
    }
    getAllBrandsForAccount();
    getAllCampaignTypesForAccount();
    getCampaignTimezoneData();


    if (+params.get("id") <= 0 && !campaign) {
      setLoading(false);
    }
  }, []);

  const getCampaignDetails = async (cid) => {
    const campaignResponse = await getDetailedCampaign(cid, user, dispatch);
    if (campaignResponse.response == null) {
      setEditMode(false);
      sendEditMode(false);
      setLoading(false);
    }
    const { name, campaignType, brandId, brandName, startDate, endDate, keywords, platforms, current_status, timezone, timezoneText } = campaignResponse?.response[0];
    setState({
      campaignName: name,
      brandName: { id: brandId, name: brandName },
      // campaignType: { type: campaignType },
      campaignTimezone: timezone,
      campaignTimezoneText: timezoneText,
      startDate: moment(startDate),
      endDate: moment(endDate),
      campaignKeywords: " ",
      platforms: platforms?.map((platform) => IdPlatformMapping[platform]),
      originalPlatforms: platforms?.map((platform) => IdPlatformMapping[platform]),
      campaignStatus: current_status,
    });
    if(current_status !== 2 || isCampStatusDraft === false ){
      setIsDraft(false);
    }
    setChipData(keywords?.length > 0 ? keywords.split(",") : []);
    setLoading(false);
  };

  React.useEffect(() => {
    setDateComponent(
      <FormDateRangeCustomPicker
        sendSelectedDates={sendSelectedDates}
        parentStartDate={state.startDate}
        parentEndDate={state.endDate}
        needRange={false}
      />
    );
  }, [state.startDate, state.endDate]);
  const getAllBrandsForAccount = async () => {
    const response = await getBrandsForAccount(user, dispatch);
    if (response?.response !== null) {
      setAllBrands([
        { id: 0, name: "+ Create a brand" },
        ...response?.response,
      ]);
    }
  };

  const getAllCampaignTypesForAccount = async () => {
    const response = await getCampaignTypesForAccount(user, dispatch);
    setCampaignTypes(response?.response);
  };

  const getCampaignTimezoneData = async () => {
    const response = await getTimezoneData("all", user, dispatch);
    setCampaignTimezones(response?.response);
  };

  const closeBranDialog = (e) => {
    setCreateBrandDialog(false);
    getAllBrandsForAccount();
  };

  const manipulatePlatforms = (platform) => {
    if (state.originalPlatforms?.includes(platform)) {
      return;
    } else if (state.platforms?.includes(platform)) {
      const newPlatformArr = state.platforms?.filter(
        (plat) => plat !== platform
      );
      setState({ ...state, platforms: newPlatformArr });
    } else {
      setState({ ...state, platforms: [...(state.platforms || []), platform] });
    }
  };

  const createCampaignFlow = async (saveType) => {
    // goToNextStep(1);
    // getCampaignId(383);
    dispatch(
      notify({
        message: "Please wait... We are processing your campaign",
        type: "info",
      })
    );
    sendEditMode(editMode);
    const platformIds = state.platforms?.map(
      (platform) => PlatformIdMapping[platform]
    );
    const originalPlatformsIds =
      state.originalPlatforms?.map((platform) => PlatformIdMapping[platform]) ||
      [];
    const newAddedPlatformIds = platformIds?.filter(
      (id) => !originalPlatformsIds.includes(id)
    );
    const campaignData = {
      userId: 1,
      name: state.campaignName,
      companyId: user.companyId > 0 ? user.companyId : user.agencyId,
      duration: "custom",
      budgetType: "Lifetime",
      brandId: state.brandName?.id,
      budget: "0",
      current_status: state.startDate.diff(moment(new Date())) >= 1 ? 4 : 1, //check if the campaign starts now or is scheduled for later time
      startDate: state.startDate.format("YYYY-MM-DD HH:mm:ss"),
      endDate: state.endDate.format("YYYY-MM-DD HH:mm:ss"),
      currency: "$", //for now kept currency as dollar
      // campaignType: state.campaignType.type,
      timezone: state.campaignTimezone,
      timezoneText: state.campaignTimezoneText,
      noOfDuration: 0,
      platforms: newAddedPlatformIds?.length === 0 ? [] : newAddedPlatformIds,
      listStatus: 1,
      eventType: eventType,
      keywords: chipData.join(","),
      addedFrom: "empowr",
    };
    setPlatforms(state.platforms);
    if (editMode || isCampaignSaveAsDraft) {
      campaignData.cid = campaignId;
      if (saveType == "Draft") {
        campaignData.current_status = 2;
      }
      const response = await editCampaign(campaignData, user, dispatch);
      if (response?.response) {
        if (saveType == "Save") {
          goToNextStep(1, isDraft);
        }
        else {
          navigate(`/app/campaigns`);
          setCampaignData(state);
        }
        getCampaignId(campaignId);
        let actionItem = "created";
        if (campaignData.current_status === 4) {
          actionItem = "scheduled";
        }
        if (saveType == "Draft") {
          actionItem = "drafted"
        }
        dispatch(
          notify({
            message: `Campaign ${actionItem} successfully`,
            type: "success",
          })
        );
      }
    } else {
      if (saveType == "Draft") {
        campaignData.current_status = 2;
      }
      const response = await createCampaign(campaignData, user, dispatch);
      const { status, id } = response?.response;
      if (status == "success") {
        if (saveType == "Save") {
          goToNextStep(1, isDraft);
        }
        else {
          setIsCampaignSaveAsDraft(true)
          setCampaignId(id)
          navigate(`/app/campaigns`);
          setCampaignData(state);
        }
        if (id > 0) {
          state.campaignId = id;
          getCampaignId(id);
        }
        setCampaignData(state);

        let actionItem = "created";
        if (campaignData.current_status === 4) {
          actionItem = "scheduled";
        }
        if (saveType == "Draft") {
          actionItem = "drafted"
        }
        dispatch(
          notify({
            message: `Campaign ${actionItem} successfully`,
            type: "success",
          })
        );
      }
    }
  };

  const toTitleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const goToPreviousStep = () => {
    navigate(`/app/campaigns`);
    setCampaignData(state);
  };

  const sendSelectedDates = ({ startDate, endDate }) => {
    setState((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  const handleChangeTimezone = (e, value) => {
    if (value !== null) {
      if (value.length > 0) {
        var data = [];
        value.forEach(function (item, index) {
          data.push(item["id"]);
        });
        state["campaignTimezone"] = data.join(",");
      } else if (typeof value["id"] !== "undefined") {
        setState({ ...state, campaignTimezone: value["id"], campaignTimezoneText: value["text"] })
      }
    }
    else {
      setState({ ...state, campaignTimezone: "", campaignTimezoneText: "" })
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box m={5}>
          <h2 style={{ fontSize: "32px", fontWeight: 500, lineHeight: "28px" }}>
            {/* {editMode ? `Edit ${state.campaignName}` : "Create a new Campaign"} */}
            Basics
          </h2>
          <Grid container item spacing={4} xs={12} style={{ paddingTop: 20 }}>
            <Grid item md={5} xs={10}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Campaign name *</b>
              </p>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Choose a short name to best describe the campaign"
                type="text"
                required
                value={state.campaignName}
                helperText="Max 40 Chars"
                onChange={(e) => {
                  const titleCaseValue = toTitleCase(e.target.value);
                  setState({ ...state, campaignName: titleCaseValue });
                }}
                InputProps={{
                  endAdornment: (
                    <div className={classes.counter}>
                      {campaignNameCount}/40
                    </div>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item md={5} xs={10}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Campaign Type *</b>
              </p>
              <Autocomplete
                id="size-small-filled"
                options={campaignTypes}
                getOptionLabel={(option) => option.type || ""}
                isOptionEqualToValue={(option, value) =>
                  option.type === value.type
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Choose from drop down a campaign type"
                    required
                  />
                )}
                value={state.campaignType}
                onChange={(event, newValue) => {
                  setState({ ...state, campaignType: newValue });
                }}
              />
            </Grid> */}
            <Grid item md={5} xs={10}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Timezone *</b>
              </p>
              <Autocomplete
                id="size-small-filled"
                options={campaignTimezones}
                getOptionLabel={(timezone) => timezone.text || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="Choose Timezone" required />
                )}
                value={
                  state.campaignTimezoneText !== ""
                    ? campaignTimezones.find((timezone) => timezone.text === state.campaignTimezoneText)
                    : null
                }
                onChange={handleChangeTimezone}
              />
            </Grid>
            <Grid item md={5}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Brand or Product to be promoted *</b>
              </p>
              <Autocomplete
                id="size-small-filled"
                aria-required
                required
                options={allBrands}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props}>
                    <span
                      style={{
                        fontWeight:
                          option.name === "+ Create a brand" ? 600 : 400,
                      }}
                    >
                      {option.name}
                    </span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Choose or create brand"
                    required
                  />
                )}
                defaultValue={state.brandName}
                value={state.brandName}
                onChange={(event, newValue) => {
                  if (newValue.name === "+ Create a brand") {
                    setCreateBrandDialog(true);
                    setState({ ...state, brandName: {} });
                  } else {
                    setState({ ...state, brandName: newValue });
                  }
                }}
              />
            </Grid>
            <Grid item md={5} xs={12} sm={12}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Campaign Dates *</b>
              </p>
              {dateComponent}
            </Grid>

            <Grid item md={10}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: "18px", fontWeight: 500, margin: 0 }}>
                  <b style={{ paddingRight: "10px" }}>Select Campaign Platform *</b>
                </p>
                <b>
                  <span
                    style={{
                      color: "blue",
                      visibility:
                        state.platforms?.length > 0 ? "visible" : "hidden",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                  >
                    {state.platforms?.length > 0 && `${state.platforms?.length} Platform${state.platforms?.length > 1 ? "s" : ""} Selected`}
                  </span>
                </b>
              </div>
              <Grid
                container
                item
                spacing={5}
                xs={12}
                style={{ paddingTop: 20 }}
              >
                {allPlatforms?.map((platform) => (
                  <Grid item>
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: state.platforms?.includes(IdPlatformMapping[platform]) ? textColor[IdPlatformMapping[platform]].color : "gray",
                        borderWidth: state.platforms?.includes(IdPlatformMapping[platform])
                          ? "3px"
                          : "1px",
                        padding: state.platforms?.includes(IdPlatformMapping[platform])
                          ? "8px"
                          : "10px",
                      }}
                      onClick={(e) =>
                        manipulatePlatforms(IdPlatformMapping[platform])
                      }
                    >
                      {snapshotHeaderIcons[IdPlatformMapping[platform]] &&
                        React.cloneElement(
                          snapshotHeaderIcons[IdPlatformMapping[platform]],
                          {
                            style: {
                              ...(IdPlatformMapping[platform] === "tiktok"
                                ? {
                                    filter: state.platforms?.includes(
                                      IdPlatformMapping[platform]
                                    )
                                      ? "grayscale(10%)"
                                      : "invert(20%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(10%)",
                                    height: "20px",
                                  }
                                : {
                                    fill: state.platforms?.includes(
                                      IdPlatformMapping[platform]
                                    )
                                      ? textColor[IdPlatformMapping[platform]]
                                          .color
                                      : "gray",
                                    height: "20px",
                                  }),
                            },
                          }
                        )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Grid container style={{ fontSize: "10px", paddingTop: "10px" }}>
                Note: A social platform once added to a campaign cannot be
                removed later.
              </Grid>
            </Grid>

            <Grid item md={10}>
              <p style={{ fontSize: "18px", fontWeight: 500 }}>
                <b>Keywords</b>{" "}
                <InfoToolTip
                  text={
                    "Campaign keywords are used to automatically identify campaign posts published by Influencers participating in the campaign. This can be edited later as well"
                  }
                />
              </p>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="A few comma-separated keywords to be used in the campaign publications"
                type="text"
                value={state.campaignKeywords}
                onChange={handleKeywordChange}
                onKeyDown={handleKeyPress}
                onBlur={handleInputBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Stack direction="row" spacing={1}>
                        {chipData?.map((chip, index) => (
                          <Chip
                            key={index}
                            label={chip}
                            onDelete={handleChipDelete(chip)}
                          />
                        ))}
                      </Stack>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container style={{ fontSize: "10px", paddingTop: "10px" }}>
                Note: Press comma or enter to add multiple campaign keywords.
                Keywords are not case-sensitive.
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" style={{ width: "84%" }}>
              {/* Back and Save as Draft */}
              <Grid item>
                <Box sx={{ mt: 4, ml: 3.5 , display: "flex", gap: "10px", alignItems: "center" }}>
                  {/* Back Button */}
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                    }}
                    onClick={(e) => goToPreviousStep()}
                  >
                    <ArrowBackIcon
                      style={{ marginRight: "8px", fontSize: "18px" }}
                    />
                    <b>Back</b>
                  </Button>

                  {/* Save as Draft */}
                  {(state.campaignStatus === "" || state.campaignStatus === 2) && (
                    <Button
                      variant="outlined"
                      style={{
                        width: "180px",
                        height: "38px",
                        borderColor: !state.campaignName || campaignNameCount > 40 ? "gray" : "#474747",
                        borderRadius: "11px",
                        color: !state.campaignName || campaignNameCount > 40 ? "gray" : "#474747",
                      }}
                      disabled={!state.campaignName || campaignNameCount > 40}
                      onClick={(e) => createCampaignFlow("Draft")}
                    >
                      <b>Save as Draft</b>
                    </Button>
                  )}
                </Box>
              </Grid>

              {/* Save and Proceed */}
              <Grid item>
                <Box sx={{ mt: 4 }}>
                  <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
                    <StyledStaticButton
                      value="active"
                      onClick={(e) => createCampaignFlow("Save")}
                      disabled={
                        !state.campaignName ||
                        !state.brandName || state.brandName.name === "" ||
                         state.campaignTimezone === -1 ||
                        state.platforms?.length === 0 || state.platforms === undefined ||
                        campaignNameCount > 40
                      }
                      classes={{ disabled: classes.disabledButton }}
                      style={{
                        width: "180px",
                        height: "38px",
                        borderRadius: "11px",
                      }}
                    >
                      <b>Save and Proceed</b>
                      <ArrowForwardIcon
                        style={{ marginLeft: "8px", fontSize: "18px" }}
                      />
                    </StyledStaticButton>
                  </StyledStaticButtonGroup>
                </Box>
              </Grid>
            </Grid>

          </Grid>
          <CreateBrand
            open={createBrandDialog}
            setOpen={(e) => closeBranDialog(e)}
          />
        </Box>
      )}
    </>
  );
};

export default CampaignWizardStep1;
