import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { getAllInfluencerListForCampaign } from "../../../../services/influencer";
import { Button, Grid } from "@mui/material";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import GroupDropdown from "../../../common/groupDropdown";
import InfluencerDropdown from "../../../common/influencerDropdown";
import TaskDropdown from "../../../common/taskDropdown";
import {
  getMilestonesForCampaign,
  getAllTasksForCampaign,
} from "../../../../services/tasks";
import MilestoneDropdown from "../../../common/milestoneDropdown";
import { FilterList } from "@mui/icons-material";

const CampaignDraftsFilter = ({
  allSelectedPlatforms,
  allSelectedGroups,
  allSelectedInfluencers,
  allSelectedTasks,
  sendFilterChange,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [buttonColor, setButtonColor] = useState("grey");
  const [selectedPlatforms, setSelectedPlatforms] =
    useState(allSelectedPlatforms);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(allSelectedGroups);
  const [influencerList, setInfluencerList] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState(
    allSelectedInfluencers
  );
  const [taskList, setTaskList] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState(allSelectedTasks);
  const [mileStoneList, setMileStoneList] = React.useState([]); //all the milestones
  const [selectedMilestones, setSelectedMilestones] = useState([]); //list of selected milestones
  const [filter, setFilter] = React.useState(false);

  useEffect(() => {
    getGroupList("all");
    getTaskList();
  }, [params.get("id")]);

  //change button color on any filter change
  const changeColor = () => {
    setButtonColor(isFilterApplied() ? "#007dff" : "grey");
  };

  const addFilters = () => {
    setFilter(!filter);
    changeColor();
  };

  const isFilterApplied = () => {
    return (
      selectedPlatforms?.length > 0 ||
      selectedGroups?.length > 0 ||
      selectedInfluencers?.length > 0
    );
  };

  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(
      params.get("id"),
      platforms,
      user,
      dispatch
    );
    if (groups.status === true) {
      const ids = groups.response?.map((group) => group.id);
      getInfluencerList(ids);
      setGroupList(groups.response);
      getMilestoneList();
      setSelectedGroups([]);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(notify({ message: groups.response?.message, type: "error" }));
    }
  };

  const getTaskList = async () => {
    const tasks = await getAllTasksForCampaign(
      params.get("id"),
      user,
      dispatch
    );
    if (tasks.status === true) {
      const taskObject = {};
      const tasksTable = [];
      tasks.response.forEach((row) => {
        if (!taskObject[row.id]) {
          taskObject[row.id] = [];
        }

        taskObject[row.id].push(row);
      });
      Object.keys(taskObject).forEach((id) => {
        tasksTable.push(taskObject[id][0]);
      });
      setTaskList(tasksTable);
    } else {
      if (tasks.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(notify({ message: tasks.response?.message, type: "error" }));
    }
  };

  const getMilestoneList = async () => {
    const milestones = await getMilestonesForCampaign(
      params.get("id"),
      user,
      dispatch
    );
    if (milestones.status === true) {
      setMileStoneList(milestones.response);
    } else {
      if (milestones.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({ message: milestones.response?.message, type: "error" })
      );
    }
  };

  const getInfluencerList = async (ids) => {
    const influencers = await getAllInfluencerListForCampaign(
      ids,
      "all",
      user,
      dispatch
    );
    if (influencers.status === true) {
      setSelectedInfluencers([]);
      setInfluencerList(influencers.response);
    }
  };

  const sendSelectedPlatforms = (event) => {
    setSelectedPlatforms(event);
    if (event?.length > 0) {
      getGroupList(event);
    } else {
      getGroupList("all");
    }
  };

  const sendSelectedGroups = (event) => {
    const ids = [];
    if (event?.length > 0) {
      event.forEach((group) => {
        ids.push(group.id);
      });
      getInfluencerList(ids);
    } else {
      getGroupList("all");
    }
    setSelectedGroups(event);
    applyFilters({ groups: event });
  };

  const sendSelectedInfluencer = (event) => {
    setSelectedInfluencers(event);
    applyFilters({ influencers: event });
  };

  const sendSelectedTasks = (event) => {
    setSelectedTasks(event);
  };

  const sendSelectedMilestones = (event) => {
    setSelectedMilestones(event);
  };

  const applyFilters = (event) => {
    sendFilterChange({
      selectedPlatforms,
      selectedGroups: event.groups || selectedGroups,
      selectedInfluencers: event.influencers || selectedInfluencers,
      selectedTasks,
    });
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Grid item md={2.5}>
          <PlatformSelectionBox
            sendSelectedPlatforms={sendSelectedPlatforms}
            parentPlatforms={selectedPlatforms}
          />
        </Grid>
        <Grid item md={2}>
          <TaskDropdown
            taskList={taskList}
            sendSelectedTasks={sendSelectedTasks}
            parentSelectedTask={selectedTasks}
          />
        </Grid>
        <Grid item md={2}>
          <MilestoneDropdown
            milestoneList={mileStoneList || []}
            sendSelectedMilestones={sendSelectedMilestones}
            parentSelectedMilestone={selectedMilestones}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
              borderRadius: "8px",
            }}
            onClick={applyFilters}
          >
            Go
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            onClick={addFilters}
            style={{
              background: buttonColor,
              borderRadius: "8px",
            }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FilterList sx={{ fontSize: "small" }} /> &nbsp;
            <span>Filters</span>
          </Button>
        </Grid>
      </Grid>
      <br />
      {filter && (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "flex-end" }}
          spacing={2}
        >
          <Grid item md={2.5}>
            <GroupDropdown
              groupList={groupList}
              sendSelectedGroups={sendSelectedGroups}
              parentSelectedGroup={selectedGroups}
            />
          </Grid>
          <Grid item md={2}>
            <InfluencerDropdown
              influencerList={influencerList}
              sendSelectedInfluencer={sendSelectedInfluencer}
              parentSelectedInfluencer={selectedInfluencers}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

CampaignDraftsFilter.propTypes = {
  allSelectedPlatforms: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  allSelectedInfluencers: PropTypes.array.isRequired,
  sendFilterChange: PropTypes.func.isRequired,
};

export default CampaignDraftsFilter;
