import { useState, useEffect, useCallback } from "react";

import {
  Avatar,
  Button,
  Grid,
  Stack,
  Tooltip,
  Paper,
  Box,
  Modal,
  Backdrop,
  Typography,
  Alert,
  Chip,
} from "@mui/material";

import utils from "../../utility/oryxUtils/utils";
import ViewProfileDialog from "./components/discovery/ViewProfileDialog";
import QueryStatsTwoToneIcon from "@mui/icons-material/QueryStatsTwoTone";
import CustomizedTable from "../common/customizedTable";
import Loader from "../common/loader";
import { EmailOutlined, VisibilityTwoTone } from "@mui/icons-material";
import DiscoveryActionBtn from "./DiscoveryActionBtn";
import AddToListDialog from "./components/discovery/AddToListDialog";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import ContactCard from "./components/ContactCard";
import UnlockDataCard from "./components/UnlockDataCard";
import { useSelector } from "react-redux";
import InviteInfluencerDialog from "./components/discovery/InviteInfluencerDialog";

const MyPaper = ({ children, elevation, fitHeight, sx }) => (
  <Paper
    elevation={elevation}
    sx={{
      p: 3,
      borderRadius: 2,
      border: 1,
      borderColor: "#fdfdfd",
      boxShadow:
        "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
      height: fitHeight ? "100%" : "auto",
      ...sx,
    }}
  >
    {children}
  </Paper>
);

const DiscoveryTable = ({
  platform,
  filtersApplied,
  tableDataLoaded,
  tableData,
  tableDataUpdated,
  tableRowHeaders,
  finalTableRowHeaders,
  selectedData,
  customCols,
  setTableRowHeaders,
  appliedFilters,
  setAppliedFilters,
  allFiltersData,
  tableRowsPerPage,
  tablePageNo,
  sortCol,
  handleInformationChange,
  setCreditValue,
  setCredit,
  credit,
  payload,
  isUnlockingResults,
  unlockedDataCount,
  setUnlockedDataCount,
  unlockConfirmation,
  hiddenColumns,
  setHiddenColumns,
  setLockedResults,
  unlockResultsHandler: unlockResults,
  discoverCredits,
}) => {
  const [bodyColumns, setBodyColumns] = useState([]);
  // const [hiddenColumns, setHiddenColumns] = useState([]);
  const [totalTableRows, setTotalTableRows] = useState(0);

  const [selectedRow, setSelectedRow] = useState({}); // for 3-dot menu action
  const [additionalDetail, setAdditionalDetail] = useState({});
  const [viewProfileDialog, setViewProfileDialog] = useState(false);
  const [addToList, setAddToList] = useState(false);
  const [inviteInfluencer, setInviteInfluencer] = useState(false);

  const [basicHeader] = useState([
    {
      id: 1,
      label: "Account",
      field: "profile",
      show: true,
    },
    {
      id: 2,
      label: "Followers",
      field: "following",
      show: true,
    },
    {
      id: 3,
      label: "Engagements",
      field: "engagements",
      show: true,
    },
    {
      id: 4,
      label: "Engagements Rate",
      field: "engagement_rate",
      show: true,
    },
  ]);

  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const user = useSelector((state) => state.user);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleListToDialogModalOpen = () => setAddToList(true);
  const handleInviteInfluencerModalOpen = () => setInviteInfluencer(true);
  const handleListToDialogModalClose = () => setAddToList(false);
  const handleInviteInfluencerModalClose = () => setInviteInfluencer(false);

  const menuOptions = (row) => [
    {
      id: "contact-info",
      name: "Contact Info",
      icon: <AccountCircleRoundedIcon />,
      action: () => {
        setActiveMenuItem(row?.account?.user_profile);
        handleModalOpen();
      },
      helpText:
        credit > 0 && !row?.account?.user_profile?.analyzed ? (
          <Box fontSize={"12px"}>
            {" "}
            View all types of contact information of the influencer. <br />{" "}
            <span style={{ color: "orange" }}> Uses 1 credit. </span> (if
            viewing for the first time){" "}
          </Box>
        ) : null,
      isDisabled:
        row?.account?.empowr_hidden ||
        (credit <= 0 && !row?.account?.user_profile?.analyzed),
    },
    {
      id: "add-to-group",
      name: "Add To Group",
      icon: <AddBusinessRoundedIcon />,
      action: () => {
        setActiveMenuItem(row?.account?.user_profile);
        handleListToDialogModalOpen();
      },
      isDisabled:
        row?.account?.empowr_hidden ||
        (credit <= 0 && !row?.account?.user_profile?.analyzed),
    },
    {
      id: "send-invite",
      name: "Send Invite",
      icon: <EmailOutlined />,
      action: () => {
        setActiveMenuItem(row?.account?.user_profile);
        handleInviteInfluencerModalOpen();
      },
      helpText: row?.account?.user_profile?.email_address === null && (
        <Box fontSize={"12px"}>
          {" "}
          Email doesn't exist for the influencer <br />{" "}
        </Box>
      ),
      isDisabled:
        row?.account?.empowr_hidden ||
        (credit <= 0 && !row?.account?.user_profile?.analyzed) ||
        row?.account?.user_profile?.email_address === null,
    },
  ];

  const reduceCredit = (row) => {
    const newRec = row["account"]["user_profile"];
    let modified = false;
    const records = tableData["data"]["accounts"];
    records.forEach((row) => {
      if (
        parseInt(row["account"]["user_profile"]["user_id"]) ===
        parseInt(newRec["user_id"])
      ) {
        if (
          row["account"]["user_profile"]["analyzed"] === undefined ||
          !row["account"]["user_profile"]["analyzed"]
        ) {
          row["account"]["user_profile"]["analyzed"] = true;
          modified = true;
          return;
        }
      }
    });

    // const iid = newRec["user_id"];

    if (user.agencyId === 0) {
      window.open(
        `/app/discovery/analyze/${platform?.toLowerCase()}/${
          row?.account?.user_profile.username
        }`,
        "_blank"
      );
      return;
    }

    if (modified) {
      setCredit((value) => value - 1);
      setCreditValue((value) => value - 1);
      createBodyData(records);
    }
    window.open(
      `/app/discovery/analyze/${platform?.toLowerCase()}/${
        row?.account?.user_profile.username
      }`,
      "_blank"
    );
  };

  const unlockResultsHandler = async () => {
    // unlock results & update table
    unlockResults(false).then((res) => {
      if (res?.length > 0) {
        let bodyCols = bodyColumns;
        res?.forEach((row, index) => {
          const bodyCol = createBodyColumn(row, index, false);
          bodyCols.push(bodyCol);
        });
        setBodyColumns(bodyCols);
      }
    });
  };

  const createBodyColumn = useCallback(
    (row, index, isHidden) => {
      let newRec = row["account"]["user_profile"];
      const iid = newRec["user_id"];
      let displayName = "";
      let name = "";
      let username = "";
      let displayUsername = "";
      if (newRec["fullname"] !== undefined) {
        name = newRec["fullname"];
        displayName = name;
      }
      if (newRec["username"] !== undefined) {
        username = newRec["username"];
        displayUsername = username;
      }

      let profileLink = newRec["url"];

      const photoUrl = newRec["picture"] ? newRec["picture"] : "";
      const bodyCol = {
        id: index + 1,
        show: true,
        iid,
        profile: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            {/* Avatar */}
            <Grid item xs={4} sm={2} md={2}>
              <a
                href={isHidden ? "#!" : profileLink}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Avatar
                  variant="circular"
                  sx={
                    isHidden
                      ? { width: "0px", height: "0px" }
                      : { width: "40px", height: "40px" }
                  }
                  src={photoUrl}
                />
              </a>
            </Grid>
            <Grid item xs={8}>
              <Grid container columns={{ xs: 4, sm: 10, md: 10 }}>
                {/* Name */}
                {isHidden ? (
                  <>
                    <Grid item xs={10}>
                      <Tooltip title={isHidden ? "Hidden name" : name}>
                        <span
                          className="name containsTootlip"
                          style={{
                            color: "transparent",
                            textShadow: "0px 0px 8px #000000",
                          }}
                        >
                          {"Hidden name"}
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title={isHidden ? `hidden_username` : username}>
                        <span
                          className="subtext username containsTooltip"
                          style={{
                            color: "transparent",
                            textShadow: "0px 0px 8px #0d6efd",
                          }}
                        >
                          @{`hidden_username`}
                        </span>
                      </Tooltip>
                    </Grid>
                  </>
                ) : (
                  <a
                    href={isHidden ? "#!" : profileLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Grid item xs={10}>
                      <Tooltip title={isHidden ? "Hidden name" : name}>
                        <span
                          className="name containsTootlip"
                          style={{
                            color: isHidden ? "transparent" : "black",
                            textShadow: isHidden
                              ? "0px 0px 8px #000000"
                              : "none",
                          }}
                        >
                          {isHidden ? "Hidden name" : displayName}
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title={isHidden ? `hidden_username` : username}>
                        <span className="subtext username containsTooltip">
                          <a
                            href={isHidden ? "#!" : profileLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: isHidden ? "transparent" : "#0d6efd",
                              textShadow: isHidden
                                ? "0px 0px 8px #0d6efd"
                                : "none",
                            }}
                          >
                            @{isHidden ? `hidden_username` : displayUsername}
                          </a>
                        </span>
                      </Tooltip>
                    </Grid>
                  </a>
                )}
              </Grid>
            </Grid>
          </Grid>
        ),
        following: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid
              item
              xs={4}
              sm={6}
              md={12}
              style={
                isHidden
                  ? { color: "transparent", textShadow: "0px 0px 8px #000000" }
                  : {}
              }
            >
              {utils.convertToInternationalNumberSystem(newRec["followers"])}
            </Grid>
          </Grid>
        ),
        engagements: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid
              item
              xs={4}
              sm={6}
              md={12}
              style={
                isHidden
                  ? { color: "transparent", textShadow: "0px 0px 8px #000000" }
                  : {}
              }
            >
              {utils.convertToInternationalNumberSystem(newRec["engagements"])}
            </Grid>
          </Grid>
        ),
        engagement_rate: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid
              item
              xs={4}
              sm={6}
              md={12}
              style={
                isHidden
                  ? { color: "transparent", textShadow: "0px 0px 8px #000000" }
                  : {}
              }
            >
              {newRec["engagement_rate"].toFixed(2) + "%"}
            </Grid>
          </Grid>
        ),
        action: (
          <Grid container columns={{ xs: 4, sm: 6, md: 8 }}>
            <Grid
              item
              xs={4}
              sm={6}
              md={8}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <>
                <DiscoveryActionBtn
                  menuOptions={menuOptions(row)}
                  setActiveMenuItem={setActiveMenuItem}
                  activeMenuItem={activeMenuItem}
                  handleModalOpen={handleModalOpen}
                  isHidden={isHidden}
                />
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    ml: "10px",
                    cursor:
                      (credit <= 0 || !credit) &&
                      !newRec["analyzed"] &&
                      user.agencyId !== 0
                        ? "not-allowed !important"
                        : "auto",
                  }}
                  style={{
                    color: isHidden ? "transparent" : "white",
                    textShadow: isHidden ? "0px 0px 8px white" : "none",
                    backgroundColor:
                      credit > 0 || newRec["analyzed"] || user.agencyId === 0
                        ? "#51A6FF"
                        : "lightgrey",
                    float: "right",
                    width: "100px",
                    pointerEvents:
                      credit > 0 || newRec["analyzed"] || user.agencyId === 0
                        ? "auto"
                        : "none",
                  }}
                  onClick={() => reduceCredit(row)}
                  disabled={
                    ((credit <= 0 || !credit) &&
                      !newRec["analyzed"] &&
                      user.agencyId !== 0) ||
                    isHidden
                  }
                >
                  {user.agencyId === 0 || newRec["analyzed"] ? (
                    <span>
                      <span>
                        <VisibilityTwoTone />
                      </span>{" "}
                      View
                    </span>
                  ) : (
                    <span>
                      <span>
                        <QueryStatsTwoToneIcon />
                      </span>{" "}
                      Analyse
                    </span>
                  )}
                </Button>
              </>
            </Grid>
          </Grid>
        ),
      };
      for (const [key, value] of Object.entries(customCols[0])) {
        if (value?.length > 0) {
          if (typeof value === "object") {
            value.forEach(function (item, index) {
              if (row["account"]["colData"] != null) {
                if (row["account"]["colData"][key] != null) {
                  bodyCol[item] = row["account"]["colData"][key][item] || "-";
                }
              }
            });
          } else {
            if (row["account"]["colData"] != null) {
              if (row["account"]["colData"][key] != null) {
                bodyCol[value] = row["account"]["colData"][key][value] || "-";
              }
            }
          }
        }
      }
      return bodyCol;
    },
    [credit, discoverCredits, tableDataUpdated, allFiltersData]
  );

  useEffect(() => {
    if (
      !selectedData ||
      !selectedData["data"]["accounts"] ||
      selectedData["data"]["accounts"]?.length === 0
    ) {
      setBodyColumns([]);
      setTotalTableRows(0);
      setHiddenColumns([]);
      return;
    }
    const totalRows = selectedData["data"]["total"];
    setTotalTableRows(totalRows);
    setTableRowHeaders([]);
    let newHeaders = tableRowHeaders;
    let filteredArray = {};
    if (newHeaders?.length == 4) {
      filteredArray = basicHeader;
    } else {
      filteredArray = basicHeader?.filter((obj) => obj.field !== "action");
    }
    for (const [key, value] of Object.entries(customCols[0])) {
      let filters = appliedFilters;
      filters[key] = value;
      let allFilters = [];
      if (value?.length > 0) {
        if (typeof value === "object") {
          value.forEach((row, index) => {
            let latestLoc = row;
            let newCol = {
              id: filteredArray?.length + 1,
              label: latestLoc,
              field: latestLoc,
              show: true,
            };
            if (
              !filteredArray.some(
                (existingFilter) =>
                  existingFilter.label === newCol.label &&
                  existingFilter.field === newCol.field
              )
            ) {
              filteredArray.push(newCol);
              allFilters.push(latestLoc);
            }
          });
        } else {
          let newCol = {
            id: filteredArray?.length + 1,
            label: value,
            field: value,
            show: true,
          };
          if (
            !filteredArray.some(
              (existingFilter) =>
                existingFilter.label === newCol.label &&
                existingFilter.field === newCol.field
            )
          ) {
            filteredArray.push(newCol);
            allFilters.push(value);
          }
        }
      }
    }
    setAppliedFilters(allFiltersData);
    const analyzeCol = {
      id: filteredArray?.length + 1,
      label: "",
      field: "action",
      show: true,
    };
    filteredArray.push(analyzeCol);
    setTableRowHeaders(filteredArray);
    const records = tableData["data"]["accounts"];
    createBodyData(records);
  }, [tableDataUpdated, allFiltersData, unlockConfirmation === true]);

  const createBodyData = (records) => {
    const bodyCols = [];

    // create table body data for unhidden accounts
    records
      ?.filter((row) => !row?.account?.empowr_hidden)
      .forEach((row, index) => {
        const bodyCol = createBodyColumn(row, index, false);
        bodyCols.push(bodyCol);
      });

    const hiddenCols = [];

    // create table body data for hidden accounts
    const hiddenAccounts = records?.filter(
      (row) => row?.account?.empowr_hidden
    );
    setLockedResults(hiddenAccounts);
    hiddenAccounts.forEach((row, index) => {
      const bodyCol = createBodyColumn(row, index, true);
      hiddenCols.push(bodyCol);
    });

    const hiddenAccountsCount = hiddenAccounts?.length;
    setHiddenColumns(hiddenCols);
    // set no of hidden accounts to be unlocked
    setUnlockedDataCount(
      discoverCredits > hiddenAccountsCount
        ? hiddenAccountsCount
        : discoverCredits
    );
    setBodyColumns(bodyCols);
  };

  const closeViewProfileDialog = () => {
    setAdditionalDetail({});
    setViewProfileDialog(false);
  };

  return tableDataLoaded ? (
    <>
      {user.agencyId > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Alert
            severity={credit > 0 ? "info" : "warning"}
            sx={{ boxShadow: 1 }}
          >
            {credit > 0 ? (
              <>
                Analysis of <b>{credit}</b> influencers is available based on
                your subscription. Upgrade to analyze more.
              </>
            ) : (
              "Upgrade your plan to analyze more influencers this month, as you have reached your limit."
            )}
          </Alert>
          <Alert
            severity={discoverCredits > 0 ? "info" : "warning"}
            sx={{ boxShadow: 1 }}
          >
            {discoverCredits > 0 ? (
              <>
                Discovery of <b>{discoverCredits}</b> influencers is available
                based on your subscription. Upgrade to discover more.
              </>
            ) : (
              "Upgrade your plan to discover more influencers this month, as you have reached your limit."
            )}
          </Alert>
        </Box>
      )}
      <div
        id="tableMenu"
        style={{
          marginTop: "20px",
          position: "sticky",
          top: "6%",
          zIndex: 1,
          transition: "all 0.3s ease-in-out",
          backdropFilter: "blur(10px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {unlockedDataCount > 0 && tableDataLoaded && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mx: 4,
              }}
            >
              <UnlockDataCard
                selectedData={selectedData}
                tableRowsPerPage={tableRowsPerPage}
                payload={payload}
                sortCol={sortCol}
                selectedPlatform={platform}
                tablePageNo={tablePageNo}
                customCols={customCols}
                unlockResultHandler={unlockResultsHandler}
                isUnlockingResults={isUnlockingResults}
                totalTableRows={totalTableRows}
                discoverCredits={discoverCredits}
                unlockedDataCount={unlockedDataCount}
              />
            </Box>
          )}
        </Box>
      </div>
      <div id="table">
        {tableDataLoaded && tableData?.["data"]?.["accounts"]?.length > 0 ? (
          <CustomizedTable
            tableHeaders={finalTableRowHeaders}
            filtersApplied={filtersApplied}
            bodyColumns={[...bodyColumns, ...hiddenColumns]}
            // expand={expand}
            selectedRow={selectedRow}
            sendInformation={handleInformationChange}
            totalRows={totalTableRows}
            pageNo={tablePageNo}
            rowsPerPageNo={tableRowsPerPage}
            sortOrder={"desc"}
            sortOrderBy={sortCol}
            lessPaddedRows
            customRowPageOptions={[10, 15, 20]}
            paginationAtTop={true}
          />
        ) : (
          <div style={{ marginTop: "20px" }}>
            <MyPaper>No data available. Please expand the filters.</MyPaper>
          </div>
        )}
      </div>
      {viewProfileDialog && (
        <ViewProfileDialog
          influencerAccount={selectedRow}
          viewProfileDialog={viewProfileDialog}
          closeViewProfileDialog={closeViewProfileDialog}
          additionalDetail={additionalDetail}
          platform={platform}
          engagementDisplay={false}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ContactCard
          avatarInfo={{
            picUrl: activeMenuItem?.picture,
            name: activeMenuItem?.username,
            userId: activeMenuItem?.user_id,
          }}
          platform={platform}
          handleModalClose={handleModalClose}
          setCredit={setCreditValue}
        />
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addToList}
        onClose={handleListToDialogModalClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <AddToListDialog
          avatarInfo={{
            picUrl: activeMenuItem?.picture,
            name: activeMenuItem?.username,
            userId: activeMenuItem?.user_id,
            platform,
          }}
          handleListToDialogModalClose={handleListToDialogModalClose}
        />
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={inviteInfluencer}
        onClose={handleInviteInfluencerModalClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <InviteInfluencerDialog
          avatarInfo={{
            picUrl: activeMenuItem?.picture,
            name: activeMenuItem?.username,
            userId: activeMenuItem?.user_id,
            email: activeMenuItem?.email_address,
            platform,
          }}
          handleListToDialogModalClose={handleInviteInfluencerModalClose}
        />
      </Modal>
    </>
  ) : (
    <Box sx={{ my: "10px" }}>
      <Loader />
    </Box>
  );
};

export default DiscoveryTable;
