import { Alert, AlertTitle, Autocomplete, Box, Button, Grid, Snackbar, TextField, Typography, Chip } from "@mui/material";
import { textColor, snapshotHeaderIcons } from "../../../../../utility/plaformIcons";
import { useEffect, useState } from "react";
import { getAllInfluencerListForCampaign } from "../../../../../services/influencer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../../../../../redux/features/system/systemAlert";
import configs from "../../../../../config/main.json";
import { inviteInfluencerByEmail } from "../../../../../services/influencer";
import { emailRegex } from "../../../../../utility/regex";

const InviteByEmail = ({ setNavigateBack, groupList, campName, isModal }) => {
    const [selectedList, setSelectedList] = useState({ name: "" });
    const user = useSelector((state) => state.user); //get loggedIn user state
    const dispatch = useDispatch();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const campaignId = params.get("id");
    const { PLATFORM_NAME } = configs;
    const [campaignName, setCampaignName] = useState(campName)
    const [emailList, setEmailList] = useState("");
    const [chips, setChips] = useState([]); // Holds the list of chips
    const [isInvalid, setIsInvalid] = useState(false);

    const goback = () => {
        setNavigateBack(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const emails = emailList.split(",").map((email) => email.trim()); // Split by comma and trim spaces

            const validEmails = emails.filter((email) => emailRegex(email)); // Validate each email
            const invalidEmails = emails.filter((email) => !emailRegex(email)); // Separate invalid emails

            if (validEmails.length > 0) {
                setChips([...chips, ...validEmails]); // Add valid emails as chips
            }

            setEmailList(invalidEmails.join(", ")); // Keep invalid emails in the input field
            setIsInvalid(invalidEmails.length > 0); // Mark input as invalid if there are any invalid emails
        }
    };

    const handleInputBlur = (event) => {
        const email = emailList.trim();
        if (email.length === 0) {
            return;
        }

        const emails = emailList.split(",").map((email) => email.trim());
        const validEmails = emails.filter((email) => emailRegex(email));
        const invalidEmails = emails.filter((email) => !emailRegex(email));

        if (validEmails.length > 0) {
            setChips([...chips, ...validEmails]);
        }
        setEmailList(invalidEmails.join(", "));
        setIsInvalid(invalidEmails.length > 0);
    };

    const handleDelete = (chipToDelete) => {
        setChips(chips.filter((chip) => chip !== chipToDelete));
    };

    const handleChange = (event) => {
        setEmailList(event.target.value);
        setIsInvalid(false);
    };

    const rollOutInvitation = () => {
        const notificationMetadata = {
            //   userId: JSON.parse(user.attributes.identities)[0].userId,
            userId: JSON.parse(user.userId),
            sourcePlatform: PLATFORM_NAME,
            notificationTypeId: "4",
            notificationActionId: "2",
            notificationCategoryId: "5",
            generatedBy: user.name,
            priority: "medium",
            consumedBy: "gazelle",
        };
        const emails = chips.join(',\n');
        //roll out invitation api integration pending
        inviteInfluencerByEmail(
            Number(campaignId),
            selectedList.id,
            emails,
            campaignName,
            selectedList.platform,
            notificationMetadata,
            user,
            dispatch
        ).then((result) => {
            if (result) {
                setSelectedList({ name: "" });
                setCampaignName();
                setChips([]);

                dispatch(
                    notify({
                        message: "Email Sent to the influencers",
                        type: "success",
                    })
                );
            }
        });
    };

    return (
        <Grid style={{ height: isModal? "" : "63.5vh"}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.name}
                        options={groupList ? groupList : []}
                        value={selectedList}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                                &nbsp;
                                {option.name}
                            </Box>
                        )}
                        onChange={(event, newValue) => {
                            setSelectedList(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Group which has all your influencers" />}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
                <Box>
                    <TextField
                        fullWidth
                        id="email-input"
                        label="Enter email"
                        placeholder="Press Enter to add email"
                        value={emailList}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                        error={isInvalid}
                        helperText={
                            <span style={{ visibility: isInvalid ? "visible" : "hidden" }}>
                                Please enter a valid email address
                            </span>
                        }
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: isInvalid ? "red" : "",
                                },
                            },
                        }}
                    />
                    <Box mt={1}>
                        <Box
                            sx={{
                                padding: "8px",
                                minHeight: "100px",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                            }}
                        >
                            {chips.length > 0 ? (
                                chips.map((chip, index) => (
                                    <Chip
                                        key={index}
                                        label={chip}
                                        onDelete={() => handleDelete(chip)}
                                        sx={{
                                            ".MuiChip-root": {
                                                fontSize: "0.875rem",
                                            },
                                        }}
                                    />
                                ))
                            ) : (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ fontStyle: "italic" }}
                                >
                                    No emails added yet
                                </Typography>
                            )}
                        </Box>
                    </Box>

                </Box>

            </Grid>
            <Grid item xs={12} spacing={2} sx={{ textAlign: "right" }}>
                <Box sx={{ mt: 4 }}>
                    <Button variant="outlined" size="large" type="submit" onClick={goback} sx={{ width: "180px" }}>
                        Go back
                    </Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button variant="contained" size="large" type="submit" disabled={chips.length === 0 || selectedList.name === ""} onClick={rollOutInvitation} >
                        {chips.length === 0 || selectedList.name === ""
                            ? "Invite Influencers"
                            : `Invite ${chips.length} Influencer${chips.length > 1 ? "s" : ""}`}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InviteByEmail;