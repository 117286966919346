import React, { useState } from "react";
import { Done } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  styled,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import {
  changeSubscriptionForAccount,
  reactivateSubscriptionForAccount,
} from "../../services/billing";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { changeBillingAttributes } from "../../redux/features/user/userSlice";
import BasePricing from "../../components/landing/BasePricing";

const PricingToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:first-of-type)": {
      borderRadius: "0px 10px 10px 0px",
      width: "180px",
      color: "black",
      borderColor: "black",
    },
    "&:first-of-type": {
      borderRadius: "10px 0px 0px 10px",
      width: "150px",
      marginRight: "0",
      color: "black",
      borderColor: "black",
    },
    "&.Mui-selected": {
      backgroundColor: "black",
      color: "white",
      "&:hover": {
        backgroundColor: "#333", // Slightly lighter shade on hover
      },
    },
  },
}));

function PricingCustomized({
  planDetails,
  user,
  typeOfRequest,
  cardDetails,
  handleUpgradeCardClose,
}) {
  const [pricingType, setPricingType] = useState("monthly");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { value } = event.target;
    setPricingType(value);
  };

  const upgradeAccount = async (planChangeTo, planName, activity) => {
    dispatch(
      notify({
        message:
          typeOfRequest === "upgrade"
            ? `Please wait... We will ${activity}  your subscription`
            : `Please wait... We are reactivating  your subscription`,
        type: "info",
      })
    );
    if ([2, 3, 6].includes(planChangeTo) && cardDetails.first_name === "") {
      dispatch(
        notify({
          message: "Please add your card details to upgrade your plan",
          type: "error",
        })
      );
      handleUpgradeCardClose(false);
      return;
    }

    if (typeOfRequest !== "upgrade") {
      changeBillingAttributes({
        isLocked: true,
      });
      const response = await reactivateSubscriptionForAccount(user, dispatch);
      if (response?.response === "success") {
        dispatch(
          notify({
            message: `Your subscription has been reactivated  successfully`,
            type: "success",
          })
        );
      } else {
        dispatch(notify({ message: response?.response, type: "error" }));
      }
    } else {
      const data = {
        agencyId: user.agencyId.toString(),
        newPlanCode: planChangeTo.toString(),
        email: user.email,
        newPlanName: planName,
      };
      const response = await changeSubscriptionForAccount(data, user, dispatch);
      if (response?.response === "success") {
        dispatch(
          notify({
            message: `Your subscription has been ${activity}d  successfully`,
            type: "success",
          })
        );
      } else {
        dispatch(notify({ message: response?.response, type: "error" }));
      }
    }

    handleUpgradeCardClose(false);
  };

  return (
    <>
      {/* Header */}
      <div
        className={styles.headerBox}
        style={{
          padding: "25px 25px 1px",
          backgroundColor: "white",
          height: "60%",
        }}
      >
        <div
          className={styles.title}
          style={{ fontSize: "40px", margin: "0px auto" }}
        >
          Influencer Marketing Plans and Pricing
        </div>
        <div className={styles.subTitle} style={{ fontSize: "18px" }}>
          Every plan lets you partner with unlimited ambassadors, influencers
          and affiliates to support your brand.
          <br />
          Choose the option to fit your sales volume and growth goals.
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "14px",
              marginRight: "100px",
            }}
          >
            <i>*Prices displayed in USD</i>
          </div>
        </div>

        {/* Plans */}

        <BasePricing
          customized={true}
          customizedPlanDetails={planDetails}
          typeOfRequest={typeOfRequest}
          upgradeAccount={upgradeAccount}
        />
      </div>

      {/* Book a call */}
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{
            width: "80%",
            margin: "auto 9% auto 11%",
            padding: "60px",
            border: "1px solid #DEE1E6",
            borderRadius: "20px",
            fontSize: "34px",
            lineHeight: "46px",
            fontWeight: "800",
            textAlign: "center",
          }}
        >
          If you're looking for something special,{" "}
          <a href="/requestDemo" style={{ textDecoration: "underline" }}>
            book a call
          </a>{" "}
          with us.
        </div>
      </div>
    </>
  );
}

export default PricingCustomized;
