import { AutoAwesome, Done, Upgrade } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import { updateBillingEmailForAccount } from "../../services/billing";

const PricingToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:first-of-type)": {
      borderRadius: "0px 10px 10px 0px",
      width: "180px",
      color: "black",
      borderColor: "black",
    },
    "&:first-of-type": {
      borderRadius: "10px 0px 0px 10px",
      width: "150px",
      marginRight: "0",
      color: "black",
      borderColor: "black",
    },
    "&.Mui-selected": {
      backgroundColor: "black",
      color: "white",
      "&:hover": {
        backgroundColor: "#333", // Slightly lighter shade on hover
      },
    },
  },
}));

function BasePricing(props) {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [pricingType, setPricingType] = useState("monthly");

  const handleChange = (event) => {
    const { value } = event.target;
    setPricingType(value);
  };

  const planDetails = [
    {
      id: 12,
      planName: "Free",
      monthly: {
        pricing: 0,
        tenure: " 7 days",
        currency: "$",
      },
      "6-months": {
        pricing: 0,
        tenure: "7 Days",
        currency: "$",
      },
      buttonCTA: "Start free 7-day Trial",
      actionCTA: "Get Started",
      padding: "5px",
      creditCardRequired: "No Credit Card Required",
      decsription: "Basic influencer discovery, analysis and campaign tracking",
      discoverySearch: 10,
      discoveryAnalysis: 10,
      teamMember: 1,
      activeCampaign: 1,
      discoveryPlatform: ["IG"],
      campaignPlatform: ["IG", "YT", "TT"],
      groups: "Default",
      influencers: 5,
      publications: 50,
      support: "Email",
      sentiment: false,
      currentPlan:
        props?.customizedPlanDetails?.planName == "Free" ? true : false,
      customActionCTA: "Downgrade",
    },
    {
      id: 6,
      planName: "Individual",
      monthly: {
        pricing: 98,
        tenure: "month",
        currency: "$",
      },
      "6-months": {
        pricing: 470,
        tenure: "6 months",
        currency: "$",
      },
      buttonCTA: "Start free 7-day Trial",
      actionCTA: "Get Started",
      padding: "5px",
      creditCardRequired: "No Credit Card Required",
      decsription:
        "Ideal for individuals starting out with Influencer marketing",
      discoverySearch: 1000,
      discoveryAnalysis: 20,
      teamMember: 1,
      activeCampaign: 1,
      discoveryPlatform: ["IG"],
      campaignPlatform: ["IG", "YT", "TT"],
      groups: "Default",
      influencers: 5,
      publications: 100,
      support: "Email",
      sentiment: false,
      currentPlan:
        props?.customizedPlanDetails?.planName === "Individual" ? true : false,
      customActionCTA:
        props.typeOfRequest !== "upgrade"
          ? "Subscribe"
          : props?.customizedPlanDetails?.planName === "Free"
          ? "Upgrade"
          : "Downgrade",
    },
    {
      id: 2,
      planName: "Growth",
      monthly: {
        pricing: 298,
        tenure: "month",
        currency: "$",
      },
      "6-months": {
        pricing: 1430,
        tenure: "6 months",
        currency: "$",
      },
      padding: "5px",
      buttonCTA: "Start free 7-day Trial",
      actionCTA: "Get Started",
      creditCardRequired: "No Credit Card Required",
      decsription: "For small businesses scaling their creator marketing",
      discoverySearch: 5000,
      discoveryAnalysis: 100,
      teamMember: 3,
      activeCampaign: 3,
      discoveryPlatform: ["IG", "YT", "TT"],
      campaignPlatform: ["IG", "YT", "TT"],
      groups: 10,
      influencers: 100,
      publications: "1000",
      support: "Email & Chat",
      sentiment: true,
      popular: props.customized ? false : true,
      currentPlan:
        props?.customizedPlanDetails?.planName === "Growth" ? true : false,
      customActionCTA:
        props.typeOfRequest !== "upgrade"
          ? "Subscribe"
          : props?.customizedPlanDetails?.planName === "Pro"
          ? "Downgrade"
          : "Upgrade",
    },
    {
      id: 3,
      planName: "Pro",
      monthly: {
        pricing: 1998,
        tenure: "month",
        currency: "$",
      },
      "6-months": {
        pricing: 9590,
        tenure: "6 months",
        currency: "$",
      },
      padding: "5px",
      buttonCTA: "Start free 7-day Trial",
      actionCTA: "Get Started",
      creditCardRequired: "No Credit Card Required",
      decsription:
        "For fast-growing businesses requiring advanced creator marketing tools",
      discoverySearch: "50,000",
      discoveryAnalysis: 750,
      teamMember: "Unlimited",
      activeCampaign: 5,
      discoveryPlatform: ["IG", "YT", "TT"],
      campaignPlatform: ["IG", "YT", "TT", "TW", "FB"],
      groups: 100,
      influencers: 200,
      publications: "10,000",
      support: "Email & Chat",
      sentiment: true,
      currentPlan:
        props?.customizedPlanDetails?.planName === "Pro" ? true : false,
      customActionCTA:
        props.typeOfRequest !== "upgrade" ? "Subscribe" : "Upgrade",
    },
    {
      planName: "Enterprise",
      monthly: {
        pricing: "Custom",
        tenure: "",
        currency: "",
      },
      "6-months": {
        pricing: "Custom",
        tenure: "",
        currency: "",
      },
      padding: "5px",
      buttonCTA: "Let’s Talk",
      actionCTA: "Let’s Talk",
      creditCardRequired: "Credit",
      decsription:
        "For large businesses with custom requirements & complex tech stack.",
      discoverySearch: "Custom",
      discoveryAnalysis: "Custom",
      teamMember: "Custom",
      activeCampaign: "Custom",
      discoveryPlatform: ["IG", "YT", "TT"],
      campaignPlatform: ["IG", "YT", "TT", "TW", "FB", "LI"],
      groups: "Custom",
      influencers: "Custom",
      publications: "Custom",
      support: "Email & Chat",
      sentiment: true,
      currentPlan:
        props?.customizedPlanDetails?.planName == "Enterprise" ? true : false,
      customActionCTA: "Contact us",
    },
  ];

  const filteredPlanDetails = planDetails.filter((plan) => {
    if (!props.customized) {
      return plan.planName !== "Free";
    } else if (props.customized && props.typeOfRequest !== "upgrade") {
      return plan.planName !== "Free";
    } else if (props.customized && props.typeOfRequest === "upgrade") {
      return plan.planName !== "Enterprise";
    }
    return true;
  });

  const handleCustomAction = (id, name, action) => {
    props.upgradeAccount(id, name, action);
  };

  return (
    <>
      {/* Header */}
      <div
        className={styles.headerBox}
        style={{
          padding: "25px 25px 1px",
          backgroundColor: "white",
          height: "60%",
        }}
      >
        {!props.customized && (
          <>
            <div style={{ textAlign: "center" }}>
              <span
                className={styles.title}
                style={{ fontSize: "40px", margin: "0px auto" }}
              >
                Influencer Marketing Plans and Pricing
              </span>
              <br />
              <span className={styles.subTitle} style={{ fontSize: "20px" }}>
                No matter your business needs or requirements, we have a plan to
                suit you.
              </span>
            </div>

            <div>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "30px",
                  fontSize: "18px",
                }}
              >
                <b>How often do you want to pay?</b>
                <br />
                <PricingToggleGroup
                  sx={{ paddingTop: "20px" }}
                  value={pricingType}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="monthly">Monthly</ToggleButton>
                  <ToggleButton value="6-months">
                    6 Months &nbsp;
                    <span
                      style={{
                        backgroundColor: "#F9D783",
                        padding: "2px",
                        borderRadius: "2px",
                        pointerEvents: "none",
                        color: "black",
                      }}
                    >
                      Save 20%
                    </span>
                  </ToggleButton>
                </PricingToggleGroup>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                  marginRight: "100px",
                }}
              >
                <i>*Prices displayed in USD</i>
              </div>
            </div>
          </>
        )}

        {/* Plans */}
        <div style={{ background: "white", paddingBottom: "50px" }}>
          <Grid
            container
            spacing={1}
            sx={{
              justifyContent: "center",
              padding: "0 20px",
              width: "94%",
              margin: "auto",
            }}
          >
            {filteredPlanDetails?.map((plan) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {props.customized ? (
                    <div
                      className={plan.currentPlan && styles.popularBanner}
                      style={{
                        visibility: plan.currentPlan ? "visible" : "hidden",
                      }}
                    >
                      Current Plan
                    </div>
                  ) : (
                    <div
                      className={plan.popular && styles.popularBanner}
                      style={{
                        visibility: plan.popular ? "visible" : "hidden",
                      }}
                    >
                      <AutoAwesome sx={{ height: "20px" }} /> Most Popular
                    </div>
                  )}

                  <div
                    className={
                      (plan.popular || plan.currentPlan) && styles.selectedBox
                    }
                    style={{ height: "100%", position: "relative" }}
                  >
                    <div
                      className={
                        !plan.popular && !plan.currentPlan && styles.curvedBox
                      }
                      style={{ height: "100%" }}
                    >
                      <div
                        className={
                          !plan.popular && !plan.currentPlan
                            ? styles.card
                            : styles.cardWithoutCuves
                        }
                        style={{ height: "100%" }}
                      >
                        <Box sx={{ height: "100%" }}>
                          <div className={styles.cardTitle}>
                            {plan.planName}
                          </div>
                          <div style={{ paddingTop: "10px" }}>
                            <span className={styles.pricing}>
                              {plan[pricingType].currency != "" &&
                                plan[pricingType].currency}
                              {plan[pricingType].pricing}
                            </span>
                            {plan[pricingType].tenure != "" && "/"}
                            {plan[pricingType].tenure}{" "}
                          </div>
                          <div
                            style={{
                              paddingTop: "20px",
                              height: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span className={styles.cardDescription}>
                              {plan.decsription}
                            </span>
                          </div>

                          {!props.customized && (
                            <div
                              style={{
                                paddingTop: plan.padding,
                                textAlign: "center",
                              }}
                            >
                              <Button
                                variant={
                                  plan.buttonCTA.includes("Talk")
                                    ? "outlined"
                                    : "filled"
                                }
                                value="active"
                                style={{
                                  backgroundColor: !plan.buttonCTA.includes(
                                    "Talk"
                                  )
                                    ? "#37ADCA"
                                    : "transparent",
                                  borderColor: plan.buttonCTA.includes("Talk")
                                    ? "#37ADCA"
                                    : "transparent",
                                  color: plan.buttonCTA.includes("Talk")
                                    ? "#37ADCA"
                                    : "white",
                                  borderRadius: "56px",
                                  width: "100%", // Set width to 100% to allow the button to scale with screen size
                                  maxWidth: "300px", // Ensure it doesn’t grow too large on bigger screens
                                  padding: "10px 30px", // Padding stays consistent for touch areas
                                  margin: "0 auto", // Center the button horizontally within its container
                                  boxSizing: "border-box", // Ensure padding is calculated inside the width
                                  paddingTop: {
                                    xs: "10px", // Small screens (mobile)
                                    sm: "20px", // Medium screens (tablet)
                                    md: "20px", // Larger screens (desktop)
                                    lg: plan.padding, // Larger desktop or based on specific padding
                                  },
                                }}
                                component={
                                  !props.customized ||
                                  plan.planName === "Enterprise"
                                    ? Link
                                    : "button"
                                }
                                to={`${
                                  plan.planName !== "Enterprise"
                                    ? `/subscribe?planName=${
                                        plan.planName
                                      }&tenure=${pricingType}&${
                                        (params.get("email") &&
                                          `&email=${params.get("email")}`) ||
                                        ""
                                      }`
                                    : "/requestDemo"
                                }`}
                              >
                                <b>{plan.buttonCTA}</b>
                              </Button>
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "#757575",
                                  fontSize: "10px",
                                  visibility:
                                    plan.creditCardRequired !== "Credit"
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {plan.creditCardRequired}
                              </p>
                            </div>
                          )}

                          <Box
                            sx={{
                              marginTop: props.customized ? "40px" : "20px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.teamMember} Team Member
                              {plan.teamMember !== 1 && "s"}
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.activeCampaign} Active Campaign
                              {plan.activeCampaign !== 1 && "s"}
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              Influencer Discovery (
                              {plan.discoveryPlatform.join(", ")}){" "}
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.discoveryAnalysis} Influencer Analysis/mo
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.discoverySearch} Influencer Discovery/mo
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.groups} Groups
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.campaignPlatform?.length} Campaign Channels
                              <br />
                              <Done
                                sx={{
                                  color: "#00D084",
                                  width: "20px",
                                  visibility: "hidden",
                                }}
                              />{" "}
                              ({plan.campaignPlatform.join(", ")}){" "}
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.influencers} Influencers
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.publications} Total Publications
                            </Typography>
                            <Typography>
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              {plan.support} Support
                            </Typography>

                            <p
                              style={{
                                visibility: plan.sentiment
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              <Done sx={{ color: "#00D084", width: "20px" }} />{" "}
                              Sentiment Analysis
                            </p>
                          </Box>
                        </Box>

                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Button
                            variant={!plan.popular ? "outlined" : "filled"}
                            value="active"
                            style={{
                              backgroundColor: plan.popular && "#EF8E56",
                              borderColor: !plan.popular && "#EF8E56",
                              color: !plan.popular ? "#EF8E56" : "white",
                              borderRadius: "56px",
                              width: "80%", // Set width to 100% to allow the button to scale with screen size
                              maxWidth: "250px", // Ensure it doesn’t grow too large on bigger screens
                              padding: "10px 30px", // Padding stays consistent for touch areas
                              margin: "0 auto", // Center the button horizontally within its container
                              boxSizing: "border-box", // Ensure padding is calculated inside the width
                              paddingTop: {
                                xs: "10px", // Small screens (mobile)
                                sm: "20px", // Medium screens (tablet)
                                md: "30px", // Larger screens (desktop)
                                lg: plan.padding, // Larger desktop or based on specific padding
                              },

                              visibility:
                                plan.planName === "Free" ||
                                (plan.planName ===
                                  props?.customizedPlanDetails?.planName &&
                                  props.typeOfRequest === "upgrade")
                                  ? "hidden"
                                  : "visible",
                            }}
                            onClick={(event) => {
                              if (
                                props.customized &&
                                plan.planName !== "Enterprise"
                              ) {
                                handleCustomAction(
                                  plan.id,
                                  plan.planName,
                                  event.target.textContent
                                ); // Pass button text content
                              }
                            }}
                            component={
                              !props.customized ||
                              plan.planName === "Enterprise"
                                ? Link
                                : "button"
                            }
                            to={`${
                              plan.planName !== "Enterprise"
                                ? `/subscribe?planName=${
                                    plan.planName
                                  }&tenure=${pricingType}&${
                                    (params.get("email") &&
                                      `&email=${params.get("email")}`) ||
                                    ""
                                  }`
                                : "/requestDemo"
                            }`}
                          >
                            {props.customized ? (
                              <b>{plan.customActionCTA}</b>
                            ) : (
                              <b>{plan.actionCTA}</b>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <br />

      {!props.customized && (
        <>
          {/* Book a call */}
          <div style={{ backgroundColor: "white" }}>
            <div
              style={{
                width: "80%",
                margin: "auto",
                padding: "60px",
                fontSize: "30px",
                lineHeight: "46px",
                fontWeight: "800",
                textAlign: "center",
              }}
            >
              Start Your Free 7-Days Trial Now
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                  padding: "30px",
                  fontSize: "24px",
                  border: "1px solid black",
                  lineHeight: "30px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Get started with our free trial and launch your campaign today!
                <Button
                  variant={"filled"}
                  value="active"
                  style={{
                    backgroundColor: "#F9D783",
                    color: "black",
                    borderRadius: "2px",
                    fontSize: "20px",
                    padding: "2px",
                  }}
                  component={Link}
                  to="/subscribe?planName=Free&tenure=monthly"
                >
                  Signup for Free
                </Button>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  paddingTop: "20px",
                  lineHeight: "10px",
                  fontWeight: "100",
                  textAlign: "center",
                }}
              >
                No credit card required
              </div>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonials />

          {/* Top Brands */}
          <TopBrands />
        </>
      )}
    </>
  );
}

export default BasePricing;
