import { Grid } from "@mui/material";
import { InfoToolTip } from "../../../common/toolTip";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";

export const tableHeaders = [
  {
    id: 1,
    label: "Publication",
    mappedValue: "Publication",
    field: "post",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },

  {
    id: 2,
    label: " ",
    mappedValue: "Publication",
    field: "Posttype",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: false,
  },

  {
    id: 3,
    label: "",
    mappedValue: "Influencer",
    field: "influencerAvatar",
    headeralignment: "center",
    bodyalignment: "right",
    sortable: false,
    show: true,
    showSelect: false,
  },

  {
    id: 4,
    label: "Influencer",
    mappedValue: "Influencer",
    field: "influencer",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 5,
    label: "Task",
    mappedValue: "Task",
    field: "title",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 6,
    label: "Milestone",
    mappedValue: "Milestone",
    field: "milestone",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 7,
    label: "Posted",
    mappedValue: "Posted",
    headerAssignment: "createdAt",
    field: "a.postedOn",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
    showSelect: true,
  },
  {
    id: 8,
    label: (
      <Grid container spacing={0.5} style={{ width: "115px" }}>
        <Grid item xs={9}>
          Engagement
        </Grid>

        <Grid item xs={3}>
          <InfoToolTip text="Displays the engagement of the publication over time as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Engagement",
    field: "totalEngagement",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 11,
    label: (
      <Grid container spacing={0.5} style={{ width: "115px" }}>
        <Grid item xs={9}>
          Views
        </Grid>

        <Grid item xs={3}>
          <InfoToolTip text="Displays the view of the publication (video/reel) over time as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Views",
    field: "totalViews",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 9,
    label: (
      <Grid container spacing={0.5} style={{ width: "65px" }}>
        <Grid item xs={8}>
          Reach
        </Grid>
        <Grid item xs={4}>
          <InfoToolTip text="Displays the total reach of the publication over time as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Reach",
    field: "reach",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 10,
    label: (
      <Grid container spacing={0.8} style={{ width: "65px" }}>
        <Grid item xs={6}>
          ER%
        </Grid>
        <Grid item xs={6}>
          <InfoToolTip text="Average engagement rate on the posts as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "ER%",
    field: "er%",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: true,
  },
  {
    id: 11,
    label: "Group Name",
    mappedValue: "Group Name",
    field: "groupName",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: false,
    showSelect: true,
  },
  {
    id: 12,
    label: (
      <>
        <Grid container>
          <Grid item xs={1} className={styles.bodycell}></Grid>
          <Grid item xs={6}>
            Status
          </Grid>
        </Grid>
      </>
    ),
    mappedValue: "Status",
    field: "status",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: true,
    show: true,
    showSelect: true,
  },
  {
    id: 13,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
    showSelect: false,
  },
];
