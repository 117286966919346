import {
  AccountBox,
  Campaign,
  SettingsInputComponent,
  PersonSearchRounded,
  PhotoSizeSelectActual,
  Groups,
  MonetizationOn,
  PersonAdd,
  IntegrationInstructions,
  ManageAccounts,
  Settings,
  Insights,
  Equalizer,
  ShowChart,
  TextSnippet,
  Receipt,
  Article,
  Assessment,
} from "@mui/icons-material";
import { styled, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:first-of-type)": {
      borderRadius: "0px 10px 10px 0px",
      width: "209px",
    },
    "&:first-of-type": {
      borderRadius: "10px 0px 0px 10px",
      width: "209px",
      marginRight: "0",
    },
  },
}));
const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#605BFF",
    border: 0,
  },
});
const StyledStaticButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&:first-of-type": {
      borderRadius: "10px 10px 10px 10px",
      width: "200px",
    },
  },
}));

const StyledStaticButton = styled(ToggleButton)({
  "&": {
    color: "white",
    backgroundColor: "#605BFF",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#3730ff",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "grey",
  },
});

const CampaignStyledStaticButton = styled(ToggleButton)({
  "&": {
    color: "white",
    backgroundColor: "#474747",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#3730ff",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "grey",
  },
});

const StyledStaticButtonRoundedGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&:first-of-type": {
        borderRadius: "56px",
        width: "250px",
        padding: "20px 40px 21px",
        textAlign: "center",
      },
    },
  })
);

const StyledStaticRoundedButton = styled(ToggleButton)({
  "&": {
    color: "white",
    backgroundColor: "#4A3AFF",
    fontSize: "18px",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#3730ff",
  },
  "&:disabled": {
    color: "white",
    backgroundColor: "grey",
  },
});
const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0C5E96" : "#0C5E96",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#8796A5" : "#aab4be",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const IconMapping = {
  Home: { icon: <HomeIcon />, default: false },
  Campaigns: { icon: <Campaign />, default: false },
  Account: { icon: <AccountBox />, default: true },
  Settings: { icon: <SettingsInputComponent />, default: true },
  Discovery: { icon: <PersonSearchRounded />, default: false },
};

const SubIconMapping = {
  Overview: { icon: <ShowChart /> },
  Analytics: { icon: <Equalizer /> },
  Publications: { icon: <PhotoSizeSelectActual /> },
  Contracts: { icon: <Article /> },
  Influencers: { icon: <Groups /> },
  "Adv. Analytics": { icon: <Insights /> },
  "Reports": { icon: <Assessment /> },
  "My Profile": { icon: <ManageAccounts /> },
  General: { icon: <Settings /> },
  Integrations: { icon: <IntegrationInstructions /> },
  Users: { icon: <Groups /> },
  "Invite New Users": { icon: <PersonAdd /> },
  Billing: { icon: <MonetizationOn /> },
  Credits: { icon: <Receipt /> },
  "User Logs": { icon: <TextSnippet /> },
  "Tasks": { icon: <Insights /> },
};

const RoundedTextField = {
  "& .MuiOutlinedInput-root": {
    border: "1px solid #EFF0F7",
    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
    borderRadius: "36px",
  },
  fieldset: {
    legend: {
      width: "unset",
    },
  },
  width: "100%",
};

export {
  StyledToggleButtonGroup,
  StyledToggleButton,
  StyledStaticButtonGroup,
  StyledStaticButton,
  StyledSwitch,
  IconMapping,
  SubIconMapping,
  StyledStaticButtonRoundedGroup,
  StyledStaticRoundedButton,
  RoundedTextField,
  CampaignStyledStaticButton
};
