import React, { useState, useEffect } from "react";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  ChevronRightOutlined,
  KeyboardArrowDown,
  OpenInNew,
} from "@mui/icons-material";
import CampaignLinksActionButton from "./CampaignLinksActionButton";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useDispatch, useSelector } from "react-redux";
import { getLinkTableData } from "../../../../services/campaignAnalytics";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import Loader from "../../../common/loader";
import { useLocation } from "react-router-dom";

const CampaignLinksTable = ({
  selectedStartDate,
  selectedEndDate,
  shorturlIds,
}) => {
  const [bodyColumns, setBodyColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("clicks");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalRows, setTotalRows] = useState(0);
  const [expand, setExpand] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [linksData, setLinksData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const tableHeadersSingleBase = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "Influencer",
      field: "influencer",
      headeralignment: "center",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 2,
      label: "Group",
      mappedValue: "Group",
      field: "group",
      headeralignment: "center",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 3,
      label: "URL",
      mappedValue: "URL",
      field: "url",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 4,
      label: "Created On",
      mappedValue: "Created On",
      field: "created",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 5,
      label: "Clicks",
      mappedValue: "Clicks",
      field: "clicks",
      headeralignment: "left",
      bodyalignment: "left",
      sortable: true,
      show: true,
    },
    {
      id: 6,
      label: "",
      mappedValue: "",
      field: "action",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
  ];
  const tableHeaderMultipleBase = [
    {
      id: 1,
      label: "Destination URL",
      mappedValue: "Destination URL",
      field: "destinationUrl",
      headeralignment: "center",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 2,
      label: "Group",
      mappedValue: "Group",
      field: "group",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 5,
      label: "Total Clicks",
      mappedValue: "Total Clicks",
      field: "totalClicks",
      headeralignment: "left",
      bodyalignment: "left",
      sortable: true,
      show: true,
    },
  ];
  const subTableHeader = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "Influencer",
      field: "influencer",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 2,
      label: "URL",
      mappedValue: "URL",
      field: "url",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 3,
      label: "Clicks",
      mappedValue: "Clicks",
      field: "clicks",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
  ];

  useEffect(() => {
    getLinkTableDataValues();
  }, []);

  useEffect(() => {
    if (expand?.length === 0 || tableData?.length === 0) {
      return;
    }
    createFinalData(linksData, page, rows, sortOrder, sortCol);
  }, [expand, linksData]);

  const getLinkTableDataValues = async () => {
    setTableLoading(true);
    const linksData = await getLinkTableData(
      shorturlIds,
      getFormattedDate(selectedStartDate, "YYYY-MM-DD"),
      getFormattedDate(selectedEndDate, "YYYY-MM-DD"),
      +params.get("id"),
      user,
      dispatch
    );

    const baseDestinationIds = [];
    linksData?.response?.forEach((row) => {
      if (!baseDestinationIds.includes(row.baseDestinationId)) {
        baseDestinationIds.push(row.baseDestinationId);
      }
    });

    if (baseDestinationIds?.length <= 1) {
      const expand = Array.apply(null, Array(linksData.response?.length))?.map(
        function () {
          return false;
        }
      );
      setExpand(expand);
    } else {
      const expand = Array.apply(null, Array(baseDestinationIds?.length))?.map(
        function () {
          return false;
        }
      );
      setExpand(expand);
    }
    setTableLoading(false);
    setLinksData(linksData.response);
    createFinalData(linksData.response, page, rows, sortOrder, sortCol);
  };

  const createFinalData = (data, page, rows, sortOrder, sortCol) => {
    const final_data = [],
      baseDestinationIds = [],
      baseDestinations = [];

    data?.forEach((row) => {
      if (!baseDestinationIds.includes(row.baseDestinationId)) {
        baseDestinationIds.push(row.baseDestinationId);
        baseDestinations.push(row);
      }
    });

    setTotalRows(
      baseDestinationIds?.length > 1
        ? baseDestinationIds?.length
        : shorturlIds?.length
    );

    if (baseDestinationIds?.length <= 1) {
      setTableHeaders(tableHeadersSingleBase);
      setTotalRows(shorturlIds?.length);

      data?.forEach((row, index) => {
        const isExpanded = expand[index];
        let object = {
          id: row.shortUrlId,
          selectedId: index,
          influencer: (
            <Grid container>
              <Grid item xs={2} style={{ margin: "auto" }}>
                <IconButton onClick={() => expandClick(row, index)}>
                  {isExpanded ? (
                    <KeyboardArrowDown />
                  ) : (
                    <ChevronRightOutlined />
                  )}
                </IconButton>
              </Grid>

              <Grid item xs={10} style={{ margin: "auto" }}>
                <Grid container>
                  <Grid item xs={12} className={styles.bodycell}>
                    <span>{row.influencerName}</span>
                    {row.profileUrl !== "" && (
                      <a
                        href={row.profileUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: "10px" }}
                      >
                        <OpenInNew fontSize="small" />
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),
          group: (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography style={textColor[row.platform.toLowerCase()]}>
                  {snapshotHeaderIcons[row.platform.toLowerCase()]}
                </Typography>
              </Grid>
              <Grid item>{row.listName}</Grid>
            </Grid>
          ),
          url: (
            <Grid container spacing={1}>
              <Grid item xs={4} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                <a href={row.destinationUrl} target="_blank" rel="noreferrer">
                  {row.shortUrl}
                </a>
              </Grid>
            </Grid>
          ),
          created: (
            <Grid container spacing={1}>
              <Grid item xs={10} className={styles.bodycell}>
                {row.createdAt}
              </Grid>
            </Grid>
          ),
          clicks: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.clicks}
              </Grid>
            </Grid>
          ),
          sort: row.clicks,
          action: (
            <Grid container spacing={1}>
              <Grid item>
                <CampaignLinksActionButton selectedRow={row} />
              </Grid>
            </Grid>
          ),
          expand: [
            {
              colSpan: 6,
              content: (
                <>
                  <div className={styles.expandHeading}>Destination URL:</div>
                  <div className={styles.expandBody}>{row.destinationUrl}</div>
                </>
              ),
            },
          ],
        };
        final_data.push(object);
      });
    } else {
      setTableHeaders(tableHeaderMultipleBase);

      baseDestinations.forEach((destination, index) => {
        const rows = data?.filter(
          (row) => row.baseDestinationId === destination.baseDestinationId
        );

        const subRows = [];
        let totalClicks = 0;
        rows.forEach((row) => {
          totalClicks += row.clicks;
          const object = {
            influencer: (
              <Grid container>
                <Grid item xs={12} className={styles.bodycell}>
                  <span>{row.influencerName}</span>
                  {row.profileUrl !== "" && (
                    <a
                      href={row.profileUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginLeft: "10px" }}
                    >
                      <OpenInNew fontSize="small" />
                    </a>
                  )}
                </Grid>
              </Grid>
            ),
            url: (
              <Tooltip title={row.destinationUrl}>
                <a href={row.destinationUrl} rel="noreferrer" target="_blank">
                  {row.shortUrl}
                </a>
              </Tooltip>
            ),
            clicks: row.clicks,
          };
          subRows.push(object);
        });

        subRows.sort(function (a, b) {
          if (a.clicks > b.clicks) return -1;
          if (a.clicks < b.clicks) return 1;
          return 0;
        });

        const isExpanded = expand[index];
        const object = {
          id: destination.baseDestinationId,
          selectedId: index,
          destinationUrl: (
            <Grid container>
              <Grid item xs={2} style={{ margin: "auto" }}>
                <IconButton onClick={() => expandClick(destination, index)}>
                  {isExpanded ? (
                    <KeyboardArrowDown />
                  ) : (
                    <ChevronRightOutlined />
                  )}
                </IconButton>
              </Grid>

              <Grid item xs={10} style={{ margin: "auto" }}>
                <a
                  href={destination.baseDestinationUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {destination.baseDestinationUrl}
                </a>
              </Grid>
            </Grid>
          ),
          group: (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  style={textColor[destination.platform.toLowerCase()]}
                >
                  {snapshotHeaderIcons[destination.platform.toLowerCase()]}
                </Typography>
              </Grid>
              <Grid item>{destination.listName}</Grid>
            </Grid>
          ),
          totalClicks: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {totalClicks}
              </Grid>
            </Grid>
          ),
          sort: totalClicks,
          expand: [
            {
              colSpan: 3,
              content: (
                <>
                  <CustomizedTable
                    tableHeaders={subTableHeader}
                    bodyColumns={subRows}
                    totalRows={subRows?.length}
                    pageNo={0}
                    rowsPerPageNo={subRows?.length}
                    sortOrder={"desc"}
                    sortOrderBy={"totalClicks"}
                    pagination={false}
                    nested={true}
                  />
                </>
              ),
            },
          ],
        };
        final_data.push(object);
      });
    }

    // if (sortOrder === "desc") {
    //   final_data.sort(function (a, b) {
    //     if (a.sort > b.sort) return -1;
    //     if (a.sort < b.sort) return 1;
    //     return 0;
    //   });
    // } else {
    //   final_data.sort(function (a, b) {
    //     if (a.sort < b.sort) return -1;
    //     if (a.sort > b.sort) return 1;
    //     return 0;
    //   });
    // }

    setTableData(final_data);
    createData(final_data.slice(page * rows, (page + 1) * rows));
  };

  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);

      const newExpand = expand?.map(() => false);
      setExpand(newExpand);
    }
  };

  const expandClick = (row, index) => {
    setSelectedRow(row);
    changeExpansionArray(index);
  };

  const changeExpansionArray = (index) => {
    const newExpandedRows = [...expand];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpand(newExpandedRows);
  };

  const createData = (tableData) => {
    setBodyColumns(tableData);
  };

  return (
    <>
      <div className={styles.background}>
        {!tableLoading ? (
          <CustomizedTable
            tableHeaders={tableHeaders}
            bodyColumns={bodyColumns}
            expand={expand}
            totalRows={totalRows}
            pageNo={page}
            rowsPerPageNo={rows}
            sortOrder={sortOrder}
            sortOrderBy={sortCol}
            selectedRow={selectedRow}
            sendInformation={sendInformation}
            staticTable={true}
          />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

CampaignLinksTable.propTypes = {
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  shorturlIds: PropTypes.array.isRequired,
};

export default CampaignLinksTable;
