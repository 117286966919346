import { Box, Grid, Tab } from "@mui/material";
import React, { useState } from "react";
import CampaignDraftsFilter from "./CampaignDraftsFilter";
import { snapshotHeaderIcons } from "../../../../utility/plaformIcons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CheckCircleRounded, PendingRounded } from "@mui/icons-material";
import CampaignDraftsPending from "./CampaignDraftsPending";
import CampaignDraftsApproved from "./CampaignDraftsApproved";
import { useLocation } from "react-router-dom";

const CampaignDraftsSnapshot = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [selectedPlatforms, setSelectedPlatforms] = useState(Object.keys(snapshotHeaderIcons));
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [tabValue, setTabValue] = useState("review");
  const universal = params.get("universal");
  const [selectedTasks, setSelectedTasks] = useState([]);

  const sendFilterChange = ({ selectedPlatforms, selectedGroups, selectedInfluencers, selectedTasks }) => {
    setSelectedPlatforms(selectedPlatforms);
    setSelectedGroups(selectedGroups);
    setSelectedInfluencers(selectedInfluencers);
    setSelectedTasks(selectedTasks);
  };

  const handleTabChange = (_, value) => {
    setTabValue(value);
  };

  return (
    <>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CampaignDraftsFilter
            allSelectedPlatforms={selectedPlatforms}
            allSelectedGroups={selectedGroups}
            allSelectedInfluencers={selectedInfluencers}
            allSelectedTasks={selectedTasks}
            sendFilterChange={sendFilterChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={tabValue}>
              <Box sx={{ bgcolor: "background.paper" }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab icon={<PendingRounded />} label="Posts for Review" value="review" />
                  <Tab icon={<CheckCircleRounded />} label="Approved Posts" value="approved" />
                </TabList>
              </Box>
              <TabPanel value={tabValue}>
                {tabValue === "review" && (
                  <CampaignDraftsPending
                    allSelectedPlatforms={selectedPlatforms}
                    allSelectedGroups={selectedGroups}
                    allSelectedInfluencers={selectedInfluencers}
                    allSelectedTasks={selectedTasks}
                  />
                )}
                {tabValue === "approved" && (
                  <CampaignDraftsApproved
                    allSelectedPlatforms={selectedPlatforms}
                    allSelectedGroups={selectedGroups}
                    allSelectedInfluencers={selectedInfluencers}
                    allSelectedTasks={selectedTasks}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignDraftsSnapshot;
