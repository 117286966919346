import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/internal/Subscribe.module.css";
import { Card, Grid, Paper, Button } from "@mui/material";
import SubscribeForm from "../components/subscribe/SubscribeForm";
import PaymentForm from "../components/subscribe/PaymentForm";
import { useLocation } from "react-router-dom";
import { getDetailsForPlan } from "../services/billing";
import SubscriptionSuccessful from "../components/subscribe/SubscriptionSuccessful";
import PaymentSlip from "../components/subscribe/PaymentSlip";
import config from "../config/main.json";
import { accountVerify } from "../services/accountVerify";
import { useDispatch } from "react-redux";
import { notify } from "../redux/features/system/systemAlert";

function Subscribe() {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const tenure = params.get("tenure");
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [planDetails, setPlanDetails] = useState({});
  const [billingDetails, setBillingDetails] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
    if (planName) {
      getPlanDetails();
    }
  }, [planName]);

  const getPlanDetails = async () => {
    const planDetails = await getDetailsForPlan(planName);
    setPlanDetails(planDetails.response);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleStepSkipped = (event) => {
    setSuccess(true);
    setBillingDetails(event.billingDetails);
    setEmail(event.email);
  };

  const getCouponCode = (event) => {
    setCouponCode(event);
  };

  const getDiscountedAmount = (event) => {
    setAmount(event);
  };

  const handleDashboardClick = () => {
    window.open(`${config.BASE_URL}/login`, "_blank");
  };

  const handleResendEmailClick = async () => {
    const response = await accountVerify(email);

    if (response?.response.statusCode !== 200) {
      dispatch(
        notify({
          message: "response?.response.message",
          type: "error",
        })
      );
    } else {
      dispatch(
        notify({
          message: "Email Sent successfully",
          type: "success",
        })
      );
    }
  };

  const card = (
    <>
      {success ? (
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "40px",
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <div className={styles.card2}>
              <Card
                sx={{
                  width: 900,
                  minHeight: 200,
                  padding: 5,
                  borderRadius: "8px",
                  backgroundColor: "#F7F9FA",
                }}
              >
                <SubscriptionSuccessful
                  billingDetails={billingDetails}
                  couponCode={couponCode}
                  amount={amount}
                  planDetails={planDetails}
                  tenure={tenure}
                />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12}>
            {planName === "Free" && (
              <Grid item xs={12}>
                <p className={styles.verificationMessage}>
                  Please check your Inbox to verify your email and set login
                  password.
                </p>
              </Grid>
            )}
            <div className={styles.card2}>
              <Card
                sx={{
                  width: 900,
                  minHeight: 200,
                  padding: 5,
                  borderRadius: "8px",
                  backgroundColor: "#F7F9FA",
                }}
              >
                <PaymentSlip
                  billingDetails={billingDetails}
                  email={email}
                  amount={amount}
                  planDetails={planDetails}
                  tenure={tenure}
                />
              </Card>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            className={styles.buttonContainer}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDashboardClick}
              className={styles.dashboardButton}
            >
              Take me to Dashboard
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleResendEmailClick}
              className={styles.resendEmailButton}
            >
              Resend Email
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div className={styles.subscribeContainer}>
          <div className={styles.subscribeTitleContainer}>
            <h1 className={styles.title}>
              Subscribe to {planName} Plan - 7-day free Trial
              <hr style={{ width: "50%", marginLeft: "360px" }} />
            </h1>
          </div>
          <Grid container spacing={4} className={styles.subscribeGridContainer}>
            <Grid item xs={12} md={6}>
              <div className={styles.subscribeCard}>
                <Paper
                  sx={{
                    width: "100%",
                    minHeight: 500,
                    padding: 5,
                    borderRadius: "8px",
                  }}
                >
                  <SubscribeForm
                    handleStepSkipped={handleStepSkipped}
                    planDetails={planDetails}
                    couponCode={couponCode}
                  />
                </Paper>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={styles.subscribeCard}>
                <Paper
                  sx={{
                    width: "100%",
                    minHeight: 500,
                    padding: 5,
                    borderRadius: "8px",
                  }}
                >
                  <PaymentForm
                    planDetails={planDetails}
                    getCouponCode={getCouponCode}
                    getDiscountedAmount={getDiscountedAmount}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
  return (
    <div ref={elementRef} className={styles.subscribeWrapper}>
      {card}
    </div>
  );
}

export default Subscribe;
