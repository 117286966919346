import { PropTypes } from "prop-types";
import ReactEcharts from "echarts-for-react";
import { BarChart, DoughnutChart, BasicLineChart } from "../../../common/chart";
import { Card, Grid } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import AudienceProgressChart from "../../../common/AudienceProgressChart";

const CampaignGaGraphs = ({
  linkClicksY,
  linkClicksX,
  conversionsData,
  referresData,
  bounceRate,
}) => {
  return (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Total Views </b>
            <Card style={{ padding: 20, float: "right", width: "175px" }}>
              <span
                style={{
                  textAlign: "center",
                  color: "rgb(0, 125, 255)",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                }}
              >
                Bounce Rate : {bounceRate + "%"}
              </span>
            </Card>
          </h4>
          {linkClicksY?.length > 0 && linkClicksX?.length > 0 ? (
            <ReactEcharts
              option={BarChart(linkClicksX, true, [
                {
                  data: linkClicksY,
                  type: "bar",
                  color: "#4FD1C5",
                  barCategoryGap: "60%",
                },
              ])}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Google Integration Found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Devices </b>
          </h4>
          {conversionsData?.length > 0 ? (
            <ReactEcharts
              option={DoughnutChart(
                "Clicks & Conversions",
                ["#5368F0", "#007AFF", "#00FF00", "#FFD700", "#87CEFA"],
                "vertical",
                10,
                50,
                conversionsData,
                ["40%", "70%"],
                false
              )}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Google Integration Found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 20, height: "100%" }}>
          <h4>
            <b className={styles.title}>Referrers </b>
          </h4>
          {referresData?.length > 0 ? (
            <AudienceProgressChart
              parentStyle={styles}
              data={referresData}
              country={false}
              tooltip={true}
              tooltipText={
                "Referrals show you sites or apps that referred visitors to your destination site by clicking a link."
              }
            />
          ) : (
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Google Integration Found
            </p>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

CampaignGaGraphs.propTypes = {
  linkClicksY: PropTypes.array.isRequired,
  linkClicksX: PropTypes.array.isRequired,
  conversionsData: PropTypes.array.isRequired,
};

export default CampaignGaGraphs;
