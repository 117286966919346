import {
  Button,
  Grid,
  Paper,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import Box from "@mui/material/Box";
import { Check } from "@mui/icons-material";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import moment from "moment";
import Step1 from "../../components/campaignBrief/Step1";
import Step2Brand from "../../components/campaignBrief/Step2Brand";
import Step2Reach from "../../components/campaignBrief/Step2Reach";
import Step3Brand from "../../components/campaignBrief/Step3Brand";
import Step3Reach from "../../components/campaignBrief/Step3Reach";
import Step4 from "../../components/campaignBrief/Step4";
import { steps } from "../../components/campaignBrief/FormConstants";
import { useLocation } from "react-router-dom";
import {
  getRequestData,
  submitCampaignBrief,
} from "../../services/campaignBrief";
import configs from "../../config/main.json";

const submitImage = `${configs.BASE_IMAGE_URL}campaignBriefSubmit.svg`;
const image = `${configs.BASE_IMAGE_URL}manWithSpeaker.svg`;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#4a3aff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#4a3aff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 34,
  width: 34,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#4a3aff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#4a3aff",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#4a3aff",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function CampaignBriefForm() {
  const minDate = moment().add(1, "months").format().split("T")[0];

  const [width, setWidth] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [step1Valid, setStep1Valid] = useState(false);
  const [step1Values, setStep1Values] = useState({
    organisation: "",
    organisationType: "Product/Service or Brand(s)",
    start: "As soon as possible",
    startDate: minDate,
    summary: "",
  });
  const [step2Valid, setStep2Valid] = useState(false);
  const [step2Values, setStep2Values] = useState({});
  const [step3Valid, setStep3Valid] = useState(true);
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({
    name: "",
    email: "",
    position: "",
    phone: "",
  });
  const [formType, setFormType] = useState("");
  const [submit, setSubmit] = useState(false);
  const [load, setLoad] = useState(false);
  const [uuid, setUuid] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [prefillData, setPrefillData] = useState({});

  const search = useLocation().search;

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
    const params = new URLSearchParams(search);
    const uuid = params.get("id");
    if (uuid === null) {
      setLoad(true);
    } else {
      setUuid(uuid);
      getFieldsData(uuid);
      setReadOnly(true);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        ["Product/Service or Brand(s)"].includes(step1Values.organisationType)
      ) {
        if (step2Values.brandName === undefined) {
          setStep2Values({
            objective: "",
            brandName: "",
            attributes: [],
            category: "",
            target: "",
          });
        }
        setFormType("brand/agency");
      } else if (
        ["NGO/NPO, or a Donor"].includes(step1Values.organisationType)
      ) {
        if (step2Values.focus === undefined) {
          setStep2Values({
            objective: "",
            focus: [],
            goals: "",
          });
        }
        setFormType("reach");
      }
    }
    if (activeStep === 1) {
      if (formType === "brand/agency") {
        if (step3Values.interests === undefined) {
          setStep3Values({
            age: [],
            gender: [],
            interests: [],
            industry: [],
            languages: [],
            locations: [],
          });
        }
      } else if (formType === "reach") {
        if (step3Values.religion === undefined) {
          setStep3Values({
            age: [],
            gender: [],
            industry: [],
            languages: [],
            religion: false,
            audience: [],
            locations: [],
            social: [],
          });
        }
      }
    }
    if (activeStep === 2 && uuid !== null) {
      setStep4Values({
        name: prefillData.name,
        email: prefillData.email,
        position: "",
        phone: "",
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitForm = () => {
    const responseData = {
      step1: step1Values,
      step2: step2Values,
      step3: step3Values,
      step4: step4Values,
    };
    const response = JSON.stringify(responseData);
    const notificationMetadata = {
      userId: step4Values.name.trim(),
      sourcePlatform: configs.PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "10",
      generatedBy: step4Values.email.trim(),
      priority: "low",
      consumedBy: "oryx",
      navigateTo: "/bdTools/campaignBrief",
    };

    const body = {
      uuid: uuid ? uuid : "",
      name: step4Values.name,
      email: step4Values.email,
      organisation: step1Values.organisation,
      response: response,
      notificationMetadata,
    };

    submitCampaignBrief(body).then((res) => {
      if (res.status) {
        setSubmit(true);
      }
    });
  };

  const getFieldsData = async (uuid) => {
    const requestData = await getRequestData(uuid);

    if (requestData.status) {
      if (requestData.response.status === "already submitted") {
        setLoad(true);
        setSubmit(true);
      } else {
        const prefillData = requestData.response[0];
        setPrefillData(prefillData);
        setStep1Values({
          organisation: prefillData.account,
          organisationType: "Product/Service or Brand(s)",
          start: "As soon as possible",
          startDate: minDate,
          summary: "",
        });
        setLoad(true);
      }
    }
  };

  const content = (
    <>
      <div className={styles.title}>Tell Us About Your Campaign</div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle_text}>
          Please fill the Campaign Creative Brief to receive a proposal with
          complete details.
        </div>
      </div>
      <div className={styles.card}>
        {!submit && (
          <Paper sx={{ width: "574px", minHeight: "750px" }}>
            {load && (
              <>
                <Box sx={{ margin: "25px 30px 35px 30px" }}>
                  <Stepper
                    activeStep={activeStep}
                    connector={<QontoConnector />}
                  >
                    {steps?.map((label) => {
                      return (
                        <Step key={label}>
                          <StepLabel />
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
                <hr style={{ margin: "0 10px 0 10px" }} />
                <Box sx={{ margin: "20px", minHeight: "570px" }}>
                  {activeStep === 0 && (
                    <Step1
                      setValid={setStep1Valid}
                      step1Values={step1Values}
                      setStep1Values={setStep1Values}
                      isReadOnly={
                        readOnly && prefillData.account !== "" ? true : false
                      }
                    />
                  )}
                  {activeStep === 1 && formType === "brand/agency" && (
                    <Step2Brand
                      setValid={setStep2Valid}
                      step2Values={step2Values}
                      setStep2Values={setStep2Values}
                    />
                  )}
                  {activeStep === 1 && formType === "reach" && (
                    <Step2Reach
                      setValid={setStep2Valid}
                      step2Values={step2Values}
                      setStep2Values={setStep2Values}
                    />
                  )}
                  {activeStep === 2 && formType === "brand/agency" && (
                    <Step3Brand
                      setValid={setStep3Valid}
                      step3Values={step3Values}
                      setStep3Values={setStep3Values}
                    />
                  )}
                  {activeStep === 2 && formType === "reach" && (
                    <Step3Reach
                      setValid={setStep3Valid}
                      step3Values={step3Values}
                      setStep3Values={setStep3Values}
                    />
                  )}
                  {activeStep === 3 && (
                    <Step4
                      submitForm={submitForm}
                      step4Values={step4Values}
                      setStep4Values={setStep4Values}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    className={styles.previous_button}
                    style={activeStep === 0 ? { visibility: "hidden" } : {}}
                    onClick={handleBack}
                  >
                    Previous Step
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.next_button}
                    style={activeStep !== 3 ? {} : { visibility: "hidden" }}
                    disabled={
                      !(activeStep === 0 && step1Valid) &&
                      !(activeStep === 1 && step2Valid) &&
                      !(activeStep === 2 && step3Valid)
                    }
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        )}
        {submit && (
          <Paper sx={{ width: "574px", minHeight: "750px" }}>
            <div className={styles.submit_card}>
              <img
                className={styles.submit_image}
                src={submitImage}
                alt="img"
              />
              <div className={styles.submit_title}>Thank You!</div>
              <div
                className={styles.submit_text}
                style={{ marginBottom: "120px" }}
              >
                We have received your details, someone from AIfluence will get
                back to you soon.
              </div>
              <div
                className={styles.submit_text}
                style={{ marginBottom: "30px" }}
              >
                For any other clarification or details feel free to email us
                at&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:support@empowr.ai"
                >
                  support@empowr.ai
                </a>
              </div>
            </div>
          </Paper>
        )}
      </div>
    </>
  );

  return (
    <>
      <div ref={elementRef}>
        {width >= 1300 && (
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              {content}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.image}>
                <img src={image} alt="img" />
              </div>
            </Grid>
          </Grid>
        )}
        {width < 1300 && content}
      </div>
    </>
  );
}

export default CampaignBriefForm;
