import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../redux/features/system/systemAlert";
import {
  createCampaignGoal,
  editCampaignGoal,
  getDetailedCampaignGoal,
} from "../../../services/campaigns";
import { useLocation, useNavigate } from "react-router-dom";
import { useData } from "../../../redux/features/data/DataContext";
import { disabledStyle } from "./staticStyling";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../common/StyledComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "../../common/loader";
import LockIcon from "@mui/icons-material/Lock";

const CampaignWizardStep3 = ({
  goToNextStep,
  audienceId,
  campaignId,
  setGoalState,
  campaignGoal,
  campaignAudience,
  editModeActive,
  sendEditMode,
  allowedPlatforms,
  influencerAllowed,
  publicationAllowed,
  isCampStatusDraft
}) => {
  const classes = disabledStyle();
  const [state, setState] = React.useState({
    count: "",
    publications: "",
    frequency: "",
    kpi: [],
    kpiValue: [],
  });
  const [preventEdit, setPreventEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isGoalSaveAsDraft, setIsGoalSaveAsDraft] = React.useState(false);
  const frequencyList = [
    "Daily",
    "Weekly",
    "Fortnightly",
    "Monthly",
    "Quarterly",
    "Fixed",
  ];
  const kpiList = [
    "Audience/Reach/Impressions",
    "Engagement",
    "Clicks",
    "Conversions/Registrations",
    "Conversions/Sales",
    "Conversions/App Downloads",
    "Conversions/App Signups",
  ];

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { setGlobalData } = useData();
  const [editMode, setEditMode] = React.useState(editModeActive);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const eventType = params.get("eventType");
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    const invalidChars = ["-", "+", ".", "e"];
    const { key, target } = event;

    if (invalidChars.includes(key) || target.value === "0") {
      event.preventDefault();
    }
  };

  const [kpiStep, setKpiStep] = React.useState([
    <Grid container item spacing={1}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Select KPI</InputLabel>
          <Select
            required={true}
            variant="outlined"
            label="Select KPI"
            placeholder="Select KPI"
            id="template"
            value={state.kpi[0]}
            onChange={(e) => handleKPI(e.target.value, 0)}
          >
            {kpiList
              ?.filter((kpi) => !state.kpi.includes(kpi))
              ?.map((kpi) => (
                <MenuItem key={kpi} value={kpi}>
                  {kpi}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            placeholder="Target (value)"
            value={state.kpiValue[0]}
            sx={{ borderColor: "#474747", color: "#474747" }}
            onChange={(e) => handleKPIValue(e.target.value, 0)}
            type="number"
            inputProps={{
              style: { textAlign: "center", fontSize: "16px" },
              min: 0,
              onKeyPress: handleKeyPress,
            }}
          />
        </FormControl>
      </Grid>
    </Grid>,
  ]);

  const handleIncrement = () => {
    setState({ ...state, count: Number(state.count) + 1 });
  };

  React.useEffect(() => {
    setLoading(true);
    if (campaignGoal) {
      setState(campaignGoal);
    }
    if (editModeActive) {
      dispatch(
        notify({
          message: "Please wait... We are loading your campaign's audience",
          type: "info",
        })
      );
      setEditMode(true);
      getCampaignGoalDetails();

      if (user.agencyId !== 0) {
        setPreventEdit(true);
      }
    } else {
      setLoading(false);
    }
  }, []);

  const getCampaignGoalDetails = async () => {
    const campaignResponse = await getDetailedCampaignGoal(
      campaignId,
      user,
      dispatch
    );
    if (campaignResponse?.response == null) {
      setEditMode(false);
      setLoading(false);
    }
    if (campaignResponse.response != null) {
      const { iidCount, postCount, postFrequency, kpiField, kpiValue } =
        campaignResponse?.response[0];

      setState({
        count: iidCount,
        publications: postCount,
        frequency: postFrequency,
        kpi: kpiField.filter((value) => value !== ""),
        kpiValue: kpiValue.filter((value) => value !== ""),
      });
      if (kpiField?.filter((value) => value !== "").length > 0) {
        const initialKpiStep = kpiField
          ?.filter((value) => value !== "")
          ?.map((kpi, index) => (
            <Grid
              container
              item
              spacing={1}
              sx={{ paddingTop: "5px" }}
              key={index}
            >
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Select KPI</InputLabel>
                  <Select
                    disabled={user.agencyId !== 0 && editMode}
                    required={true}
                    variant="outlined"
                    label="Select KPI"
                    placeholder="Select KPI"
                    id={`template-${index}`}
                    value={kpi}
                    onChange={(e) => handleKPI(e.target.value, index)}
                  >
                    {kpiList
                      ?.filter((kpi) => !state.kpi.includes(kpi))
                      ?.map((kpiItem) => (
                        <MenuItem key={kpiItem} value={kpiItem}>
                          {kpiItem}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={user.agencyId !== 0 && editMode}
                    variant="outlined"
                    placeholder="Target (value)"
                    type="number"
                    value={kpiValue[index]}
                    sx={{ borderColor: "#474747", color: "#474747" }}
                    inputProps={{
                      style: { textAlign: "center", fontSize: "16px" },
                    }}
                    onChange={(e) => handleKPIValue(e.target.value, index)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ));
        setKpiStep(initialKpiStep);
      }
    }

    setLoading(false);
  };

  const handleDecrement = () => {
    if (state.count > 0) {
      setState({ ...state, count: Number(state.count) - 1 });
    }
  };

  const handleKPI = (kpi, index) => {
    setState((prevState) => {
      const newKPI = [...prevState.kpi];
      newKPI[index] = kpi;
      return { ...prevState, kpi: newKPI };
    });
  };

  const handleKPIValue = (value, index) => {
    setState((prevState) => {
      const newKPIValue = [...prevState.kpiValue];
      newKPIValue[index] = value;
      return { ...prevState, kpiValue: newKPIValue };
    });
  };

  const incrementRows = (kpi, kpiValue) => {
    setKpiStep((prevData) => [
      ...prevData,
      <Grid
        container
        item
        spacing={1}
        sx={{ paddingTop: "5px" }}
        key={prevData?.length}
      >
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Select KPI</InputLabel>
            <Select
              required={true}
              variant="outlined"
              label="Select KPI"
              placeholder="Select KPI"
              id="template"
              value={kpi[prevData?.length]}
              onChange={(e) => handleKPI(e.target.value, prevData?.length)}
            >
              {kpiList
                ?.filter((kpi) => !state.kpi.includes(kpi))
                ?.map((kpi) => (
                  <MenuItem key={kpi} value={kpi}>
                    {kpi}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              placeholder="Target (value)"
              type="number"
              value={kpiValue[prevData?.length]}
              sx={{ borderColor: "#474747", color: "#474747" }}
              onChange={(e) => handleKPIValue(e.target.value, prevData?.length)}
              inputProps={{
                style: { textAlign: "center", fontSize: "16px" },
                min: 0,
                onKeyPress: handleKeyPress,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>,
    ]);
  };

  const storeGoal = async (saveType) => {
    dispatch(
      notify({
        message: "Please wait... We are processing your campaign's goal",
        type: "info",
      })
    );
    const campaignGoal = {
      audienceId,
      cid: campaignId,
      iidCount: state.count.toString(),
      postCount: state.publications,
      postFrequency: state.frequency,
      kpiField: state.kpi,
      kpiValue: state.kpiValue,
      status: saveType === "Save" ? 1 : 2,
    };
    if (editMode || isGoalSaveAsDraft) {
      const response = await editCampaignGoal(campaignGoal, user, dispatch);
      // if (saveType == "Save") {
      //   navigate(`/app/campaigns`);
      // }
      navigate(`/app/campaigns`);

      dispatch(
        notify({
          message: "Campaign's Goal edited successfully",
          type: "success",
        })
      );
    } else {
      const response = await createCampaignGoal(campaignGoal, user, dispatch);
      if (response?.response.status === "success") {
        if (saveType === "Save") {
          navigate(`/app/campaigns/overview?id=${campaignId}&universal=false`, {
            replace: true,
          });
        }
        else {
          navigate(`/app/campaigns`);
        }
        setIsGoalSaveAsDraft(true);
        dispatch(
          notify({
            message: "Campaign's Goal edited successfully",
            type: "success",
          })
        );
      } else {
        const response = await createCampaignGoal(campaignGoal, user, dispatch);
        if (response?.response.status === "success") {
          if (saveType === "Save") {
            navigate(`/app/campaigns/overview?id=${campaignId}&universal=false`, {
              replace: true,
            });
          }
          setIsGoalSaveAsDraft(true);
          dispatch(
            notify({
              message: "Campaign's Goal created successfully",
              type: "success",
            })
          );
        }
      }
    };

    const goToPreviousStep = () => {
      goToNextStep(1, isCampStatusDraft);
      setGoalState(state);
      sendEditMode(true);
    };

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <Box m={5}>
            <h2 style={{ fontSize: "32px", fontWeight: 500, lineHeight: "28px" }}>
              Goals
            </h2>
            <Grid container item spacing={4} xs={12} style={{ paddingTop: 20 }}>
              <Grid item md={10}>
                <p style={{ fontSize: "18px", fontWeight: 500 }}>
                  <b>Total Number of Influencers</b>
                </p>
                <Grid item sx={{ width: "49.5%" }}>
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      onClick={handleDecrement}
                      style={{
                        height: "56px",
                        borderColor: "#474747",
                        color: "#474747",
                        fontSize: "30px",
                      }}
                      disabled={state.count <= 1 || (preventEdit && editMode)}
                    >
                      -
                    </Button>
                    <TextField
                      variant="outlined"
                      placeholder="Number of Influencers"
                      label="Number of Influencers"
                      value={state.count}
                      disabled={preventEdit && editMode}
                      sx={{
                        borderColor: "#474747",
                        color: "#474747",
                        width: "100%",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      onChange={(e) =>
                        setState({ ...state, count: e.target.value })
                      }
                      type="number"
                      inputProps={{
                        style: { textAlign: "center", fontSize: "16px" },
                        onKeyPress: handleKeyPress,
                        min: 0,
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={handleIncrement}
                      disabled={preventEdit && editMode}
                      style={{
                        height: "56px",
                        borderColor: "#474747",
                        color: "#474747",
                        fontSize: "30px",
                      }}
                    >
                      +
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={10}>
                <p style={{ fontSize: "18px", fontWeight: 500 }}>
                  <b>
                    Publications per Influencer{" "}
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                    >
                      (Optional)
                    </p>
                  </b>
                </p>
                <Grid container item spacing={1}>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        // disabled={preventEdit && editMode}
                        placeholder="Publication Count"
                        label="Publication Count"
                        value={state.publications}
                        sx={{ borderColor: "#474747", color: "#474747" }}
                        type="number"
                        inputProps={{
                          style: { textAlign: "center", fontSize: "16px" },
                          onKeyPress: handleKeyPress,
                          min: 0,
                        }}
                      />
                      <Button
                        variant="outlined"
                        onClick={handleIncrement}
                        disabled={preventEdit && editMode}
                        style={{
                          height: "56px",
                          borderColor: "#474747",
                          color: "#474747",
                          fontSize: "30px",
                        }}
                      >
                        +
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={10}>
                  <p style={{ fontSize: "18px", fontWeight: 500 }}>
                    <b>
                      Publications per Influencer{" "}
                      <p
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          fontWeight: "normal",
                        }}
                      >
                        (Optional)
                      </p>
                    </b>
                  </p>
                  <Grid container item spacing={1}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          // disabled={preventEdit && editMode}
                          placeholder="Publication Count"
                          label="Publication Count"
                          value={state.publications}
                          sx={{ borderColor: "#474747", color: "#474747" }}
                          type="number"
                          inputProps={{
                            style: { textAlign: "center", fontSize: "16px" },
                            min: 0,
                            onKeyPress: handleKeyPress,
                          }}
                          onChange={(e) =>
                            setState({ ...state, publications: e.target.value })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Frequency</InputLabel>
                        <Select
                          disabled={preventEdit && editMode}
                          required={true}
                          variant="outlined"
                          label="Frequency"
                          placeholder="Frequency"
                          id="template"
                          value={state.frequency}
                          onChange={(e) =>
                            setState({ ...state, frequency: e.target.value })
                          }
                        >
                          {frequencyList?.map((frequency) => (
                            <MenuItem key={frequency} value={frequency}>
                              {frequency}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={10}>
                  <p style={{ fontSize: "18px", fontWeight: 500 }}>
                    <b>Expected Results</b>
                  </p>
                  {kpiStep}
                  <Button
                    style={{
                      color: !preventEdit && !editMode ? "#000000" : "",
                      paddingTop: "10px",
                    }}
                    disabled={preventEdit && editMode}
                    onClick={(e) => incrementRows(state.kpi, state.kpiValue)}
                  >
                    <Add /> Add another
                  </Button>
                  <Grid
                    container
                    style={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      alignItems: "center",
                    }}
                  >
                    <LockIcon
                      fontSize="small"
                      style={{ marginBottom: "2.5px", fontSize: "14px" }}
                    />
                    {editMode
                      ? "These fields can only be edited by Campaign Manager or Admin"
                      : "These fields will only be editable by Campaign Manager or Admin later."}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: "84%" }}
                >
                  {/* Previous and Save as Draft */}
                  <Grid item>
                    <Box sx={{ mt: 4, ml: 3.5, display: "flex", gap: "10px", alignItems: "center" }}>
                      {/* Previous Button */}
                      <Button
                        variant="outlined"
                        style={{
                          width: "180px",
                          height: "38px",
                          color: "#474747",
                          borderColor: "#474747",
                          borderRadius: "11px",
                        }}
                        onClick={(e) => goToPreviousStep()}
                      >
                        <ArrowBackIcon style={{ marginRight: "8px", fontSize: "18px" }} />
                        <b>Previous</b>
                      </Button>

                      {/* Save as Draft Button */}
                      <Button
                        variant="outlined"
                        style={{
                          width: "180px",
                          height: "38px",
                          borderColor: "#474747",
                          borderRadius: "11px",
                          color: "#474747", // Text color when enabled
                          borderColor: "#474747", // Border color when enabled
                        }}
                        onClick={(e) => storeGoal("Draft")}
                      >
                        <b>Save as Draft</b>
                      </Button>
                    </Box>
                  </Grid>

                  {/* Save and Proceed */}
                  <Grid item>
                    <Box sx={{ mt: 4 }}>
                      <StyledStaticButtonGroup
                        color="primary"
                        size="small"
                        exclusive
                        aria-label="Campaign"
                      >
                        <StyledStaticButton
                          value="active"
                          onClick={(e) => storeGoal("Save")}
                          disabled={
                            state.count === "" ||
                            state.count <= "0" ||
                            state.kpi?.length === 0 ||
                            state.kpiValue?.length === 0
                          }
                          classes={{ disabled: classes.disabledButton }}
                          style={{
                            width: "180px",
                            height: "38px",
                            color: "#474747",
                            borderColor: "#474747",
                            borderRadius: "11px",
                          }}
                        >
                          <b>Save and Proceed</b>
                          <ArrowForwardIcon style={{ marginLeft: "8px", fontSize: "18px" }} />
                        </StyledStaticButton>
                      </StyledStaticButtonGroup>
                    </Box>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Box>
        )}
      </>
    );
  };
};

  export default CampaignWizardStep3;
