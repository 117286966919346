import { CheckCircle, CloudUpload, Info } from "@mui/icons-material";
import {
    Box,
    Button,
    Stack,
    Chip,
    Typography,
    Backdrop,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import React, { useState } from "react";
import { Download, Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../../utility/oryxUtils/utils";
import { useEffect } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import {
    checkListCountries,
    createPptReport,
    downloadPptReport,
    getAllReportFields,
    getAllReportHistory,

} from "../../../../services/pptReport";
import configs from "../../../../config/main.json";
import { notify } from "../../../../redux/features/system/systemAlert";
import { StyledStaticButtonGroup, StyledStaticButton } from "../../../common/StyledComponents";
import Loader from "../../../common/loader";

const slides = `${configs.BASE_IMAGE_URL}googleSlides.png`

const reportThemeOptions = [
    {
        primary: "#e64b3c",
        primaryDark: "#c0382a",
        secondary: "#7f7f7f",
        secondaryDark: "#606060",
    },
    {
        primary: "#f77d0a",
        primaryDark: "#ba5d06",
        secondary: "#7f7f7f",
        secondaryDark: "#606060",
    },
    {
        primary: "#4057ed",
        primaryDark: "#202f8c",
        secondary: "#7f7f7f",
        secondaryDark: "#606060",
    },
];

const CampaignReport = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [reportTitle, setReportTitle] = useState("Title");
    const [reportBrand, setReportBrand] = useState("Brand");
    const [reportType, setReportType] = useState("");
    const [dataRefreshedOn, setDataRefreshedOn] = useState("");
    const [reportTheme, setReportTheme] = useState(reportThemeOptions[0]);
    const [reportLogo, setReportLogo] = useState(null);
    const [reportHistory, setReportHistory] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [statusES, setStatusES] = useState();
    const [progress, setProgress] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [status, setStatus] = useState("Started");
    const [markNew, setMarkNew] = useState(false);
    const [notification, setNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let campId = params.get("id");
    const [pptReportId, setPptReportId] = React.useState(campId);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingNotfication, setLoadingNotfication] = useState(false);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const loadingBoxStyle = {
        position: "fixed",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, 0)",
        backgroundColor: "#2196f3",
        color: "#fff",
        padding: "15px 20px",
        borderRadius: "4px",
        textAlign: "center",
        fontSize: "17px",
        zIndex: 10000,
    };


    useEffect(() => {
        if (pptReportId > 0) {
            openPPTGenerationModal(pptReportId);
        }
    }, [pptReportId]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= currentProgress) {
                    clearInterval(interval);
                }
                if (prev === 100) {
                    setMarkNew(true);
                    getReportHistory(selectedId);
                    setGenerating(false);
                    statusES.close();
                    setNotification(true);
                    setSeverity("success");
                    setMessage("Presentation Created!");
                }
                return prev < currentProgress ? prev + 1 : prev;
            });
        }, 100);
    }, [currentProgress]);

    const openPPTGenerationModal = async (id) => {
        const res = await checkListCountries(id, user, dispatch);
        if (res.response) {
            await getReportFields(id);
            await getReportHistory(id);
            setSelectedId(id);
            setMarkNew(false);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getReportFields = async (id) => {
        const data = await getAllReportFields(id, user, dispatch);
        setReportTitle(data.response.title);
        setReportBrand(data.response.account);
        setDataRefreshedOn(
            utils.convertDateMoment(data.lastRefreshedTime, "onlydate")
        );
        if (data.response.status === 4) {
            setReportType("End of Campaign Report");
        } else if (data.response.status === 1) {
            const start = moment(data.response.startDate.split(" ")[0], "YYYY-MM-DD");
            const end = moment();
            const diff = Math.floor(end.diff(start, "days") / 7);
            setReportType(`Week ${diff} Report`);
        }
    };

    const closeModal = () => {
        getReportFields(pptReportId)
        setReportType("");
        setReportTheme(reportThemeOptions[0]);
        setReportLogo(null);
        setGenerating(false);
        setOpenModal(false);
    };

    const getReportHistory = async (id) => {
        const reportHistory = await getAllReportHistory(id, user, dispatch);
        setReportHistory(reportHistory.response || []);
    };

    const handleUpload = (event) => {
        if (event.target.files?.length !== 0) {
            const file = event.target.files[0];
            setReportLogo(file || "");
        } else {
            setReportLogo(null);
        }
    };

    const handleGenerateReport = async (event) => {
        event.preventDefault();

        setGenerating(true);
        setProgress(0);
        setCurrentProgress(0);
        setStatus("Started");

        const email = user.email;

        const formData = new FormData();
        formData.append("logo", reportLogo);
        formData.append("cid", selectedId);
        formData.append("account", reportBrand);
        formData.append("title", reportTitle);
        formData.append("reportType", reportType);
        formData.append("colors", JSON.stringify(reportTheme));
        formData.append("owner", email);

        const { UNAUTH_API_HOST } = configs;

        const res = await createPptReport(formData);
        const reportId = res.reportId;
        if (window.EventSource) {
            const source = new EventSource(
                UNAUTH_API_HOST + "/report/status/" + reportId
            );
            source.addEventListener(
                "open",
                (e) => {
                    source.onmessage = (event) => {
                        let data = JSON.parse(event.data);
                        setStatus(data.status);
                        setCurrentProgress(data.progress);
                        if (data.status === "error") {
                            setNotification(true);
                            setSeverity("error");
                            setMessage("An error occurred while creating the presentation!");
                            setGenerating(false);
                            setMarkNew(false);
                            getReportHistory(selectedId);
                        }
                    };
                },
                false
            );
            setStatusES(source);
        }
    };

    const downloadPpt = async (title, id) => {
        setLoadingNotfication(true);
        const data = await downloadPptReport(id, user, dispatch);
        setLoadingNotfication(false)
        if (data) {
            dispatch(
                notify({
                    message: "PPT downloaded successfully",
                    type: "success",
                })
            );
            FileSaver(data.response, title + ".pptx");
        }
    };

    return (
        <>
            {isLoading === true ? (
                <><Loader /> </>
            ) : (
                <>
                    {loadingNotfication && (
                        <div style={loadingBoxStyle}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <InfoOutlined style={{ marginRight: "15px", color: "#fff" }} />
                                    Please wait... We are downloading your ppt
                                </div>
                                <Close
                                    onClick={() => setLoadingNotfication(false)}
                                    style={{ marginLeft: "15px", color: "#fff", cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    )}
                    <Stack direction="row" justifyContent="space-between">
                        <Box></Box>
                        <Box my={3}>
                            <StyledStaticButtonGroup
                                color="primary"
                                exclusive
                                size="small"
                                aria-label="Campaign"
                            >
                                <StyledStaticButton
                                    value="active"
                                    onClick={() => setOpenModal(true)}
                                >
                                    <img
                                        src={slides}
                                        alt="slides"
                                        style={{
                                            color: "white",
                                            height: "20px",
                                            width: "20px",
                                            marginRight: "10px"
                                        }}
                                    /><b>Generate Report</b>
                                </StyledStaticButton>
                            </StyledStaticButtonGroup>
                        </Box>
                    </Stack>

                    <Box m={3} sx={{ minHeight: "50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {reportHistory.length === 0 ? (
                            <Box textAlign="center" my={4}>
                                <Typography variant="h4" color="textSecondary">
                                    No reports available yet.
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Generate a report to get insights on your campaign’s performance.
                                </Typography>
                            </Box>
                        ) : (
                            <div style={{ maxHeight: "75vh", overflowY: "auto", width: '100%' }}>
                                {reportHistory.map((report, index) => (
                                    <Grid container>
                                        <Grid item sm={11}>
                                            <div style={{ padding: "8px" }}>
                                                <Box
                                                    sx={{
                                                        "&:hover": {
                                                            bgcolor: "#eeeeee",
                                                        },
                                                        padding: "10px 20px",
                                                        cursor: "pointer",
                                                        height: "68px",
                                                    }}
                                                    onClick={() => window.open(report.link, "_blank", "noreferrer")}
                                                >
                                                    <div>
                                                        <span style={{ fontSize: "18px" }}>{report.title}</span>
                                                        {index === 0 && (
                                                            <Chip
                                                                sx={{
                                                                    ml: "6px",
                                                                    transform: "scale(0.8) translateY(-4px)",
                                                                    bgcolor: "#5682f0",
                                                                    borderRadius: "12px",
                                                                    fontSize: "16px",
                                                                }}
                                                                color="primary"
                                                                label="New"
                                                            />
                                                        )}
                                                    </div>
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <div style={{ fontSize: "14px", color: "gray" }}>
                                                                {"Built on " + utils.convertDate(report.date, "datetime") + " GMT "}
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ fontSize: "14px", color: "gray" }}>
                                                                {"Using " + utils.convertDate(report.dataUpdateTime, "onlydate") + " data"}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </div>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <div style={{ padding: "8px" }}>
                                                <Box
                                                    sx={
                                                        report.link === ""
                                                            ? {
                                                                bgcolor: "#f9f9f9",
                                                                height: "68px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }
                                                            : {
                                                                bgcolor: "#f9f9f9",
                                                                "&:hover": {
                                                                    bgcolor: "#eeeeee",
                                                                },
                                                                cursor: "pointer",
                                                                height: "68px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }
                                                    }
                                                    onClick={() => {
                                                        if (report.link !== "") {
                                                            const sepLink = report.link.split("/");
                                                            const id = sepLink[sepLink.length - 1];
                                                            downloadPpt(report.title, id);
                                                        }
                                                    }}
                                                >
                                                    <Download fontSize="medium" sx={report.link === "" && { color: "lightgray" }} />
                                                </Box>
                                            </div>
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        )}
                    </Box>


                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal}
                        // closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openModal}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 1000,
                                    height: "auto",
                                    bgcolor: "#FAFAFB",
                                    borderRadius: "15px",
                                    boxShadow: 24,
                                    p: 3,
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginLeft: "24px",
                                    }}
                                >
                                    <div id="modal-add_user-heading" className="styles_modal_heading">
                                        <h2
                                            id="modal-add_user-heading-text"
                                            style={{ fontSize: "2em", fontWeight: "bold", margin: "0" }}
                                        >
                                            Generate Report
                                        </h2>
                                        <br />
                                    </div>
                                    <IconButton >
                                        <Close onClick={closeModal} />
                                    </IconButton>
                                </div>
                                <Box m={3}>
                                    <form onSubmit={handleGenerateReport}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        onChange={(e) => setReportTitle(e.target.value)}
                                                        variant="outlined"
                                                        label="Report Title"
                                                        required={true}
                                                        value={reportTitle}
                                                        disabled={generating}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        onChange={(e) => setReportBrand(e.target.value)}
                                                        variant="outlined"
                                                        label="Customer/Account Name"
                                                        required={true}
                                                        value={reportBrand}
                                                        disabled={generating}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        onChange={(e) => setReportType(e.target.value)}
                                                        variant="outlined"
                                                        label="Report Type"
                                                        required={true}
                                                        value={reportType}
                                                        disabled={generating}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Report Theme</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        label="Report Theme"
                                                        required={true}
                                                        value={reportTheme}
                                                        renderValue={(theme) => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        height: "20px",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.primary,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.primaryDark,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.secondary,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.secondaryDark,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                        onChange={(e) => setReportTheme(e.target.value)}
                                                        disabled={generating}
                                                    >
                                                        {reportThemeOptions?.map((theme) => (
                                                            <MenuItem value={theme} key={theme}>
                                                                <div
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.primary,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.primaryDark,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.secondary,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: theme.secondaryDark,
                                                                            height: "20px",
                                                                            display: "inline-block",
                                                                            width: "25%",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12}>
                                                <div
                                                    style={{
                                                        backgroundColor: "#f9f9f9",
                                                        padding: "5px 10px",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid item md={5}>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Tooltip
                                                                    title={reportLogo === null ? "" : reportLogo.name}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        startIcon={<CloudUpload />}
                                                                        endIcon={reportLogo && <CheckCircle />}
                                                                        style={{ margin: "10px", width: "200px" }}
                                                                        component="label"
                                                                        disabled={generating}
                                                                    >
                                                                        Upload Logo
                                                                        <input
                                                                            style={{
                                                                                position: "absolute",
                                                                                width: "1px",
                                                                                height: "1px",
                                                                                overflow: "hidden",
                                                                                opacity: 0,
                                                                            }}
                                                                            accept="image/png, image/jpeg"
                                                                            type="file"
                                                                            onChange={(event) => handleUpload(event)}
                                                                            required
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                                <div
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        color: "gray",
                                                                        fontStyle: "italic",
                                                                    }}
                                                                >
                                                                    JPEG, PNG only. Under 2MB File.
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={7}>
                                                            <div
                                                                style={{
                                                                    fontStyle: "italic",
                                                                    marginTop: "20px",
                                                                    color: "gray",
                                                                }}
                                                            >
                                                                <Info fontSize="small" />
                                                                &nbsp;&nbsp;Should be 500x160 pixels in size
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div style={{ textAlign: "center" }}>
                                            {!generating && (
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    sx={{ m: "15px 15px 0px 15px" }}
                                                    disabled={!reportLogo}
                                                    startIcon={
                                                        <img
                                                            src={slides}
                                                            alt="slides"
                                                            style={{
                                                                color: "white",
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        />
                                                    }
                                                >
                                                    Generate Report
                                                </Button>
                                            )}
                                            {generating && (
                                                <>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={progress}
                                                        sx={{ height: "10px" }}
                                                        style={{ marginTop: "20px" }}
                                                    />
                                                    <div style={{ color: "gray", margin: "5px 5px 20px 5px" }}>
                                                        <CircularProgress size={16} />
                                                        &nbsp;&nbsp;&nbsp;
                                                        {status}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {!generating && (
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    fontSize: "14px",
                                                    color: "gray",
                                                }}
                                            >
                                                Data up-to-date till {dataRefreshedOn}{" "}
                                                <Tooltip title="Report generated will be built on data snapshot time mentioned and not the time of report generation">
                                                    <Info fontSize="small" />
                                                </Tooltip>
                                            </div>
                                        )}
                                    </form>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
        </>
    );
};

export default CampaignReport;
