import { Box, Grid } from "@mui/material";
import AnalyticsFilter from "./AnalyticsFilter";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import React from "react";
import { useLocation } from "react-router-dom";
import { 
  getEnagementGraph, 
  getListEngagementGraph, 
  getPublicationGraph, 
  getEngReachByPostType, 
  getReachListwise 
} from "../../../../services/campaignAnalytics";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations"; // Now has defensive checks
import moment from "moment";
import Loader from "../../../common/loader";
import CampaignAnalyticsGraphs from "./CampaignAnalyticsGraphs";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";

const CampaignAnalyticsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);

  const [totalEngagement, setTotalEngagement] = React.useState([]); //total engagement of all influencer posts
  const [totalEngagementTime, setTotalEngagementTime] = React.useState([]);

  const [publicationEngagement, setPublicationEngagement] = React.useState([]); //engagment by publication date
  const [publicationEngagementTime, setPublicationEngagementTime] = React.useState([]);

  const [listEngagement, setListEngagement] = React.useState([]); //engagement by top 3 lists
  const [listEngagementTime, setListEngagementTime] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [platforms, setPlatforms] = React.useState([]);
  const [engagementSummary, setEngagementSummary] = React.useState({}); //engagement summary pf all platforms
  const [reachSplit, setReachSplit] = React.useState([]); //reach split over social network
  const [reachColorArray, setReachColorArray] = React.useState([]); //engagement split over social network
  const [engagementSplit, setEngagementSplit] = React.useState([]);
  const [engagementColorArray, setEngagementColorArray] = React.useState([]);

  // Additional charts:
  const [engByListwiseTime, setEngByListwiseTime] = React.useState([]);
  const [engByListwiseData, setEngByListwiseData] = React.useState([]);
  const [engByListwiseLists, setEngByListwiseLists] = React.useState([]);
  const [reachByListwiseTime, setReachByListwiseTime] = React.useState([]);
  const [reachByListwiseData, setReachByListwiseData] = React.useState([]);
  const [reachByListwiseLists, setReachByListwiseLists] = React.useState([]);
  const [engByPostTypeData, setEngByPostTypeData] = React.useState({ reach: null, engagement: null });

  const [startDate, setStartDate] = React.useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")); 
  const [endDate, setEndDate] = React.useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(Object.keys(snapshotHeaderIcons)); 
  const [selectedBreakdown, setSelectedBreakdown] = React.useState("day"); 
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = React.useState([]);
  const universal = params.get("universal");
  React.useEffect(() => {
    getAnalyticsData(params.get("id"), startDate, endDate, selectedBreakdown, selectedPlatforms, [-1], [-1], [-1]);
  }, [params.get("id")]);

  const parseCustomDate = (str) => {
    if (typeof str !== 'string') return str; 
    const parts = str.split(" "); 
    if (parts.length !== 3) return str;
    const day = parts[0].padStart(2, '0');
    const monthStr = parts[1];
    const yearShort = parts[2];

    const monthMap = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    };

    const month = monthMap[monthStr];
    if (!month) return str;
    const year = "20" + yearShort; 
    return `${year}-${month}-${day}`; 
  };

  const getAnalyticsData = async (cid, from, to, filterBy, plid, postTypes, lids, iids) => {
    setLoading(true);
    const engagement = await getEnagementGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch);
    const publication = await getPublicationGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch);
    const listwiseEngagement = await getListEngagementGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch);

    const engByListwiseResponse = await getListEngagementGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch);
    const reachByListwiseResponse = await getReachListwise(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch);
    const engReachByPostTypeResponse = await getEngReachByPostType(cid, from, to, filterBy,-1, postTypes, lids, iids, user, dispatch);

    setLoading(false);
    setDataFound(false);

    // Engagement data
    if (engagement.status === true && engagement.response) {
      if (engagement.response.TotalEngagement !== null) {
        createEngagementSummaryArray(engagement.response.TotalEngagement);
      } else {
        createEngagementSummaryArray({});
      }
      //platform wise engagement graph
      if (engagement.response.Summary !== null) {
        const p = Object.keys(engagement.response.Summary);
        setPlatforms(p);
        //engagement summary for likes, comments and shares
        setEngagementSummary(engagement.response.Summary);
      } else {
        setPlatforms([]);
        setEngagementSummary({});
      }
      if (engagement.response.ReachSplit !== null) {
        //doughnut graph for reach split
        createReachSplitArray(engagement.response.ReachSplit);
      } else {
        createReachSplitArray({});
      }
      if (engagement.response.EngagementSplit !== null) {
        //doughnut graph for engagement split
        createEngagementSplitArray(engagement.response.EngagementSplit);
      } else {
        createEngagementSplitArray({});
      }

      setDataFound(true);
    }

    // Publication data
    if (publication.status === true && publication.response && publication.response.PublicationEngagement !== null) {
      //publication summary graph
      createPublicationEngagementSummaryArray(publication.response.PublicationEngagement);
      setDataFound(true);
    } else {
      createPublicationEngagementSummaryArray({});
    }
    if (listwiseEngagement.status === true && listwiseEngagement.response && Object.keys(listwiseEngagement.response.ListEngagement || {}).length > 0) {
       //listwise summary graph
      createListWisengagementSummaryArray(listwiseEngagement.response.ListEngagement);
      setDataFound(true);
    } else {
      createListWisengagementSummaryArray({});
    }
    // Eng By Listwise
    if (engByListwiseResponse && engByListwiseResponse.response && engByListwiseResponse.response.ListEngagement && Object.keys(engByListwiseResponse.response.ListEngagement).length > 0) {
      const { finalDateArray, seriesArray, keysArray } = processEngByListwiseData(engByListwiseResponse.response.ListEngagement);
      setEngByListwiseTime(finalDateArray);
      setEngByListwiseData(seriesArray);
      setEngByListwiseLists(keysArray);
      setDataFound(true);
    } else {
      setEngByListwiseTime([]);
      setEngByListwiseData([]);
      setEngByListwiseLists([]);
    }

    // Reach By Listwise
    if (reachByListwiseResponse && reachByListwiseResponse.response && reachByListwiseResponse.response.ListReach && reachByListwiseResponse.response.ListReach.length > 0) {
      const { dateArray, valueArray } = processReachByListwiseData(reachByListwiseResponse.response.ListReach);
      setReachByListwiseTime(dateArray);
      setReachByListwiseLists(["List Reach"]);
      setReachByListwiseData([{ name: "List Reach", type: "bar", data: valueArray }]);
      setDataFound(true);
    } else {
      setReachByListwiseTime([]);
      setReachByListwiseData([]);
      setReachByListwiseLists([]);
    }

    // Eng/Reach By Post Type
    if (engReachByPostTypeResponse && engReachByPostTypeResponse.response) {
      const engByPostTypeData = processEngReachByPostTypeData(engReachByPostTypeResponse.response);
      setEngByPostTypeData(engByPostTypeData);
      if ((engByPostTypeData.reach && engByPostTypeData.reach.labels.length > 0) || 
          (engByPostTypeData.engagement && engByPostTypeData.engagement.labels.length > 0)) {
        setDataFound(true);
      }
    }
  };

  const processEngByListwiseData = (summarySplit) => {
    const keysArray = Object.keys(summarySplit);
    const { finalDateArray, seriesArray } = createPlatformSplitArray(keysArray, summarySplit, false);
    return { finalDateArray, seriesArray, keysArray };
  };

  const processReachByListwiseData = (listReach) => {
    let dateArray = [];
    let valueArray = [];
    listReach.forEach((item) => {
      dateArray.push(item.Key);
      valueArray.push(item.Value);
    });
    return { dateArray, valueArray };
  };

  const processEngReachByPostTypeData = (response) => {
    const data = { reach: { labels: [], datasets: { post: [], story: [], reel: [] } }, engagement: { labels: [], datasets: { post: [], story: [], reel: [] } } };

    ["reach", "engagement"].forEach((type) => {
      if (response[type]) {
        const aggregatedData = { labels: [], datasets: { post: [], story: [], reel: [] } };
        const allPlatforms = Object.keys(response[type]);
        let tempData = {};
        allPlatforms.forEach((platform) => {
          if (platform === "total") return;
          const dataByDay = response[type][platform]?.day || {};
          Object.keys(dataByDay).forEach(date => {
            if (!tempData[date]) {
              tempData[date] = { post: 0, story: 0, reel: 0 };
            }
            tempData[date].post += dataByDay[date].post;
            tempData[date].story += dataByDay[date].story;
            tempData[date].reel += dataByDay[date].reel;
          });
        });
        aggregatedData.labels = Object.keys(tempData).sort((a, b) => new Date(a) - new Date(b));
        aggregatedData.datasets.post = aggregatedData.labels.map(date => tempData[date].post);
        aggregatedData.datasets.story = aggregatedData.labels.map(date => tempData[date].story);
        aggregatedData.datasets.reel = aggregatedData.labels.map(date => tempData[date].reel);
        data[type] = aggregatedData;
      }
    });

    return data;
  };

  const createEngagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const p = Object.keys(summarySplit);
      let event = createPlatformSplitArray(p, summarySplit, true);
      setTotalEngagementTime(event.finalDateArray);

      setTotalEngagement(event.seriesArray);
    }
  };

  const createPublicationEngagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const p = Object.keys(summarySplit);
      let event = createPlatformSplitArray(p, summarySplit, true);
      setPublicationEngagementTime(event.finalDateArray);
      setPublicationEngagement(event.seriesArray);
    }
  };

  const createListWisengagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const groups = Object.keys(summarySplit);
      let event = createPlatformSplitArray(groups, summarySplit, false);
      setListEngagementTime(event.finalDateArray);
      setLists(groups);
      setListEngagement(event.seriesArray);
    }
  };

  const createPlatformSplitArray = (summaryObject, summarySplit, color) => {
    let dateArray = [];
    let seriesArray = [];
    summaryObject.forEach((platform) => {
      dateArray.push(...Object.keys(summarySplit[platform]));
    });

    summaryObject.forEach((platform) => {
      const platformName = typeof platform === 'string' ? platform : String(platform);
      const object = {
        name: platformName,
        type: "bar",
        showBackground: false,
        color: color ? [textColor[platformName]?.color] : [],
        data: [],
      };
      dateArray.forEach((date) => {
        const val = summarySplit[platform][date] || 0;
        object.data.push(val);
      });
      seriesArray.push(object);
    });

    const finalDateArray = [...new Set(dateArray)].sort((a, b) => {
      // Convert custom date format before sorting if needed
      const isCustomFormatA = typeof a === 'string' && /^\d{1,2}\s\w{3}\s\d{2}$/.test(a);
      const isCustomFormatB = typeof b === 'string' && /^\d{1,2}\s\w{3}\s\d{2}$/.test(b);
      const dateA = isCustomFormatA ? new Date(parseCustomDate(a)) : new Date(a);
      const dateB = isCustomFormatB ? new Date(parseCustomDate(b)) : new Date(b);
      return dateA - dateB;
    });

    return { finalDateArray, seriesArray };
  };

  const createReachSplitArray = (reachSplit) => {
    const p = Object.keys(reachSplit);
    const splitArray = [];
    const colorArray = [];
    if (reachSplit.total && reachSplit.total > 0) {
      p?.forEach((platform) => {
        if (platform !== "total" && reachSplit[platform] > 0) {
          const platformName = String(platform);
          const reachPercent = ((reachSplit[platform] / reachSplit["total"]) * 100).toFixed(2);
          splitArray.push({
            value: reachPercent,
            name: capitalizeFirstLetter(platformName),
          });
          colorArray.push(textColor[platformName]?.color || '#000');
        }
      });
    }
    setReachColorArray(colorArray);
    setReachSplit(splitArray);
  };

  const createEngagementSplitArray = (engagementSplit) => {
    const p = Object.keys(engagementSplit);
    const splitArray = [];
    const colorArray = [];
    if (engagementSplit.total && engagementSplit.total > 0) {
      p?.forEach((platform) => {
        if (platform !== "total" && engagementSplit[platform] > 0) {
          const platformName = String(platform);
          const engagementPercent = ((engagementSplit[platform] / engagementSplit["total"]) * 100).toFixed(2);
          splitArray.push({
            value: engagementPercent,
            name: capitalizeFirstLetter(platformName),
          });
          colorArray.push(textColor[platformName]?.color || '#000');
        }
      });
    }
    setEngagementColorArray(colorArray);
    setEngagementSplit(splitArray);
  };

  const recieveSelectedValues = (event) => {
    setDataFound(false);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setSelectedBreakdown(event.selectedBreakdown);
    setSelectedPlatforms(event.selectedPlatforms);
    setSelectedGroups(event.selectedGroups);
    setSelectedInfluencers(event.selectedInfluencers);
    getAnalyticsData(
      params.get("id"),
      event.startDate,
      event.endDate,
      event.selectedBreakdown,
      event.selectedPlatforms,
      [-1],
      event.selectedGroups?.length > 0 ? event.selectedGroups?.map((group) => group.id) : [-1],
      event.selectedInfluencers?.length > 0 ? event.selectedInfluencers?.map((influencer) => influencer.Iid) : [-1]
    );
  };

  return (
    <Box>
      <AdjustCampaignDates />
      <AnalyticsFilter
        recieveSelectedValues={recieveSelectedValues}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        breakdown={selectedBreakdown}
        platformIds={selectedPlatforms}
        selectedGroupsData={selectedGroups}
        selectedInfluencersData={selectedInfluencers}
      />
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <CampaignAnalyticsGraphs
          totalEngagement={totalEngagement}
          totalEngagementTime={totalEngagementTime}
          platforms={platforms}
          publicationEngagement={publicationEngagement}
          publicationEngagementTime={publicationEngagementTime}
          engagementSummary={engagementSummary}
          reachColorArray={reachColorArray}
          reachSplit={reachSplit}
          engagementSplit={engagementSplit}
          engagementColorArray={engagementColorArray}
          listEngagement={listEngagement}
          listEngagementTime={listEngagementTime}
          lists={lists}
          engByListwiseTime={engByListwiseTime}
          engByListwiseData={engByListwiseData}
          engByListwiseLists={engByListwiseLists}
          reachByListwiseTime={reachByListwiseTime}
          reachByListwiseData={reachByListwiseData}
          reachByListwiseLists={reachByListwiseLists}
          engByPostTypeData={engByPostTypeData}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.container}>
              <div className={styles.child}>This campaign has no analytics for the selected range.</div>
            </div>
          </Grid>
        </Grid>
      )}
      <br />
    </Box>
  );
};

export default CampaignAnalyticsSnapshot;
