import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextareaAutosize,
  Card,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FacebookPost from "../FacebookPost";
import InstagramPost from "../InstagramPost";
import LinkedinPost from "../LinkedinPost";
import TiktokPost from "../TiktokPost";
import TwitterPost from "../TwitterPost";
import YoutubePost from "../YoutubePost";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import MessageIcon from "@mui/icons-material/Message";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HistoryIcon from "@mui/icons-material/History";
import PostTextEditor from "../PostTextEditor";
import PreviewIcon from "@mui/icons-material/Preview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MuiAlert from "@mui/material/Alert";
import { getDraftDetails, draftPostAction, getDraftHistory } from "../../../../../services/publication";
import { charLengthSupported, draftStatusMapping ,statusDraftMapping} from "../CommonAssets";
import { notify } from "../../../../../redux/features/system/systemAlert";
import { getDraftsList } from "../../../../../services/publication";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import configs from "../../../../../config/main.json";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ApprovalDialog = ({ draft, iid, viewApproval, closeApproval, draftIds }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [postDetails, setPostDetails] = useState({});
  const [lastCommentHidden, setLastCommentHidden] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [editContent, setEditContent] = useState(false);
  const [content, setContent] = useState("");
  const [contentLength, setContentLength] = useState(0);
  const [maxLength, setMaxLength] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [postHistory, setPostHistory] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const postsIds = draftIds.map(d => d.id)|| [];

  useEffect(() => {
    if (viewApproval && draft?.id) {
      initialLoad(draft.id);
    }
  }, [viewApproval, draft.id]);

  useEffect(() => {
    const fb = document.getElementById("improvement_comment")?.value;
    if (contentLength > maxLength || (editContent && fb?.trim() === "")) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [contentLength, feedback, editContent, maxLength]);

  const initialLoad = async (draftId) => {
    if (!draftId) return;
    const postDetailsResp = await getDraftDetails(draftId, user, dispatch);
    if (postDetailsResp.status === true && postDetailsResp.response) {
      setPostDetails(postDetailsResp.response);
      setContent(postDetailsResp.response.postContent);
      setContentLength(postDetailsResp.response.postContent?.length || 0);
      setMaxLength(charLengthSupported[postDetailsResp.response.platform] || 1000);
    }
    const postHistoryResp = await getDraftHistory(draftId, user, dispatch);
    if (postHistoryResp.status === true && postHistoryResp.response) {
      setPostHistory(postHistoryResp.response);
    } else {
      setPostHistory([]);
    }
    setEditContent(false);
    setFeedback("");
    setUnsavedChanges(false);
  };

  const HandleActionButtonClick = async (action) => {
    const data = {
      postId: postDetails.id, 
      status: draftStatusMapping[action],
      comment: feedback,
      postContent: content,
      platform: "empowr",
      email: user.email,
      notificationMetadata: {
        userId: iid?.toString(),
        sourcePlatform: configs.PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "6",
        generatedBy: user.email,
        priority: "medium",
        consumedBy: "gazelle",
        navigateTo: `/account/posts/draft/${postDetails.id}`
      }
    };

    const result = await draftPostAction(data, user, action);
    if (result.status === true) {
      dispatch(notify({ message: result.response.Msg, type: "success" }));
      closeApproval(true);
    }
  };

  const renderPost = () => {
    if (!postDetails.id) return null;

    const baseStyle = {
      margin: "1px auto",
      padding: "20px",
      borderRadius: "10px",
    };

    const platformStyle = {
      instagram: { width: "100%", maxWidth: "450px", marginLeft: "30px" },
      linkedin: { width: "100%", maxWidth: "500px", marginLeft: "10px" },
      facebook: { width: "100%", maxWidth: "338px", marginLeft: "30px" },
      twitter: { width: "100%", maxWidth: "480px", marginLeft: "10px" },
      tiktok: { width: "100%", maxWidth: "500px", marginLeft: "30px" },
      youtube: { width: "100%", maxWidth: "600px", marginLeft: "30px" },
    };

    const currentPlatformStyle = platformStyle[postDetails.platform] || {};
    const statusValue = {
      1: "Submitted For Review",
      2: "Approved",
      3: "Rejected",
      4: "Improvement Suggested",
      5: "Deleted",
      6: "Edit With Suggestions",
      7: "Resubmitted",
      8: "Published",
      9: "Discard"
    };
    const statusText = statusValue[postDetails.currentStatus] || "Status Unknown";

    return (
      <>
        <Box
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            textAlign: "center",
            borderRadius: "10px",
            boxSizing: "border-box",
            padding: "5px",
            marginBottom: "0",
            ...currentPlatformStyle,
          }}
        >
          <Typography variant="body2">{statusText}</Typography>
        </Box>
        <Card style={{ ...baseStyle, ...currentPlatformStyle }}>
          {postDetails.platform === "instagram" && (
            <InstagramPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "linkedin" && (
            <LinkedinPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "facebook" && (
            <FacebookPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "twitter" && (
            <TwitterPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "tiktok" && (
            <TiktokPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "youtube" && (
            <YoutubePost formData={postDetails} cid={postDetails.cid} />
          )}
        </Card>
      </>
    );
  };

  const editPost = () => {
    return (
      <>
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <PostTextEditor
            isReadOnly={false}
            placeholder={"placeholder"}
            maxLength={maxLength}
            handlePostInput={(newContent) => {
              setContent(newContent);
              setContentLength(newContent.length);
              setUnsavedChanges(true);
            }}
            postContent={content}
            charCount={contentLength}
            setCharCount={setContentLength}
          />
        </FormControl>
        <Stack
          direction="row"
          justifyContent="end"
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Button
            sx={{ marginRight: "auto" }}
            variant="contained"
            size="large"
            startIcon={<CancelIcon />}
            color="secondary"
            onClick={() => {
              setEditContent(false);
              setDisableSubmit(false);
              setUnsavedChanges(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            startIcon={<CheckCircleIcon />}
            disabled={disableSubmit}
            onClick={() => {
              if (window.confirm("Are you sure you want to SUBMIT the edits with the following suggestions?")) {
                HandleActionButtonClick("Edit With Suggestions");
              }
            }}
          >
            Submit
          </Button>
        </Stack>
      </>
    );
  };

  const toggleLastComment = () => {
    setLastCommentHidden(!lastCommentHidden);
  };

  const getLastCommentToggleIcon = () => {
    if (lastCommentHidden) {
      return (
        <IconButton onClick={toggleLastComment} style={{ color: "black" }}>
          <ExpandMoreIcon fontSize="small" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleLastComment} style={{ color: "black" }}>
        <ExpandLessIcon fontSize="small" />
      </IconButton>
    );
  };

  const getTopComments = (topComment) => {
    if (lastCommentHidden) return null;
    return topComment.map((detail, idx) => (
      <React.Fragment key={idx}>
        <Typography variant="body2">{detail.comment}</Typography>
        {detail && (
          <Typography
            variant="caption"
            sx={{ color: "rgb(113, 118, 123)", display: "flex", alignItems: "center" }}
          >
            <AccessTimeIcon sx={{ fontSize: 12, color: "black", mr:0.5 }} />
            {new Date(detail.timestamp).toLocaleString()}
          </Typography>
        )}
      </React.Fragment>
    ));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const closeSnackbar = () => {
    setShowMessage(false);
  };

  const drawTimeline = () => {
    if (postHistory && postHistory.length > 0) {
      return (
        <Timeline>
          {postHistory.map((historyItem, index) => {
            const statusName = statusDraftMapping?.[historyItem.status] || "Status Unknown";
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent sx={{ py: 1, px: 2 }}>
                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem' }}>
                    {new Date(historyItem.createdAt).toLocaleString('en-US', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {statusName}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {index < postHistory.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ py: 1, px: 2 }}>
                  {historyItem.comment && (
                    <Typography variant="body2">{historyItem.comment}</Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      );
    } else {
      return (
        <Typography align="center" color="textSecondary">
          Nothing to show here!!
        </Typography>
      );
    }
  };

  const disableActions = editContent;

  
  const currentDraftId = postDetails.id;
  const currentIndex = currentDraftId ? postsIds.indexOf(currentDraftId) : -1;

  const isApprovedOrDiscarded = postDetails.currentStatus === 2 || postDetails.currentStatus === 9;

  const nextPost = () => {
    if (unsavedChanges && !window.confirm("You have unsaved changes. Do you want to proceed without saving?")) {
      return;
    }
    if (currentIndex >= 0 && currentIndex < postsIds.length - 1) {
      const newDraftId = postsIds[currentIndex + 1];
      initialLoad(newDraftId);
    }
  };

  const previousPost = () => {
    if (unsavedChanges && !window.confirm("You have unsaved changes. Do you want to proceed without saving?")) {
      return;
    }
    if (currentIndex > 0) {
      const newDraftId = postsIds[currentIndex - 1];
      initialLoad(newDraftId);
    }
  };


  return (
    <Dialog
      open={viewApproval}
      onClose={() => closeApproval(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <PreviewIcon
              style={{ color: "#464E5F", width: "3rem", height: "3rem" }}
            />
            <Box ml={2}>
              <Typography
                variant="h6"
                style={{ fontSize: "20px", color: "#000" }}
              >
                {postDetails.campaignName || "Loading..."}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#464E5F", fontSize: "14px" }}
              >
                Preview drafts, provide feedback or approve
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={() => closeApproval(false)}>
            <HighlightOffIcon style={{ color: "#000" }} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} md={isApprovedOrDiscarded ? 12 : 6}>
            {!editContent && postDetails.id && <div>{renderPost()}</div>}
            {editContent && <div>{editPost()}</div>}
          </Grid>
          {!isApprovedOrDiscarded && (
            <Grid item xs={12} md={6}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="post review tabs"
              >
                <Tab
                  icon={<CreditCardIcon style={{ color: "black" }} />}
                  iconPosition="start"
                  label={<span style={{ color: "#000" }}>Actions</span>}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<HistoryIcon style={{ color: "black" }} />}
                  iconPosition="start"
                  label={<span style={{ color: "#000" }}>History</span>}
                  {...a11yProps(1)}
                />
              </Tabs>

              <TabPanel value={tabValue} index={0}>
                <Typography
                  variant="body2"
                  style={{ color: "#344054", fontWeight: "500", marginBottom: 8 }}
                >
                  Feedback*
                </Typography>
                <TextareaAutosize
                  id="improvement_comment"
                  placeholder="Type in your feedback here."
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    padding: "12px",
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                  }}
                  minRows={5}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
                <Stack spacing={2} style={{ marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<MessageIcon />}
                    style={{
                      backgroundColor: disableActions ? "#E0E0E0" : "#FFFFFF",
                      color: disableActions ? "#9E9E9E" : "#595959",
                      border: disableActions ? "1px solid #D0D5DD" : "",
                    }}
                    onClick={() => {
                      if (feedback.trim() === "") {
                        alert("Please provide feedback before submitting.");
                      } else {
                        HandleActionButtonClick("Improvement Suggested");
                        setEditContent(false);
                        setFeedback("");
                        setUnsavedChanges(false);
                      }
                    }}
                    disabled={disableActions}
                  >
                    Give Feedback
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<CheckCircleIcon />}
                    style={{
                      backgroundColor: disableActions ? "#E0E0E0" : "#33CC99",
                      color: disableActions ? "#9E9E9E" : "#fff",
                      border: disableActions ? "1px solid #D0D5DD" : "",
                    }}
                    onClick={() => {
                      if (window.confirm("Are you sure you want to APPROVE this post?")) {
                        HandleActionButtonClick("Approved");
                      }
                    }}
                    disabled={disableActions}
                  >
                    Approve Draft
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<EditIcon />}
                    style={{
                      backgroundColor: disableActions ? "#E0E0E0" : "#7F56D9",
                      color: disableActions ? "#9E9E9E" : "#fff",
                      border: disableActions ? "1px solid #D0D5DD" : "",
                    }}
                    onClick={() => {
                      setEditContent(true);
                    }}
                    disabled={disableActions}
                  >
                    Edit Caption & Give Feedback
                  </Button>
                </Stack>

                {postDetails && (
                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                          Submitted by
                        </Typography>
                        <Typography variant="body2">
                          {postDetails.influencerName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                          List
                        </Typography>
                        <Typography variant="body2">
                          {postDetails.listName?.length > 14
                            ? postDetails.listName.substring(0, 14) + "..."
                            : postDetails.listName}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {drawTimeline()}
              </TabPanel>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="outlined"
            startIcon={<ReplyOutlinedIcon />}
            style={{
              color: currentIndex === 0 || currentIndex === -1 ? "gray" : "#007DFF",
              border: "2px solid",
              backgroundColor: "#fff",
              height: "44px",
            }}
            onClick={previousPost}
            disabled={currentIndex <= 0}
          >
            Previous Draft
          </Button>
          <Button
            variant="outlined"
            endIcon={<ReplyOutlinedIcon style={{ transform: "scaleX(-1)" }} />}
            style={{
              color: currentIndex === postsIds.length - 1 || currentIndex === -1 ? "gray" : "#007DFF",
              border: "2px solid",
              backgroundColor: "#fff",
              height: "44px",
            }}
            onClick={nextPost}
            disabled={currentIndex === postsIds.length - 1 || currentIndex === -1}
          >
            Next Draft
          </Button>
        </Grid>
      </DialogContent>
      <Snackbar
        open={showMessage}
        autoHideDuration={3000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="info">
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

ApprovalDialog.propTypes = {
  draft: PropTypes.object.isRequired,
  draftIds: PropTypes.array.isRequired,
  viewApproval: PropTypes.bool.isRequired,
  closeApproval: PropTypes.func.isRequired,
  iid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ApprovalDialog;
