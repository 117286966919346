import {
  ApartmentOutlined,
  BusinessOutlined,
  HomeOutlined,
  PaymentOutlined,
  PinOutlined,
} from "@mui/icons-material";
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormControl,
  Box,
} from "@mui/material";
import styles from "../../styles/internal/Subscribe.module.css";
import { RoundedTextField } from "../common/StyledComponents";
import React from "react";
import { getCountries } from "../../services/settings";
import { Button } from "react-bootstrap";
import config from "../../config/main.json";
import {
  addUnauthBillingDetailsToAccount,
  subscribeToPlan,
} from "../../services/billing";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { monthArray } from "../common/utils";
import { useLocation } from "react-router-dom";

const logo = `${config.BASE_IMAGE_URL}pay-logo-strip.png`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const BillingForm = ({
  email,
  stepSkipped,
  planDetails,
  otherDetails,
  couponCode,
}) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = React.useState([]);
  const [yearsArray, setYearsArray] = React.useState([]);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const [state, setState] = React.useState({
    cardNumber: "",
    selectedDate: new Date(),
    month: monthArray[new Date().getMonth()],
    year: new Date().getFullYear().toString(),
    country: "",
    cvv: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    region: "",
    postalcode: "",
    couponCode: couponCode,
  });
  React.useEffect(() => {
    getYearsArray();
    getAllCountries();
  }, []);

  const getYearsArray = () => {
    const yearArray = [];
    for (
      let year = new Date().getFullYear();
      year <= new Date().getFullYear() + 50;
      year++
    ) {
      yearArray.push(year);
    }
    setYearsArray(yearArray);
  };

  const getAllCountries = async () => {
    const result = await getCountries();
    setCountries(result.response);
  };

  const skipStep = async () => {
    const data = {
      PlanCode: planDetails.id.toString(),
      Email: email,
      FirstName: otherDetails.firstname,
      LastName: otherDetails.lastname,
      Company: otherDetails.agencyName,
      Currency: "USD",
      CompanyId: otherDetails.accountId,
    };
    const response = await subscribeToPlan(data);
    stepSkipped({ skipped: true });
  };

  const addBillingDetails = async () => {
    dispatch(
      notify({
        message: "Please wait... We are adding your billing details",
        type: "info",
      })
    );
    const {
      cardNumber,
      month,
      year,
      cvv,
      streetAddress1,
      streetAddress2,
      city,
      region,
      country,
      postalcode,
    } = state;
    const billingDetails = {
      cardNumber: cardNumber.replaceAll("  ", ""),
      month: (monthArray.indexOf(month) + 1).toString(),
      year: year.toString(),
      cvv,
      streetAddress1,
      streetAddress2,
      city,
      region,
      country,
      postalcode,
      street1: streetAddress1,
      street2: streetAddress2,
      email,
      couponCode: couponCode,
    };
    const response = await addUnauthBillingDetailsToAccount(billingDetails);
    if (response?.response === "success") {
      dispatch(
        notify({
          message: "Billing Details added successfully!",
          type: "success",
        })
      );
      stepSkipped({ skipped: true, billingDetails: billingDetails });
    } else {
      dispatch(
        notify({
          message: response?.response,
          type: "error",
        })
      );
    }
  };

  const card = (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="start"
          >
            <FormControlLabel
              value="start"
              control={<Radio />}
              label={
                planDetails?.planName?.toLowerCase() !== "free"
                  ? "Pay with Credit Card"
                  : "Add Credit Card"
              }
              labelPlacement="end"
              className={styles.formTitle}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <Box
            component={"img"}
            src={logo}
            alt="logo"
            sx={{ paddingTop: "10px" }}
          />
        </Grid>
      </Grid>

      <div className={styles.formTitle}>
        <b>Card Number</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="Card Number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PaymentOutlined />
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "\\d{13,22}", // Matches between 13 and 19 digits (including spaces)
              maxLength: 22, // Maximum length including spaces
            }}
            value={state.cardNumber}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const formattedValue = inputValue
                .slice(0, 16) // Limit to 16 digits
                .replace(/(.{4})/g, "$1  "); // Add spaces every 4 digits

              setState({ ...state, cardNumber: formattedValue.trim() }); // Update state
            }}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Card Details</b>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Month</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="<Month>"
              onChange={(e) => setState({ ...state, month: e.target.value })}
              value={state.month}
              sx={{
                borderRadius: "36px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "36px",
                },
              }}
            >
              {monthArray?.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Year</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="<Year>"
              onChange={(e) => setState({ ...state, year: e.target.value })}
              value={state.year}
              sx={{
                borderRadius: "36px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "36px",
                },
              }}
              MenuProps={MenuProps}
            >
              {yearsArray?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            type="text"
            sx={RoundedTextField}
            placeholder="<CVV>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PinOutlined />
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 3,
            }}
            value={state.cvv}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              setState({ ...state, cvv: inputValue.slice(0, 3) }); // Limit to 20 characters
            }}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Billing Address*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">
              Select a country
            </InputLabel>

            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="<Select a country>"
              onChange={(e) => setState({ ...state, country: e.target.value })}
              value={state.country}
              sx={{ borderRadius: "26px", width: "620px" }}
              MenuProps={MenuProps}
            >
              {countries?.map((country) => (
                <MenuItem key={country.id} value={country.countryCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<Street Address 1>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HomeOutlined />
                </InputAdornment>
              ),
            }}
            value={`${state.streetAddress1}`}
            onChange={(e) =>
              setState({ ...state, streetAddress1: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<Street Address 2>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HomeOutlined />
                </InputAdornment>
              ),
            }}
            value={`${state.streetAddress2}`}
            onChange={(e) =>
              setState({ ...state, streetAddress2: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<City>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ApartmentOutlined />
                </InputAdornment>
              ),
            }}
            value={`${state.city}`}
            onChange={(e) => setState({ ...state, city: e.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<State/Province>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <BusinessOutlined />
                </InputAdornment>
              ),
            }}
            value={`${state.region}`}
            onChange={(e) => setState({ ...state, region: e.target.value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<Postal Code>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PinOutlined />
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 6,
            }}
            value={`${state.postalcode}`}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              setState({ ...state, postalcode: inputValue.slice(0, 6) }); // Limit to 20 characters
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Button
            value="active"
            onClick={() => skipStep()}
            variant="outlined"
            className={styles.borderButton}
          >
            <b>Skip and Start Trial</b>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <div style={{ textAlign: "right" }}>
            <Button
              className={styles.staticButton}
              onClick={addBillingDetails}
              // disabled={!state.cardNumber || !state.selectedDate || !state.streetAddress1 || !state.cvv || !state.postalcode}
            >
              <b>Update Billing Information</b>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
  return <div>{card}</div>;
};

export default BillingForm;
