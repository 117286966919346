import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Modal,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  LocationOn,
  OpenInNew,
  Close,
  Person,
  Edit,
  Add,
} from "@mui/icons-material";
import {
  getDetailedInfluencerInformation,
  editInfluencerDetails,
} from "../../../../../services/influencer";
import {
  snapshotHeaderIcons2,
  textColor,
} from "../../../../../utility/plaformIcons";
import { stringAvatar } from "../../campaignDrafts/CommonAssets";
import configs from "../../../../../config/main.json";
import { useDispatch } from "react-redux";
import MultiselectLocations from "../../../../campaignBrief/multiselectLocations";
import MuiPhoneInput from "../../../../../pages/Settings/MuiPhoneInput";

const phoneIcon = `${configs.BASE_IMAGE_URL}Vector.svg`;
const mailIcon = `${configs.BASE_IMAGE_URL}email.svg`;
const locationIcon = `${configs.BASE_IMAGE_URL}location.svg`;
const warningIcon = `${configs.BASE_IMAGE_URL}Warning.svg`;
const ageIcon = `${configs.BASE_IMAGE_URL}age.svg`;
const genderIcon = `${configs.BASE_IMAGE_URL}Gender.svg`;

const genderData = ["Male", "Female", "Other", "Decline to self identify"];
const ageData = [
  { value: "13-17", text: "13-17" },
  { value: "18-24", text: "18-24" },
  { value: "25-34", text: "25-34" },
  { value: "35-44", text: "35-44" },
  { value: "45-54", text: "45-54" },
  { value: "55-64", text: "55-64" },
  { value: "65+", text: "65+" },
];

const arraysEqual = (arr1, arr2) => {
  if (!arr1 && !arr2) return true;
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((val, i) => val === arr2[i]);
};

const removeEmptyStrings = (arr) => {
  return arr?.filter(
    (item) =>
      typeof item === "string" &&
      item.trim() !== "" &&
      item.trim() !== ", ,"
  );
};

const prepareFieldUpdates = (typeOfRequest, originalArr, finalArr, id) => {
  const updates = [];
  const length = Math.max(originalArr.length, finalArr.length);

  for (let i = 0; i < length; i++) {
    const ov = (originalArr[i] || "").trim();
    const nv = (finalArr[i] || "").trim();

    if (ov === nv) continue; // no change

    // If adding a new value where none existed before
    if (!ov && nv) {
      if (typeOfRequest === "phone" && nv && !nv.includes("-")) {
        alert(`Phone number "${nv}" must include dial code "-"`);
        return [];
      }
      updates.push({ typeOfRequest, value: nv, id, edit: false, originalValue: "" });
      continue;
    }
    if (ov && nv && ov !== nv) {
      if (typeOfRequest === "phone" && nv && !nv.includes("-")) {
        alert(`Phone number "${nv}" must include dial code "-"`);
        return [];
      }
      updates.push({ typeOfRequest, value: nv, id, edit: true, originalValue: ov });
      continue;
    }
  }

  return updates;
};

const ViewDetailedProfileDialog = ({
  influencerAccount,
  viewProfile,
  closeViewProfileDialog,
  user,
}) => {
  const [loading, setLoading] = useState(true);
  const [influencerInformation, setInfluencerInformation] = useState({});
  const [originalInfluencerInformation, setOriginalInfluencerInformation] = useState({});
  const [locations, setLocations] = useState([]);
  const [primaryLocation, setPrimaryLocation] = useState("");
  const [genders, setGenders] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [names, setNames] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    closeViewProfileDialog(false);
    setIsEditMode(false);
  };

  const handleCancelEdit = () => {
    getInfluencerInformation();
    setIsEditMode(false);
  };

  useEffect(() => {
    if (viewProfile) {
      getInfluencerInformation();
    }
  }, [influencerAccount, viewProfile]);

  const getInfluencerInformation = () => {
    setLoading(true);
    getDetailedInfluencerInformation(influencerAccount.iid, user, dispatch)
      .then((data) => {
        let emailsArray = [];
        if (Array.isArray(data.emails)) {
          emailsArray = data.emails.map((email) =>
            typeof email === "object" ? email.value : email.trim()
          );
        } else if (typeof data.emails === "string") {
          emailsArray = data.emails.split(",").map((email) => email.trim());
        } else if (data.email) {
          emailsArray = [typeof data.email === "string" ? data.email.trim() : data.email];
        }

        let namesArray = [];
        if (Array.isArray(data.names)) {
          namesArray = data.names.map((name) =>
            typeof name === "object" ? name.value : name.trim()
          );
        } else if (typeof data.names === "string") {
          namesArray = data.names.split(",").map((name) => name.trim());
        } else if (data.name) {
          namesArray = [typeof data.name === "string" ? data.name.trim() : data.name];
        }

        const processedData = {
          ...data,
          emails: emailsArray,
          names: namesArray,
        };

        setInfluencerInformation(processedData);
        setOriginalInfluencerInformation(processedData);
        setPhones(removeEmptyStrings(data.contacts));

        const primaryLocationStr = data.primaryAddress?.value || "";
        setPrimaryLocation(primaryLocationStr);

        const locationsArray = removeEmptyStrings(data.locations || [])
          .map((loc) => (typeof loc === "object" ? loc.value : loc))
          .filter((loc) => loc !== primaryLocationStr);

        setLocations(locationsArray);

        const gendersArray = removeEmptyStrings(data.genders || []).map(
          (gender) => (typeof gender === "object" ? gender.value : gender)
        );
        setGenders(gendersArray);

        const ageRangesArray = removeEmptyStrings(data.ageRanges || []).map(
          (age) => (typeof age === "object" ? age.value : age)
        );
        setAgeRanges(ageRangesArray);

        setEmails(removeEmptyStrings(emailsArray));
        setNames(removeEmptyStrings(namesArray));

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching influencer information:", error);
        setLoading(false);
      });
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "-";
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Handle location changes (used by Multiselect)
  const handleLocations = (value) => {
    if (value !== "") {
      setLocations((prev) => {
        const updatedLocations = [...prev];
        if (!updatedLocations.includes(value)) {
          updatedLocations.push(value);
        }
        return updatedLocations;
      });
    }
  };

  const removeLocation = (value) => {
    setLocations((prev) => prev.filter((loc) => loc !== value));
  };

  const handleSaveChanges = async () => {
    const id = influencerAccount.iid;
    let updates = [];

    // Compare final arrays to originals:
    const originalEmails = originalInfluencerInformation.emails || [];
    const finalEmails = emails;
    if (!arraysEqual(finalEmails, originalEmails)) {
      const emailUpdates = prepareFieldUpdates("email", originalEmails, finalEmails, id);
      updates = updates.concat(emailUpdates);
    }

    const originalPhones = originalInfluencerInformation.contacts || [];
    const finalPhones = phones;
    const cleanedOriginalPhones = removeEmptyStrings(originalPhones);
    if (!arraysEqual(finalPhones, cleanedOriginalPhones)) {
      const phoneUpdates = prepareFieldUpdates("phone", cleanedOriginalPhones, finalPhones, id);
      updates = updates.concat(phoneUpdates);
    }

    const originalGenders = originalInfluencerInformation.genders
      ? originalInfluencerInformation.genders.map((g) => (typeof g === "object" ? g.value : g))
      : [];
    const finalGenders = genders;
    const cleanedOriginalGenders = removeEmptyStrings(originalGenders);
    if (!arraysEqual(finalGenders, cleanedOriginalGenders)) {
      const genderUpdates = prepareFieldUpdates("gender", cleanedOriginalGenders, finalGenders, id);
      updates = updates.concat(genderUpdates);
    }

    const originalAges = originalInfluencerInformation.ageRanges
      ? originalInfluencerInformation.ageRanges.map((a) => (typeof a === "object" ? a.value : a))
      : [];
    const finalAges = ageRanges;
    const cleanedOriginalAges = removeEmptyStrings(originalAges);
    if (!arraysEqual(finalAges, cleanedOriginalAges)) {
      const ageUpdates = prepareFieldUpdates("age_range", cleanedOriginalAges, finalAges, id);
      updates = updates.concat(ageUpdates);
    }

    const combinedOriginalLocations = [
      (originalInfluencerInformation.primaryAddress?.value || "").trim(),
      ...((originalInfluencerInformation.locations || []).map((loc) =>
        typeof loc === "object" ? loc.value.trim() : loc.trim()
      )),
    ].filter((loc) => loc !== "");

    const combinedNewLocations = [
      primaryLocation.trim(),
      ...locations.map((loc) => loc.trim()),
    ].filter((loc) => loc !== "");

    if (!arraysEqual(combinedNewLocations, combinedOriginalLocations)) {
      const locationUpdates = prepareFieldUpdates(
        "address",
        combinedOriginalLocations,
        combinedNewLocations,
        id
      );
      updates = updates.concat(locationUpdates);
    }

    // Remove duplicates if any:
    updates = updates.filter(
      (update, index, self) =>
        index ===
        self.findIndex(
          (u) =>
            u.typeOfRequest === update.typeOfRequest &&
            u.value === update.value &&
            u.originalValue === update.originalValue &&
            u.edit === update.edit
        )
    );

    if (updates.length === 0) {
      alert("No changes detected.");
      return;
    }

    try {
      await Promise.all(updates.map((update) => editInfluencerDetails(update, user, dispatch)));
      await getInfluencerInformation();
      setIsEditMode(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleMakePrimaryLocation = (location) => {
    const requestBody = {
      typeOfRequest: "address",
      value: location,
      id: influencerAccount.iid,
      action: "makePrimary",
    };

    editInfluencerDetails(requestBody, user, dispatch)
      .then(() => {
        setPrimaryLocation(location);
        setLocations(locations.filter((loc) => loc !== location));
        getInfluencerInformation();
      })
      .catch((error) => {
        console.error("Error making location primary:", error);
      });
  };

  const handlePrimaryContactChange = (phone) => {
    if (!phone.includes("-")) {
      if (
        window.confirm(
          'Phone number must include dial code "-" to be made primary. Would you like to add it now?'
        )
      ) {
        setIsEditMode(true);
      }
      return;
    }
    const requestBody = {
      typeOfRequest: "phone",
      value: phone,
      id: influencerAccount.iid,
      action: "makePrimary",
    };
    editInfluencerDetails(requestBody, user, dispatch)
      .then(() => {
        getInfluencerInformation();
      })
      .catch((error) => {
        console.error("Error making phone primary:", error);
      });
  };

  const handlePrimaryEmailChange = (email) => {
    const requestBody = {
      typeOfRequest: "email",
      value: email,
      id: influencerAccount.iid,
      action: "makePrimary",
    };

    editInfluencerDetails(requestBody, user, dispatch)
      .then(() => {
        getInfluencerInformation();
      })
      .catch((error) => {
        console.error("Error making email primary:", error);
      });
  };
  const handleAddPhone = () => {
    setPhones([""]);
  };

  const handleAddEmail = () => {
    setEmails([""]);
  };

  const handleAddGender = () => {
    setGenders([""]);
  };

  const handleAddAgeRange = () => {
    setAgeRanges([""]);
  };
  const handlePhoneInputChange = (value, country, index) => {
    let dialCode = country.dialCode;
    let cleanValue = value.replace(/[^0-9]/g, "");
    if (cleanValue.startsWith(dialCode)) {
      cleanValue = cleanValue.substring(dialCode.length);
    }
    const updatedPhones = [...phones];
    updatedPhones[index] = `${dialCode}-${cleanValue}`;
    setPhones(updatedPhones);
  };

  const getMuiPhoneValue = (phone) => {
    if (phone.includes("-")) {
      return `+${phone}`;
    } else {
      return `+1${phone}`;
    }
  };

  const getPhoneDialAndNumber = (phone) => {
    if (phone.includes("-")) {
      const [dial, num] = phone.split("-");
      return { dialCode: dial, number: num };
    }
    return { dialCode: "1", number: phone };
  };

  return (
    !loading && (
      <Modal
        open={viewProfile}
        onClose={handleClose}
        aria-labelledby="profile-modal-title"
        aria-describedby="profile-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isEditMode
              ? { xs: "95%", sm: "85%", md: "75%", lg: "70%" }
              : { xs: "90%", sm: "80%", md: "60%", lg: "55%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 0,
            borderRadius: 3,
            maxHeight: isEditMode ? "95vh" : "90vh",
            overflowY: "auto",
          }}
        >
          <Grid
            container
            sx={{
              height: "74px",
              background: "linear-gradient(to right, #C4E4FF 0%, #3699FF 100%)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1%",
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }}>
              {isEditMode ? "" : ""}
            </Typography>
            <Box>
              {isEditMode ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <IconButton
                  onClick={() => setIsEditMode(true)}
                  sx={{ color: "#fff" }}
                >
                  <Edit />
                </IconButton>
              )}
              <IconButton
                onClick={handleClose}
                sx={{ marginLeft: "10px", color: "#fff" }}
              >
                <Close />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            container
            sx={{
              margin: "0 4% 5% 4%",
              width: "96%",
              display: "flex",
              gap: "5%",
            }}
          >
            {/* Profile Picture & Platforms */}
            <Grid item>
              <Avatar
                src={influencerAccount.photoUrl || ""}
                {...stringAvatar(influencerAccount.name)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
                sx={{
                  width: 178,
                  height: 160,
                  borderRadius: 5,
                  position: "relative",
                  top: "-40px",
                }}
              />
              <Grid>
                {influencerInformation.platforms &&
                  influencerInformation.platforms.map(
                    (platform) =>
                      platform.platform !== "google" && (
                        <Grid
                          item
                          key={platform.platform}
                          style={{
                            marginTop: "8px",
                            color: "#000000",
                            fontSize: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={textColor[platform.platform]}
                              style={{ paddingTop: "10px" }}
                            >
                              {snapshotHeaderIcons2[platform.platform]}
                            </Typography>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "-7px",
                                }}
                              >
                                {platform.username === "" ? (
                                  <span style={{ fontSize: "20px" }}>-</span>
                                ) : (
                                  <span>@{platform.username}</span>
                                )}
                                {platform.profileurl !== "" && (
                                  <IconButton
                                    onClick={() =>
                                      window.open(platform.profileurl, "_blank")
                                    }
                                    size="small"
                                  >
                                    <OpenInNew style={{ fontSize: "14px" }} />
                                  </IconButton>
                                )}
                              </div>
                              <span>{platform.followers} followers</span>
                            </div>
                          </div>
                        </Grid>
                      )
                  )}
              </Grid>
            </Grid>

            {/* Main Info Section */}
            <Grid item sx={{ width: "60%" }}>
              {/* Name & Primary Location */}
              <Grid item style={{ marginTop: "20px" }}>
                <div
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: "#111111",
                    lineHeight: "40px",
                  }}
                >
                  {names[0] || "-"}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#94A2B3",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocationOn style={{ fontSize: "19px" }} />
                  {primaryLocation ? (
                    <>
                      {primaryLocation}
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        (primary)
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>

              {/* Membership & Campaign Data */}
              <Grid
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "2%",
                  flexWrap: "wrap",
                  color: "#1BC5BD",
                  fontSize: "12px",
                  width: "38vw",
                }}
              >
                <div style={{ width: "20%" }}>
                  Member since
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.createdAt !== ""
                      ? formatDate(influencerInformation.createdAt)
                      : "-"}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Active campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.activeCampaigns || 0}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Past campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.pastCampaigns || 0}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Contracts signed
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.contractsSigned || 0}
                  </div>
                </div>
              </Grid>

              {/* Contact + Demographic Info */}
              <Grid
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#262222",
                  gap: "5%",
                }}
              >
                <div style={{ width: "60%" }}>
                  {/* ---------------- Phone ---------------- */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={phoneIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Tel :
                    </span>
                  </div>
                  {(() => {
                    const havePhones = phones.length > 0;
                    if (isEditMode) {
                      if (havePhones) {
                        return phones.map((phone, index) => {
                          const { dialCode } = getPhoneDialAndNumber(phone);
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "5px",
                              }}
                            >
                              <MuiPhoneInput
                                country={dialCode ? dialCode : "us"}
                                value={getMuiPhoneValue(phone)}
                                onChange={(value, country) =>
                                  handlePhoneInputChange(value, country, index)
                                }
                                InputProps={{ endAdornment: null }}
                                inputStyle={{
                                  borderRadius: "6px",
                                  border: "1px solid #d9d9dd",
                                  boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                                  height: "45px",
                                  width: "220px",
                                }}
                              />
                            </div>
                          );
                        });
                      } else {
                        return (
                          <div style={{ marginTop: "5px" }}>
                            <MuiPhoneInput
                              country={"us"}
                              inputStyle={{
                                borderRadius: "6px",
                                border: "1px solid #d9d9dd",
                                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                                height: "45px",
                                width: "220px",
                              }}
                              onChange={(value, country) => {
                                let dialCode = country.dialCode;
                                let cleanValue = value.replace(/[^0-9]/g, "");
                                if (cleanValue.startsWith(dialCode)) {
                                  cleanValue = cleanValue.substring(dialCode.length);
                                }
                                setPhones([`${dialCode}-${cleanValue}`]);
                              }}
                            />
                          </div>
                        );
                      }
                    } else {
                      // Not in edit mode
                      if (havePhones) {
                        return phones.map((phone, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{phone}</span>
                            {phone ===
                            influencerInformation.primaryContactNumber?.value ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  marginLeft: "5px",
                                }}
                              >
                                (primary)
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                                onClick={() => handlePrimaryContactChange(phone)}
                              >
                                (Make it primary)
                              </span>
                            )}
                          </div>
                        ));
                      } else {
                        return (
                          <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                            -
                          </span>
                        );
                      }
                    }
                  })()}

                  {/* ---------------- Email ---------------- */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={mailIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Email :
                    </span>
                  </div>
                  {(() => {
                    const haveEmails = emails.length > 0;
                    if (isEditMode) {
                      // If we already have email(s), show them for editing but do NOT show "Add another email"
                      if (haveEmails) {
                        return emails.map((email, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <TextField
                              size="small"
                              value={email || ""}
                              onChange={(e) => {
                                const updatedEmails = [...emails];
                                updatedEmails[index] = e.target.value;
                                setEmails(updatedEmails);
                              }}
                              sx={{ width: "70%", marginRight: "10px" }}
                              placeholder="e.g., example@domain.com"
                            />
                          </div>
                        ));
                      } else {
                        // No email data -> show one textfield
                        return (
                          <TextField
                            size="small"
                            placeholder="e.g., example@domain.com"
                            onChange={(e) => setEmails([e.target.value])}
                            sx={{ width: "70%", marginTop: "5px" }}
                          />
                        );
                      }
                    } else {
                      if (haveEmails) {
                        return emails.map((email, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{email}</span>
                            {email === influencerInformation.primaryEmail?.value ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  marginLeft: "5px",
                                }}
                              >
                                (primary)
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                                onClick={() => handlePrimaryEmailChange(email)}
                              >
                                (Make it primary)
                              </span>
                            )}
                          </div>
                        ));
                      } else {
                        return (
                          <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                            -
                          </span>
                        );
                      }
                    }
                  })()}

                  {/* ---------------- Other Known Locations ---------------- */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={locationIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Other Known Locations :
                    </span>
                  </div>
                  {(() => {
                    if (isEditMode) {
                      // If we already have location data, just show them (no MultiSelect for adding)
                      if (locations.length > 0) {
                        return locations.map((location, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{location}</span>
                          </div>
                        ));
                      } else {
                        return (
                          <MultiselectLocations
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid #EFF0F7",
                                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                                borderRadius: "5px",
                              },
                              fieldset: {
                                legend: {
                                  width: "unset",
                                },
                              },
                              width: "100%",
                              marginTop: "5px",
                            }}
                            locationValues={locations}
                            setLocationValues={handleLocations}
                            removeLocation={removeLocation}
                          />
                        );
                      }
                    } else {
                      if (locations.length > 0) {
                        return locations.map((location, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{location}</span>
                            <span
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "blue",
                              }}
                              onClick={() => handleMakePrimaryLocation(location)}
                            >
                              (Make it primary)
                            </span>
                          </div>
                        ));
                      } else {
                        return (
                          <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                            -
                          </span>
                        );
                      }
                    }
                  })()}
                </div>
                <div>
                  {/* ---------------- Gender ---------------- */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={genderIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Gender :
                    </span>
                    {genders.length > 1 && new Set(genders).size > 1 && (
                      <img
                        src={warningIcon}
                        alt="Icon"
                        style={{
                          paddingBottom: "5px",
                          paddingLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                  {(() => {
                    const haveGenders = genders.length > 0;
                    if (isEditMode) {
                      if (haveGenders) {
                        return genders.map((genderItem, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <FormControl
                              size="small"
                              sx={{ minWidth: 150, marginRight: "10px" }}
                            >
                              <InputLabel id={`gender-select-label-${index}`}>
                                Select Gender
                              </InputLabel>
                              <Select
                                labelId={`gender-select-label-${index}`}
                                id={`gender-select-${index}`}
                                value={genderItem || ""}
                                label="Select Gender"
                                onChange={(e) => {
                                  const updatedGenders = [...genders];
                                  updatedGenders[index] = e.target.value;
                                  setGenders(updatedGenders);
                                }}
                              >
                                {genderData.map((genderOption) => (
                                  <MenuItem key={genderOption} value={genderOption}>
                                    {genderOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        ));
                      } else {
                        // No gender -> show one select for adding
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <FormControl
                              size="small"
                              sx={{ minWidth: 150, marginRight: "10px" }}
                            >
                              <InputLabel>Select Gender</InputLabel>
                              <Select
                                value=""
                                label="Select Gender"
                                onChange={(e) => setGenders([e.target.value])}
                              >
                                {genderData.map((genderOption) => (
                                  <MenuItem key={genderOption} value={genderOption}>
                                    {genderOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        );
                      }
                    } else {
                      // Not in edit mode
                      if (haveGenders) {
                        return genders.map((genderItem, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{genderItem}</span>
                            {genders.length > 1 && index === 0 && (
                              <img
                                src={warningIcon}
                                alt="Warning"
                                style={{
                                  paddingBottom: "5px",
                                  paddingLeft: "5px",
                                }}
                              />
                            )}
                          </div>
                        ));
                      } else {
                        return (
                          <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                            -
                          </span>
                        );
                      }
                    }
                  })()}

                  {/* ---------------- Age ---------------- */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ageIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Age :
                    </span>
                  </div>
                  {(() => {
                    const haveAgeRanges = ageRanges.length > 0;
                    if (isEditMode) {
                      // If we have any age data, just show them for editing (no "Add" link)
                      if (haveAgeRanges) {
                        return ageRanges.map((ageRange, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <FormControl
                              size="small"
                              sx={{ minWidth: 150, marginRight: "10px" }}
                            >
                              <InputLabel id={`age-select-label-${index}`}>
                                Select Age Range
                              </InputLabel>
                              <Select
                                labelId={`age-select-label-${index}`}
                                id={`age-select-${index}`}
                                value={ageRange || ""}
                                label="Select Age Range"
                                onChange={(e) => {
                                  const updatedAges = [...ageRanges];
                                  updatedAges[index] = e.target.value;
                                  setAgeRanges(updatedAges);
                                }}
                              >
                                {ageData.map((ageOption) => (
                                  <MenuItem
                                    key={ageOption.value}
                                    value={ageOption.value}
                                  >
                                    {ageOption.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        ));
                      } else {
                        // No age data -> show one select
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <FormControl
                              size="small"
                              sx={{ minWidth: 150, marginRight: "10px" }}
                            >
                              <InputLabel>Select Age Range</InputLabel>
                              <Select
                                value=""
                                label="Select Age Range"
                                onChange={(e) => setAgeRanges([e.target.value])}
                              >
                                {ageData.map((ageOption) => (
                                  <MenuItem
                                    key={ageOption.value}
                                    value={ageOption.value}
                                  >
                                    {ageOption.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        );
                      }
                    } else {
                      // Not in edit mode
                      if (haveAgeRanges) {
                        return ageRanges.map((ageRange, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span>{ageRange}</span>
                          </div>
                        ));
                      } else {
                        return (
                          <span style={{ fontSize: "20px", paddingLeft: "20px" }}>
                            -
                          </span>
                        );
                      }
                    }
                  })()}

                  {/* If we have additional known names, show them (view mode only) */}
                  {!isEditMode && names && names.length > 1 && (
                    <div style={{ marginTop: "20px" }}>
                      <Person
                        style={{ fontSize: "14px", marginBottom: "5px" }}
                      />
                      <span
                        style={{
                          color: "#111111",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Other Known Names :
                      </span>
                      <br />
                      {names.slice(1).map((name, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "3px",
                            marginTop: "5px",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>•</span>
                          {name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  );
};

ViewDetailedProfileDialog.propTypes = {
  influencerAccount: PropTypes.object.isRequired,
  viewProfile: PropTypes.bool.isRequired,
  closeViewProfileDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ViewDetailedProfileDialog;
