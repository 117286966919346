import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CustomizedTable from "../../common/customizedTable";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";
import { useLocation, useNavigate } from "react-router-dom";


const CampaignTable = ({
  tableHeaders,
  getInformation,
  totalRows,
  bodyColumns,
  selectedRow,
  page,
  rowsPerPage,
  sortOrderValue,
  sortOrderBy,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sendInformation = (event) => {
    getInformation(event);
  };
  // const handleRowClick = (row) => {
  //   navigate(`${location.pathname}/overview?id=${row.id}&universal=${row.universal}`, {
  //     replace: true,
  //   });
  // }

  const handleRowClick = (row) => {
    if (row.status === "Draft") return;
  
    navigate(`${location.pathname}/overview?id=${row.id}&universal=${row.universal}`, {
      replace: true,
    });
  };

  React.useEffect(() => {}, [bodyColumns, tableHeaders]);

  return (
    <div className={styles.campaignListTable}>
      <CustomizedTable
        tableHeaders={tableHeaders}
        bodyColumns={bodyColumns}
        selectedRow={selectedRow}
        sendInformation={sendInformation}
        totalRows={totalRows}
        pageNo={page}
        rowsPerPageNo={rowsPerPage}
        sortOrder={sortOrderValue}
        sortOrderBy={sortOrderBy}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CampaignTable.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  getInformation: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  bodyColumns: PropTypes.array.isRequired,
  selectedRow: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortOrderValue: PropTypes.string.isRequired,
  sortOrderBy: PropTypes.string.isRequired,
};

export default CampaignTable;
