import CampaignSnapshot from "./CampaignSnapshot";
import CampaignWidget from "./CampaignWidget";
import ReactEcharts from "echarts-for-react";
import { MixedBarLineChart } from "../../../common/chart";
import { Button, Card, Grid, Box, Tooltip } from "@mui/material";
import * as echarts from "echarts";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import React from "react";
import { getCampaignOverview, getCampaignRecentUpdates, getPerfomanceGraph } from "../../../../services/campaignAnalytics";
import Loader from "../../../common/loader";
import { convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import { useLocation, useNavigate } from "react-router-dom";
import { trendLine } from "../../../../utility/status";
import { useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { useDispatch } from "react-redux";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import DummyCampaign from "../../../common/DummyCampaign";
import { Archive, Edit, Unarchive } from "@mui/icons-material";
import { refreshCampaignStatus } from "../../../../services/campaigns";
import { grey } from "@mui/material/colors";
import EmptyCampaign from "./EmptyCampaign"
// import { Archive, Visibility } from "@mui/icons-material";

const CampaignOverviewSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [dataFound, setDataFound] = React.useState(false);
  const [overallSummary, setOverallSummary] = React.useState({}); //overall summary for campaign widgets
  const [goalsSnapshot, setGoalsSnapshot] = React.useState({}); //goals for campaign widgets
  const [recentUpdates, setRecentUpdates] = React.useState({}); //recent updates for campaign widgets
  const [snapshot, setSnapshot] = React.useState({}); //platform-wise snapshot
  const [platforms, setPlatforms] = React.useState([]); //platforms on the campaign
  const [xAxisData, setXAxisData] = React.useState([]);
  const [yAxisPostData, setYAxisPostData] = React.useState([]);
  const [yAxisEngagementData, setYAxisEngagementData] = React.useState([]);
  const [performanceGraph, setPerformanceGraph] = React.useState({}); //performance graph data
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [universal, setUniversal] = React.useState({});
  React.useEffect(() => {
    const universal = params.get("universal");
    setUniversal(JSON.parse(universal ? universal?.toLowerCase() : false));
    setDataFound(false);
    getOverviewDetails();
  }, [+params.get("id")]);

  const getOverviewDetails = async () => {
    setLoading(true);
    const overview = await getCampaignOverview(+params.get("id"), user, dispatch);
    const recentUpdates = await getCampaignRecentUpdates(+params.get("id"), user, dispatch);
    const perfomance = await getPerfomanceGraph(params.get("id"), user, dispatch);
    if (perfomance.status === true) {
      setPerformanceGraph(perfomance.response);
      createGraph(perfomance.response);
    }
    if (recentUpdates.status === true) {
      setRecentUpdates(recentUpdates.response);
    }
    if (overview.status === true) {
      if (overview.response.overallSummary) {
        setDataFound(true);
        setOverallSummary(overview.response.overallSummary[0]);
        setGoalsSnapshot(overview.response.goalsSnapshot);
      }
      if (overview.response.snapshot) {
        setDataFound(true);
        setSnapshot(overview.response.snapshot);
        const platforms = Object.keys(overview.response.snapshot);
        setPlatforms(platforms);
      }
    } else {
      if (overview.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: overview.response?.message,
          type: "error",
        })
      );
    }

    setLoading(false);
  };

  const createGraph = (graphData) => {
    const xAxisData = Object.keys(graphData.campaignSplit);
    setXAxisData(xAxisData);
    const postData = [];
    const engagementData = [];
    xAxisData.forEach((value) => {
      postData.push(graphData.campaignSplit[value].posts);
      engagementData.push(graphData.campaignSplit[value].engagement);
    });
    setYAxisPostData(postData);
    setYAxisEngagementData(engagementData);
  };

  const archieveCampaign = async () => {
    dispatch(
      notify({
        message: "Please wait... we are archieving the campaign",
        type: "info",
      })
    );
    const data = {
      id: +params.get("id"),
      current_status: 6,
    };
    const {
      response: { status },
    } = await refreshCampaignStatus(data, user, dispatch);
    if (status === "success") {
      dispatch(
        notify({
          message: "Archived successfully",
          type: "success",
        })
      );
      setDataFound(false);
      getOverviewDetails();
    } else {
      dispatch(
        notify({
          message: status,
          type: "error",
        })
      );
    }
  };
  return loading ? (
    <Loader />
  ) : dataFound ? (
    <>
      <DummyCampaign universal={universal} />
      <AdjustCampaignDates />

      <Box mt={0} mr={0} sx={{ width: "99%" }}>
        <Grid container spacing={1} sx={{ display: "flex", justifyContent: "end" }}>
          <Grid item>
            <Tooltip title={universal ? "" : "Edit Campaign Details"}>
              <span>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "40px",
                    width: "40px",
                    height: "40px",
                    padding: "6px",
                    background: universal
                      ? "gray"
                      : "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                    color: "white",
                    "&:disabled": {
                      background: "gray",
                      color: "white",
                    },
                  }}
                  onClick={(e) =>
                    navigate(`/app/campaigns/edit/wizard?id=${params.get("id")}&eventType=${overallSummary.eventType}`)
                  }
                  disabled={universal}
                >
                  <Edit sx={{ fontSize: "20px", color: "white" }} />
                </Button>
              </span>
            </Tooltip>

          </Grid>
          {overallSummary.campaignStatus.toLowerCase() !== "archived" && (
            <Grid item>
              <Tooltip title={universal ? "" : "Archive this Campaign"}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50%",
                  minWidth: "40px",
                  width: "40px",
                  height: "40px",
                  padding: "6px",
                  background: universal
                    ? "gray"
                    : "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                  color: "white",
                  "&:disabled": {
                    background: "gray",
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (window.confirm("Are you sure you want to archive the campaign?")) {
                    archieveCampaign();
                  }
                }}
                disabled={universal}
              >
                <>
                  <Archive sx={{ fontSize: "20px", color: "white" }} />
                </>
              </Button>
            </Tooltip>
            </Grid>
          )}
      </Grid>
    </Box >

      <br />
  {/* Campaign Widgets to show campaign summary */ }
  <CampaignWidget overallSummary={overallSummary} goalsSnapshot={goalsSnapshot} recentUpdates={recentUpdates} emptyCampaign={platforms?.length === 0 ? true : false} />

  {
    platforms?.length > 0 && (
      <>
        <br />
        <br />
        {/* Campaign Snapshot to show platform-wise summary of the campaign */}
        <CampaignSnapshot snapshot={snapshot} platforms={platforms} />
        <br />
        {/* Graph to show publication and engagement relationship */}
        <Card style={{ padding: "20px", background: "white", borderRadius: 10 }}>
          <ReactEcharts
            option={MixedBarLineChart(
              "Performance Over Time (All Channels)",
              ["Publications", "Engagement"],
              xAxisData,
              [
                {
                  type: "value",
                  name: "Publications",
                },
                {
                  type: "value",
                  name: "Engagement",
                },
              ],
              [
                {
                  name: "Publications",
                  type: "bar",
                  color: ["#DDE3EE"],
                  data: yAxisPostData,
                },
                {
                  name: "Engagement",
                  type: "line",
                  smooth: true,
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgb(190, 227, 248)",
                      },
                      {
                        offset: 1,
                        color: "rgb(252, 254, 255)",
                      },
                    ]),
                  },
                  yAxisIndex: 1,
                  data: yAxisEngagementData,
                },
              ]
            )}
            style={{ height: "450px", width: "100%" }}
          />
          <p style={{ position: "absolute", right: 100 }}>*Engagement is cumulative</p>
          <br />
          {/* Snapshot of the engagement this and previous week and the post added this week */}
          <Grid container>
            <Grid item xs={3}>
              <h5 className={styles.subtextCardTitle}>Engagement This Week</h5>
              <h5 className={styles.subtextCardContent}>
                {convertToInternationalNumberSystem(performanceGraph.engagementsThisWeek?.value)}
              </h5>
              {trendLine[performanceGraph.engagementsThisWeek?.trend]}
            </Grid>
            <Grid item xs={3}>
              <h5 className={styles.subtextCardTitle}>Engagement Last Week</h5>
              <h5 className={styles.subtextCardContent}>
                {convertToInternationalNumberSystem(performanceGraph.engagementsLastWeek?.value)}
              </h5>
              {trendLine[performanceGraph.engagementsLastWeek?.trend]}
            </Grid>
            <Grid item xs={3}>
              <h5 className={styles.subtextCardTitle}>Publications This Week</h5>
              <h5 className={styles.subtextCardContent}>{performanceGraph.postThisWeek?.value}</h5>
              {trendLine[performanceGraph.postThisWeek?.trend]}
            </Grid>
            <Grid item xs={3}>
              <h5 className={styles.subtextCardTitle}>Lifetime Publications</h5>
              <h5 className={styles.subtextCardContent}>{performanceGraph.totalPosts?.value}</h5>
            </Grid>
          </Grid>
        </Card>
      </>
    )
  }
  {
    platforms?.length === 0 && (
      <EmptyCampaign overallSummary={overallSummary} />
    )
  }
    </>
  ) : (
  <EmptyCampaign overallSummary={overallSummary} />
);
};

export default CampaignOverviewSnapshot;
