import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  styled,
  TableSortLabel,
  Checkbox,
  Select,
  MenuItem,
  PaginationItem,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { convertToInternationalNumberSystem } from "../../utility/stringOperations";
import "../../styles/internal/customisedTable.css";

const StyledTableCell = styled(TableCell)(({ theme, colors, nested }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "none",
    color: colors?.header?.textColor ? colors?.header?.textColor : "black",
    ...(nested && { padding: "8px" }),
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    border: "none",
    color: colors?.body?.textColor ? colors?.header?.textColor : "black",
    // backgroundColor: colors?.body?.backgroundColor
    //   ? colors?.body?.backgroundColor
    //   : "white",
    ...(nested && { padding: "8px", backgroundColor: "#f0f0f0" }),
    fontSize: "14px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, colors }) => ({
  backgroundColor: colors?.body?.backgroundColor
    ? colors.body.backgroundColor
    : "white",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#DCDCE0 !important",
  },
}));

const PaginationActions = ({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  paginationAtTop,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage - 1);
  };
  const start = page * rowsPerPage + 1;
  const end = Math.min((page + 1) * rowsPerPage, count);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        position: "relative",
        mt: 2,
        overflowY: "hidden",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {!paginationAtTop && (
          <span>
            Showing{" "}
            {`${convertToInternationalNumberSystem(
              start
            )}-${convertToInternationalNumberSystem(
              end
            )} of ${convertToInternationalNumberSystem(count)}`}
          </span>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
          renderItem={(item) => {
            if (item.type === "previous" || item.type === "next") {
              return (
                <PaginationItem
                  {...item}
                  sx={{
                    border: "1px solid",
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    margin: "0 4px",
                    padding: "0 8px",
                    "&:hover": {
                      bgcolor: "#f0f0f0",
                    },
                    "&.Mui-selected": {
                      bgcolor: "#3D9CFF",
                      color: "white",
                    },
                  }}
                >
                  {item.type === "previous" ? "< Prev" : "Next >"}
                </PaginationItem>
              );
            }
            return (
              <PaginationItem
                {...item}
                sx={{
                  "&.MuiPaginationItem-page": {
                    border: "1px solid",
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    margin: "0 4px",
                    padding: "0 8px",
                  },
                  "&.Mui-selected": {
                    bgcolor: "#3D9CFF",
                    color: "white",
                  },
                  "&.MuiPaginationItem-ellipsis": {
                    border: "none",
                    margin: "0 4px",
                  },
                }}
              />
            );
          }}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Select
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          sx={{
            border: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            margin: "0 4px",
            padding: "0 8px",
            "&:hover": {
              bgcolor: "#f0f0f0",
            },
            height: "36px",
            minWidth: "56px",
          }}
        >
          {[10, 20, 30, 50]?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, tableHeaders, nested } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeaders?.map(
          (headCell) =>
            headCell.show && (
              <StyledTableCell
                key={headCell.id}
                align={headCell.headeralignment}
                sortDirection={orderBy === headCell.field ? order : false}
                nested={nested}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.field}
                    direction={orderBy === headCell.field ? order : "asc"}
                    onClick={createSortHandler(headCell.field)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span">
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </StyledTableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
};

const CustomizedRow = ({
  row,
  headerColumns,
  expand,
  selectedRow,
  lessPaddedRows,
  nested,
  onRowClick,
}) => {
  const handleRowClick = () => {
    console.log(typeof onRowClick);
    if (typeof onRowClick === "function") {
      onRowClick(row);
    } else {
      console.warn("onRowClick is not a function");
    }
  };
  return (
    <>
      {/* {typeof onRowClick === "function" && row.status === "Draft" ? (
        <Tooltip
          title={
            <span style={{ fontSize: "12px" }}>
              You can view your campaign status only after the campaign is created.
            </span>
          }
        >
          <StyledTableRow hover key={row.id} onClick={handleRowClick}>
          {headerColumns?.map((header) => {
            return (
              header.show && (
                <StyledTableCell
                  nested={nested}
                  key={header.id}
                  align={header.bodyalignment}
                  scope="row"
                  sx={{
                    borderBottomLeftRadius:
                      expand !== undefined &&
                      expand[row.selectedId] &&
                      "0 !important",
                    borderBottomRightRadius:
                      expand !== undefined &&
                      expand[row.selectedId] &&
                      "0 !important",
                    padding: lessPaddedRows ? "12px" : "16px",
                  }}
                >
                  {header.headerAssignment ? row[header.headerAssignment] : row[header.field]}
                </StyledTableCell>
              )
            );
          })}
        </StyledTableRow>
        </Tooltip>
      ) : (
        <StyledTableRow hover key={row.id} onClick={handleRowClick}>
          {headerColumns?.map((header) => {
            return (
              header.show && (
                <StyledTableCell
                  nested={nested}
                  key={header.id}
                  align={header.bodyalignment}
                  scope="row"
                  sx={{
                    borderBottomLeftRadius:
                      expand !== undefined &&
                      expand[row.selectedId] &&
                      "0 !important",
                    borderBottomRightRadius:
                      expand !== undefined &&
                      expand[row.selectedId] &&
                      "0 !important",
                    padding: lessPaddedRows ? "12px" : "16px",
                  }}
                >
                  {header.headerAssignment ? row[header.headerAssignment] : row[header.field]}
                </StyledTableCell>
              )
            );
          })}
        </StyledTableRow>
      )} */}

      <StyledTableRow hover key={row.id} onClick={handleRowClick}>
        {headerColumns?.map((header) => {
          return (
            header.show && (
              <StyledTableCell
                nested={nested}
                key={header.id}
                align={header.bodyalignment}
                scope="row"
                sx={{
                  borderBottomLeftRadius:
                    expand !== undefined &&
                    expand[row.selectedId] &&
                    "0 !important",
                  borderBottomRightRadius:
                    expand !== undefined &&
                    expand[row.selectedId] &&
                    "0 !important",
                  padding: lessPaddedRows ? "12px" : "16px",
                }}
              >
                {header.headerAssignment
                  ? row[header.headerAssignment]
                  : row[header.field]}
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>


      {expand !== undefined && expand[row.selectedId] && (
        <StyledTableRow key={row.id}>
          {row.expand?.map((cell) => (
            <StyledTableCell
              key={row.id}
              align="left"
              colSpan={cell.colSpan}
              scope="row"
              sx={{
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important",
                borderTop: "1px solid #D3D3D3 !important",
                padding: lessPaddedRows ? "12px" : "16px",
              }}
            >
              {cell.content}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      )}
    </>
  );
};

const CustomizedTable = ({
  tableHeaders,
  filtersApplied,
  bodyColumns,
  expand,
  sendInformation,
  selectedRow,
  totalRows,
  pageNo,
  rowsPerPageNo,
  sortOrder,
  sortOrderBy,
  lessPaddedRows,
  customRowPageOptions,
  pagination = true,
  nested = false,
  staticTable = false,
  paginationAtTop = false,
  onRowClick,
}) => {
  const [order, setOrder] = React.useState(sortOrder);
  const [orderBy, setOrderBy] = React.useState(sortOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(pageNo);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageNo);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [pendingPageChange, setPendingPageChange] = useState(null);

  const start = page * rowsPerPage + 1;
  const end = Math.min((page + 1) * rowsPerPage, totalRows);

  const handleConfirmPageChange = () => {
    setOpenConfirmationDialog(false);
    setPage(pendingPageChange);
    setPendingPageChange(null);
    handleInformationChange(pendingPageChange, rowsPerPage, order, orderBy);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleInformationChange(
      page,
      rowsPerPage,
      isAsc ? "desc" : "asc",
      property
    );
  };

  const handleInformationChange = (page, rowsPerPage, order, orderBy) => {
    sendInformation({ page, rowsPerPage, order, orderBy });
  };

  const handleChangePage = (event, newPage) => {
    if (filtersApplied) {
      setPendingPageChange(newPage);
      setOpenConfirmationDialog(true);
    } else {
      setPage(newPage);
      handleInformationChange(newPage, rowsPerPage, order, orderBy);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handleInformationChange(0, newRowsPerPage, order, orderBy);
  };

  return (
    <Box sx={!nested ? { width: "100%" } : { width: "100%", p: "0 10%" }}>
      {paginationAtTop && (
        <Box sx={{ flex: 1, mb: 2 }}>
          <span>
            Showing{" "}
            {`${convertToInternationalNumberSystem(
              start
            )}-${convertToInternationalNumberSystem(
              end
            )} of ${convertToInternationalNumberSystem(totalRows)}`}
          </span>
        </Box>
      )}
      <TableContainer sx={{ maxWidth: "100%" }}>
        <Table
          style={{ overflow: "hidden" }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={bodyColumns?.length}
            tableHeaders={tableHeaders}
            nested={nested}
          />
          <TableBody className="container">
            {bodyColumns?.map((rowData, index) => {
              return (
                <>
                  <CustomizedRow
                    key={index}
                    row={rowData}
                    headerColumns={tableHeaders}
                    expand={expand}
                    selectedRow={selectedRow}
                    lessPaddedRows={lessPaddedRows}
                    nested={nested}
                    onRowClick={onRowClick}
                  />
                  <span style={{ display: "block", marginBottom: "1rem" }} />
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          ActionsComponent={(subProps) => (
            <PaginationActions
              {...subProps}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              paginationAtTop={paginationAtTop}
            />
          )}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() => ""}
          sx={{
            "& .MuiTablePagination-spacer": {
              flex: "none",
            },
            "& .MuiTablePagination-actions": {
              display: "none",
            },
          }}
        />
      )}
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Confirm Page Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have searched for some profiles, do you wish to go to another
            page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmPageChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CustomizedTable.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  bodyColumns: PropTypes.array.isRequired,
  expand: PropTypes.array,
  sendInformation: PropTypes.func,
  selectedRow: PropTypes.object,
  totalRows: PropTypes.number.isRequired,
  pageNo: PropTypes.number.isRequired,
  rowsPerPageNo: PropTypes.number.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortOrderBy: PropTypes.string.isRequired,
  pagination: PropTypes.bool,
  nested: PropTypes.bool,
  staticTable: PropTypes.bool,
  paginationAtTop: PropTypes.bool,
};

export default CustomizedTable;
