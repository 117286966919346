import { Box, IconButton, Step, StepLabel, Stepper, Backdrop, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CampaignWizardStep1 from "./CampaignWizardStep1";
import CampaignWizardStep2 from "./CampaignWizardStep2";
import CampaignWizardStep3 from "./CampaignWizardStep3";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PlatformIdMapping } from "../../../utility/plaformIcons";
import { getCreditInfo } from "../../../services/credit";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { StyledStaticButtonRoundedGroup, StyledStaticRoundedButton } from "../../common/StyledComponents";
import SvgIcon from '@mui/material/SvgIcon';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';

const CampaignWizardStepper = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const steps = ["Select master blaster campaign settings", "Create an ad group", "Create an ad"];
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [campaignId, setCampaignId] = React.useState(0);
  const [audienceId, setAudienceId] = React.useState(0);

  const [campaignAudience, setCampaignAudience] = React.useState(null);
  const [campaign, setCampaign] = React.useState(null);
  const [campaignGoal, setCampaignGoal] = React.useState(null);
  const [editModeActive, setEditModeActive] = React.useState(false);
  const [allowedPlatforms, setAllowedPlatforms] = React.useState([]);
  const [allPlatforms, setAllPlatforms] = React.useState(Object.keys(PlatformIdMapping));
  const [influencerAllowed, setInfluencerAllowed] = React.useState(0);
  const [publicationAllowed, setPublicationAllowed] = React.useState(0);
  const [closeModal, setCloseModal] = useState(false);
  const [isCampStatusDraft, setIsCampStatusDraft ] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getCreditInfoForPlans();
  }, []);

  const getCreditInfoForPlans = async () => {
    const data = {
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const creditInfo = await getCreditInfo(data, user, dispatch);
    if (creditInfo.status) {
      const { Platforms, Fields } = creditInfo.response.Campaigns;
      setInfluencerAllowed(Fields.Influencers.CreditsAvailable);
      setPublicationAllowed(Fields.Publications.CreditsAvailable);
      setAllPlatforms(Platforms.split(","));
    }
  };
  const navigate = useNavigate();
  const goToNextStep = (event, status) => {
    setIsCampStatusDraft(status);
    setSelectedStep(event);
  };
  const getCampaignId = (event) => {
    setCampaignId(event);
  };

  const getAudienceId = (event) => {
    setAudienceId(event);
  };

  const setAudienceState = (event) => {
    setCampaignAudience(event);
  };
  const setGoalState = (event) => {
    setCampaignGoal(event);
  };
  const setCampaignData = (event) => {
    setCampaign(event);
  };
  const sendEditMode = (event) => {
    setEditModeActive(event);
  };
  const handleCloseModal = () => {
    setCloseModal(true);
  }

  const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;

    return (
      <Box>
        {completed ? (
          <Box
            sx={{
              width: 28,
              height: 28,
              borderRadius: '50%',
              backgroundColor:'primary.main',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudDoneOutlinedIcon sx={{width: "18px", height: "20px"}}/>
          </Box>
        ) : (
          <Box
            sx={{
              width: 28,
              height: 28,
              borderRadius: '50%',
              backgroundColor: active ? 'primary.main' : 'grey.400',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Box>
        )}
      </Box>
    );
  };

  const handleClose = () => {
    navigate(`/app/campaigns`);
  }

  const setPlatforms = (event) => {
    setAllowedPlatforms(event);
  };

  return (
    <>
      <Box m={3} sx={{ backgroundColor: "#F7F7F7", padding: "10px" }}>
        <Box display="flex" justifyContent="flex-end" style={{ paddingRight: "10px", paddingTop: "10px" }}>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Box>
        <Box m={3}>
        <Stepper activeStep={selectedStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                >
                  {index === 0 ? 'Basics' : index === 1 ? 'Target Audience' : 'Goals'}
                </StepLabel>
              </Step>
            ))}
            </Stepper>
          {selectedStep === 0 && (
            <CampaignWizardStep1
              goToNextStep={goToNextStep}
              campaign={campaign}
              getCampaignId={getCampaignId}
              setCampaignData={setCampaignData}
              sendEditMode={sendEditMode}
              setPlatforms={setPlatforms}
              allPlatforms={allPlatforms}
              isCampStatusDraft={isCampStatusDraft}
            />
          )}
          {selectedStep === 1 && (
            <CampaignWizardStep2
              goToNextStep={goToNextStep}
              campaignId={campaignId}
              campaignAudience={campaignAudience}
              setAudienceState={setAudienceState}
              getAudienceId={getAudienceId}
              editModeActive={editModeActive}
              isCampStatusDraft={isCampStatusDraft}
            />
          )}
          {selectedStep === 2 && (
            <CampaignWizardStep3
              goToNextStep={goToNextStep}
              campaignId={campaignId}
              audienceId={audienceId}
              campaignGoal={campaignGoal}
              setGoalState={setGoalState}
              campaignAudience={campaignAudience}
              editModeActive={editModeActive}
              sendEditMode={sendEditMode}
              allowedPlatforms={allowedPlatforms}
              influencerAllowed={influencerAllowed}
              publicationAllowed={publicationAllowed}
              isCampStatusDraft={isCampStatusDraft}
            />
          )}
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={closeModal}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Fade in={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              borderRadius: "15px",
              boxShadow: 24,
              p: 4,
            }}
          >
            Any unsaved changes will be lost. Are you sure you want to continue and exit?
            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "40px" }}>
              <Button
                variant="outlined"
                style={{
                  width: "180px",
                  height: "38px",
                  color: "#474747",
                  borderColor: "#474747",
                  borderRadius: "16px",
                  marginTop: "5px"
                }}
                onClick={() => setCloseModal(false)}
              >
                Cancel
              </Button>
              <StyledStaticButtonRoundedGroup
                color="primary"
                size="small"
                exclusive
                style={{ height: "30px", width: "200px" }}
                aria-label="Template"
              >
                <StyledStaticRoundedButton value="active" onClick={handleClose} >
                  Yes, Exit
                </StyledStaticRoundedButton>
              </StyledStaticButtonRoundedGroup>
            </div>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};

export default CampaignWizardStepper;
