import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Filters from "../Filters";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataByPlatform } from "../../../services/discovery";
import { checkForCredit } from "../../../services/credit";

const MoreFilters = (props) => {
  const {
    setSelectedData,
    setTableDataUpdated,
    customCols,
    setCustomCols,
    tableDataLoaded,
    setTableDataLoaded,
    tablePageChanged,
    tableRowsPerPageChanged,
    tableRowsPerPage,
    tablePageNo,
    isChanged,
    setIsChanged,
    resetPageNo,
    setTablePageNo,
    payload,
    setPayload,
    allFiltersData,
    setAllFiltersData,
    selectedCol,
    setSelectedCol,
    sortCol,
    selectedPlatform,
    setSelectedPlatform,
    clearAllFilter,
    expanded,
    setExpanded,
    sendCredits,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isData, setIsData] = useState(false);
  const [isDataSet, setIsDataSet] = useState(false);

  const sortField = {
    instagram: "engagements",
    tiktok: "followers",
    youtube: "followers",
  };

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    getFilteredData();
  }, [isChanged, sortCol]);

  const getFilteredData = async () => {
    setTableDataLoaded(false);
    try {
      const { status, response } = await fetchDataByPlatform(
        payload,
        sortCol,
        sortField,
        selectedPlatform,
        tablePageNo,
        tableRowsPerPage,
        customCols,
        dispatch,
        user
      );
      if (status === 200 && response) {
        setSelectedItem(response);

        await checkForCreditValue();
        setIsData(!isDataSet);
        setIsDataSet(!isDataSet);
        setTableDataLoaded(true);
      }
    } catch (err) {
      setIsData(false);
    }
    setTableDataLoaded(true);
  };

  const checkForCreditValue = async () => {
    const data = {
      name: ["Discovery.Analyze", "Discovery.Discover"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const credits = parseInt(response?.response.message[data.name[0]]);
    const discoverCredits = parseInt(response?.response.message[data.name[1]]);
    sendCredits({ credits, discoverCredits });
  };

  return (
    <>
      {setSelectedData(selectedItem)}
      {setTableDataUpdated(isData)}
      <Accordion
        expanded={expanded === "panel1"}
        sx={{ my: 2, borderRadius: "6px", border: "1px solid transparent" }}
        onChange={() => setExpanded("panel1")(!expanded)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            More Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography my={2}>Audience:</Typography>
            <Box>
              <Filters
                setSelectedData={setSelectedData}
                setTableDataUpdated={setTableDataUpdated}
                selectedPlatform={selectedPlatform}
                customCols={customCols}
                setCustomCols={setCustomCols}
                tableDataLoaded={tableDataLoaded}
                setTableDataLoaded={setTableDataLoaded}
                tablePageChanged={tablePageChanged}
                tableRowsPerPageChanged={tableRowsPerPageChanged}
                tableRowsPerPage={tableRowsPerPage}
                tablePageNo={tablePageNo}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                resetPageNo={resetPageNo}
                setTablePageNo={setTablePageNo}
                payload={payload}
                setPayload={setPayload}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                selectedCol={selectedCol}
                setSelectedCol={setSelectedCol}
                sortCol={sortCol}
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
              />
            </Box>
          </Box>
          <Box>
            <Typography my={2}>Influencers:</Typography>
            <Box>
              <Filters
                setSelectedData={setSelectedData}
                setTableDataUpdated={setTableDataUpdated}
                selectedPlatform={selectedPlatform}
                customCols={customCols}
                setCustomCols={setCustomCols}
                tableDataLoaded={tableDataLoaded}
                setTableDataLoaded={setTableDataLoaded}
                tablePageChanged={tablePageChanged}
                tableRowsPerPageChanged={tableRowsPerPageChanged}
                tableRowsPerPage={tableRowsPerPage}
                tablePageNo={tablePageNo}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                resetPageNo={resetPageNo}
                setTablePageNo={setTablePageNo}
                payload={payload}
                setPayload={setPayload}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                selectedCol={selectedCol}
                setSelectedCol={setSelectedCol}
                sortCol={sortCol}
                isInfFilter
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
              />
            </Box>
          </Box>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  color: "#000000",
                  border: "1px solid lightgrey",
                  whiteSpace: "nowrap",
                  marginTop: "1rem",
                  "&:hover": {
                    border: "1px solid lightgrey",
                    bgcolor: "lightgrey",
                  },
                }}
                onClick={() => clearAllFilter()}
              >
                CLEAR FILTERS
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ marginTop: "1rem" }}
                variant="contained"
                onClick={() => {
                  setIsChanged(!isChanged);
                  setExpanded("panel1")(false);
                }}
                fullWidth
              >
                APPLY
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MoreFilters;
