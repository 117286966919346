export const tableHeaders = [
  {
    id: 1,
    label: "Task Title",
    mappedValue: "Task Title",
    field: "title",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },

  {
    id: 2,
    label: "Milestone",
    mappedValue: "Milestone",
    field: "milestone",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: "Group Name",
    mappedValue: "Group Name",
    field: "groupName",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: "Total Influencers",
    mappedValue: "Total Influencers",
    field: "totalInfluencers",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: "Due Date",
    mappedValue: "Due Date",
    field: "dueDate",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 6,
    label: "Priority",
    mappedValue: "Priority",
    field: "priority",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
    {
      id: 7,
      label: "",
      mappedValue: "",
      field: "action",
      headeralignment: "left",
      bodyalignment: "left",
      sortable: false,
      show: true,
    },
];

export const subTableHeaders = [
  {
    id: 1,
    label: "Influencer Name",
    mappedValue: "Influencer Name",
    field: "infName",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },

  {
    id: 2,
    label: "Status",
    mappedValue: "Status",
    field: "status",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: "",
    mappedValue: "",
    field: "chip",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
];
