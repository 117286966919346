import config from "../config/main.json";
import { createSignedRequest, requestConfig } from "./common";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const loginUser = async (email, password) => {
  const body = JSON.stringify({ email, password });

  const response = await fetch(`${config.HEIMDALL_HOST}/login`, {
    method: "POST",
    headers,
    body,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const sendOtp = async (email) => {
  const body = JSON.stringify({ email });

  const response = await fetch(`${config.HEIMDALL_HOST}/handleOtp`, {
    method: "POST",
    headers,
    body,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const verifyOtp = async (email, otp) => {
  const body = JSON.stringify({ email, otp });

  const response = await fetch(`${config.HEIMDALL_HOST}/verifyOtp`, {
    method: "POST",
    headers,
    body,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getMenuItemsData = async (body, user) => {
  const request = {
    hostname: config.ORYX_HOSTNAME,
    method: "POST",
    url: `${config.API_HOST}/menus`,
    data: JSON.stringify(body),
  };
  const signedRequest = createSignedRequest(request, user);
  const response = await fetch(
    signedRequest.url,
    requestConfig(request, signedRequest)
  );
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const verifyUser = async (email, password) => {
  const body = JSON.stringify({ email, password });

  const response = await fetch(`${config.HEIMDALL_HOST}/verifyUser`, {
    method: "POST",
    headers,
    body,
  });
  return {
    status: response.status,
    response: await Promise.resolve(response.json()),
  };
};

const getUserDetails = async (email) => {
  const body = JSON.stringify({ email });

  const response = await fetch(`${config.HEIMDALL_HOST}/getUserDetails`, {
    method: "POST",
    headers,
    body,
  });
  return {
    status: response.status,
    response: await Promise.resolve(response.json()),
  };
};

export {
  loginUser,
  getMenuItemsData,
  verifyUser,
  getUserDetails,
  sendOtp,
  verifyOtp,
};
