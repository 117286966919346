import AppLayout from "../layouts/App/AppLayout";
import MainLayout from "../layouts/MainLayout";
import InviteNewUsers from "../pages/Account/InviteNewUsers";
import Users from "../pages/Account/Users";
import AudienceInsights from "../pages/campaign/AudienceInsights";
import Campaign from "../pages/campaign/Campaign";
import CampaignAnalytics from "../pages/campaign/CampaignAnalytics";
import CampaignGroups from "../pages/campaign/CampaignGroups";
import CampaignInfluencers from "../pages/campaign/CampaignInfluencers";
import CampaignLinks from "../pages/campaign/CampaignLinks";
import CampaignOverview from "../pages/campaign/CampaignOverview";
import CampaignPublications from "../pages/campaign/CampaignPublications";
import ForgotPassword from "../pages/ForgotPassword";
import AccountVerify from "../pages/AccountVerify";
import Login from "../pages/Login";
import MyProfile from "../pages/Settings/MyProfile";
import PasswordReset from "../pages/PasswordReset";
import General from "../pages/Settings/General";
import Integrations from "../pages/Settings/Integrations";
import CampaignBriefLayout from "../layouts/CampaignBrief/CampaignBriefLayout";
import CampaignBriefForm from "../pages/campaignBrief/CampaignBriefForm";
import IMAIDiscovery from "../components/imaiDiscovery/Discovery";
import AnalyzeProfile from "../components/imaiDiscovery/components/AnalyzeProfile/AnalyzeProfile";
import CreateCampaign from "../pages/campaign/CreateCampaign";
import CreateCampaignPublications from "../pages/campaign/CreateCampaignPublications";
import SignUp from "../pages/SignUp";
import LandingLayout from "../layouts/App/LandingLayout/LandingLayout";
import Landing from "../pages/Landing/Landing";
import RequestDemoLayout from "../layouts/App/RequestDemo/RequestDemoLayout";
import RequestDemoForm from "../pages/RequestDemo/RequestDemoForm";
import DiscoverAndAnalyze from "../pages/Landing/DiscoverAndAnalyze";
import ContractsAndActivate from "../pages/Landing/ContractsAndActivate";
import TrackAndManageCampaigns from "../pages/Landing/TrackAndManageCampaigns";
import MonitorAndPromoteUGC from "../pages/Landing/MonitorAndPromoteUGC";
import Attribution from "../pages/Landing/Attribution";
import Pricing from "../pages/Landing/Pricing";
import PrivacyPolicy from "../components/staticPages/PrivacyPolicy";
import StaticPageLayout from "../layouts/App/StaticPageLayout";
import IPrivacyPolicy from "../components/staticPages/IPrivacyPolicy";
import PaymentDone from "../pages/PaymentDone";
import Help from "../pages/Landing/Help";
import TermsOfService from "../components/staticPages/TermsOfService";
import AdvanceAnalytics from "../pages/campaign/components/AdvanceAnalytics";
import UnderMaintenance from "../components/common/UnderMaintenance";
import ExportList from "../components/imaiDiscovery/ExportList";
import SentimentAnalytics from "../pages/campaign/SentimentAnalytics";
import Subscribe from "../pages/Subscribe";
import BillingInformation from "../pages/Billing/BillingInformation";
import SubscriptionReactivated from "../components/subscribe/SubscriptionReactivated";
import CampaignDynamicLinks from "../pages/campaign/CampaignDynamicLinks";
import CreateCampaignGroups from "../pages/campaign/CreateCampaignGroups";
import CreditLedger from "../pages/CreditLedger/CreditLedger";
import CampaignDrafts from "../pages/campaign/CampaignDrafts";
import UserActionLogs from "../pages/Account/UserActionLogs";
import CampaignGa from "../pages/campaign/CampaignGa";
import CampaignContracts from "../pages/campaign/CampaignContracts";
import EditTemplate from "../components/campaign/campaignAnalytics/campaignContracts/contractActions/EditTemplate";
import CreateTemplate from "../components/campaign/campaignAnalytics/campaignContracts/contractActions/CreateTemplate";
import SendContract from "../components/campaign/campaignAnalytics/campaignContracts/contractActions/SendContract";
import CampaignIdentPublications from "../pages/campaign/CampaignIdentPublications";
import CampaignWizardStepper from "../components/campaign/newCampaign/CampaignWizardStepper";
import BaseServices from "../pages/Landing/Services";
import BaseAbout from "../pages/Landing/About";
import Analyze from "../components/imaiDiscovery/AnalyzeInfluencer";
import DiscoveryBase from "../components/imaiDiscovery/DiscoveryBase";
import HistoryData from "../components/imaiDiscovery/FilterComponents/HistoryData";
import AddCampaignGroup from "../components/campaign/campaignAnalytics/campaignGroups/add/AddCampaignGroup";
import Home from "../components/dashboard/home";
import AddCampaignInfluencer from "../components/campaign/campaignAnalytics/campaignInfluencers/addInfluencers/AddCampaignInfluencer";
import CampaignReport from "../components/campaign/campaignAnalytics/campaignReport/CampaignReport";
import CampaignTasks from "../pages/campaign/CampaignTasks";

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      { index: true, element: <Landing /> },
      { path: "discoverAndAnalyze", element: <DiscoverAndAnalyze /> },
      { path: "contractsAndActivate", element: <ContractsAndActivate /> },
      { path: "trackAndManageCampaigns", element: <TrackAndManageCampaigns /> },
      { path: "monitorAndPromoteUGC", element: <MonitorAndPromoteUGC /> },
      { path: "attribution", element: <Attribution /> },
      { path: "pricing", element: <Pricing /> },
      { path: "subscribe", element: <Subscribe /> },
      { path: "contact", element: <Help /> },
      { path: "reactivate", element: <SubscriptionReactivated /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "passwordReset", element: <PasswordReset /> },
      { path: "forgotPassword", element: <ForgotPassword /> },
      { path: "accountVerify", element: <AccountVerify /> },
      { path: "signup", element: <SignUp /> },
      { path: "paymentDone", element: <PaymentDone /> },
      { path: "services", element: <BaseServices /> },
      { path: "about", element: <BaseAbout /> },
    ],
  },
  {
    path: "app",
    element: <AppLayout />,
    children: [
      {
        path: "dashboard",
        children: [{ index: true, element: <Home /> }],
      },
      {
        path: "campaigns",
        children: [
          { index: true, element: <Campaign /> }, //campaign landing page
          {
            path: "create",
            children: [
              { path: "", element: <CreateCampaign /> },
              { path: "wizard", element: <CampaignWizardStepper /> },
            ],
          }, //create campaign
          {
            path: "edit",
            children: [
              { path: "", element: <CreateCampaign /> },
              { path: "wizard", element: <CampaignWizardStepper /> },
            ],
          },
          { path: "overview", element: <CampaignOverview /> }, //overview of the campaign in campaign analysis
          { path: "reports", element: <CampaignReport /> }, //CampaignReport of the campaign in campaign analysis
          {
            path: "analytics",
            children: [
              { path: "snapshot", element: <CampaignAnalytics /> }, //analytics of the campaign in campaign analysis
              { path: "audience", element: <AudienceInsights /> }, //audience insights of the campaign in campaign analysis
              { path: "sentiment", element: <SentimentAnalytics /> }, //sentiment analytics of the campaign in campaign analysis
              { path: "clicks", element: <CampaignLinks /> }, //short urls of the campaign in campaign analysis
              { path: "firebase", element: <CampaignDynamicLinks /> }, //short urls of the campaign in campaign analysis
              { path: "google", element: <CampaignGa /> }, //google anaytics in campaign analysis
            ],
          },
          {
            path: "influencers",
            children: [
              { path: "all", element: <CampaignInfluencers /> }, //influencers in the campaign in campaign analysis
              { path: "groups", element: <CampaignGroups /> }, //groups of the campaign in campaign analysis
              { path: "groups/create", element: <AddCampaignGroup /> }, // creating a group
              { path: "AddInfluencer", element: <AddCampaignInfluencer /> },
              { path: "contracts", element: <CampaignContracts /> }, //contracts of the influencers in campaign analysis
              { path: "contracts/edit", element: <EditTemplate /> }, //edit contract template of the campaign in campaign analysis
              { path: "contracts/create", element: <CreateTemplate /> }, //create contract template of the campaign in campaign analysis
              { path: "contracts/send", element: <SendContract /> }, //send contract of the campaign in campaign analysis
              {
                path: "groups/create",
                element: <CreateCampaignGroups />,
              },
            ],
          },
          {
            path: "publications",
            children: [
              { path: "published", element: <CampaignPublications /> }, //publications added in the campaign in campaign analysis
              {
                path: "published/create",
                element: <CreateCampaignPublications />,
              },
              { path: "drafts", element: <CampaignDrafts /> }, //drafts added in the campaign in campaign analysis
              { path: "identified", element: <CampaignIdentPublications /> }, //publications added in the campaign in campaign analysis
              { path: "tasks", element: <CampaignTasks /> }, //publications added in the campaign in campaign analysis
            ],
          },
          { path: "bi-dashboard", element: <AdvanceAnalytics /> }, //advance analytics of the campaing in campaign analysis
        ],
      },
      {
        path: "account",
        children: [
          { path: "users", element: <Users /> },
          { path: "inviteNewUsers", element: <InviteNewUsers /> },
          { path: "billing", element: <BillingInformation /> },
          { path: "credit-ledger", element: <CreditLedger /> },
          { path: "userLogs", element: <UserActionLogs /> },
        ],
      },
      {
        path: "settings",
        children: [
          { path: "general", element: <General /> },
          { path: "myProfile", element: <MyProfile /> },
          { path: "integrations", element: <Integrations /> },
        ],
      },
      {
        path: "discovery",
        children: [
          { index: true, element: <DiscoveryBase /> },
          { path: "analyze/:platform/:id", element: <AnalyzeProfile /> },
          { path: "exports", element: <ExportList /> },
          { path: "history/:type", element: <HistoryData /> },
          { path: "analyze/influencer", element: <Analyze /> },
        ],
      },
    ],
  },
  {
    path: "campaignBrief",
    element: <CampaignBriefLayout />,
    children: [{ index: true, element: <CampaignBriefForm /> }],
  },
  {
    path: "requestDemo",
    element: <RequestDemoLayout />,
    children: [{ index: true, element: <RequestDemoForm /> }],
  },
  {
    path: "page",
    element: <StaticPageLayout />,
    children: [
      { path: "privacyPolicy", element: <PrivacyPolicy /> },
      { path: "influencerPrivacyPolicy", element: <IPrivacyPolicy /> },
      { path: "termsOfService", element: <TermsOfService /> },
    ],
  },
  {
    path: "maintenance",
    element: <UnderMaintenance />,
  },
];

export default routes;
