import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Typography,
  Tooltip
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SecondaryStyledMenu from "./common/SecondaryStyledMenu";
import TuneIcon from "@mui/icons-material/Tune";

const MAX = 5;

const EditColumns = ({ tableRowHeaders, setFinalTableRowHeaders }) => {
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const final = [];
    const selected = [];
    tableRowHeaders.forEach((header) => {
      if (["Account", ""].includes(header.label)) {
        final.push(header);
      } else if (selected?.length < MAX) {
        selected.push(header.id);
        final.push(header);
      }
    });
    setSelected(selected);
    setFinalTableRowHeaders(final);
  }, [tableRowHeaders]);

  const handleCheck = (id) => {
    const prev = [...selected];
    const index = prev.indexOf(id);
    if (index === -1) {
      prev.push(id);
    } else {
      if (selected?.length <= 1) return;
      prev.splice(index, 1);
    }
    const final = tableRowHeaders?.filter((header) => {
      if (["Account", ""].includes(header.label) || prev.includes(header.id))
        return true;
      else return false;
    });
    setSelected(prev);
    setFinalTableRowHeaders(final);
  };

  return (
    <>
      <Tooltip title="Edit Columns" arrow>
        <Button
          sx={{
            color: "black",
            background: "white",
            border: "1px solid lightgrey",
            borderRadius: "12px",
            "&:hover": { bgcolor: "lightgray" },
            px: "8px",
            justifyContent: "center",
            mr: "1rem",
          }}
          id="styled-menu"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
        >
          <TuneIcon />
        </Button>
      </Tooltip>
      <SecondaryStyledMenu
        id="styled-menu"
        MenuListProps={{
          "aria-labelledby": "styled-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography sx={{ fontWeight: "bold", mx: "16px", my: "8px" }}>
          Select up to{" "}
          {tableRowHeaders?.length - 2 < MAX ? tableRowHeaders?.length - 2 : MAX}{" "}
          fields:
        </Typography>
        <FormGroup>
          {tableRowHeaders?.map((header) => {
            if (["Account", ""].includes(header.label)) return null;
            else {
              return (
                <MenuItem>
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    key={header.id}
                    control={
                      <Checkbox
                        checked={selected.includes(header.id)}
                        disabled={
                          !selected.includes(header.id) &&
                          selected?.length >= MAX
                        }
                        onClick={() => handleCheck(header.id)}
                      />
                    }
                    label={header.label}
                  />
                </MenuItem>
              );
            }
          })}
        </FormGroup>
      </SecondaryStyledMenu>
    </>
  );
};

export default EditColumns;
