import { func, string } from "prop-types";
import InviteByList from "./InviteByList";
import InviteByEmail from "./InviteByEmail";

const InviteLayout = ({ typeOfInvite, goback, groupList, campaignName , isModal}) => {
  const setNavigateBack = (event) => {
    goback(event);
  };
  return (
    <div style={{marginTop: "50px"}}>
    {typeOfInvite === "list" && (
        <InviteByList setNavigateBack={setNavigateBack} groupList={groupList} campName={campaignName} isModal={isModal} />
      )}
      {typeOfInvite === "email" && (
        <InviteByEmail setNavigateBack={setNavigateBack} groupList={groupList} campName={campaignName} isModal={isModal} />
      )}    
    </div>
  );
};

InviteLayout.propTypes = {
  typeOfInvite: string.isRequired,
  goback: func.isRequired,
};
export default InviteLayout;
