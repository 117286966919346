import {
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  IconButton,
  Modal,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../../common/StyledComponents";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import TaskForm from "./TaskForm";
import {
  getTasksListForCampaign,
  updateAssigneeStatus,
} from "../../../../services/tasks";
import { useDispatch, useSelector } from "react-redux";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import Loader from "../../../common/loader";
import CustomizedTable from "../../../common/customizedTable";
import { subTableHeaders, tableHeaders } from "./CommonAssets";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import { taskPriorityColor } from "../../../../utility/status";
import {
  ChevronRightOutlined,
  Circle,
  Edit,
  KeyboardArrowDown,
  RemoveRedEye,
  ViewAgenda,
} from "@mui/icons-material";
import { getDraftDetails } from "../../../../services/publication";
import ApprovalDialog from "../campaignDrafts/draftActions/ApprovalDialog";
import SearchItem from "../../../common/searchitem";
import StatusDropdown from "./StatusDropdown";
import { notify } from "../../../../redux/features/system/systemAlert";

const CampaignTasksSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);
  const [formType, setFormType] = React.useState("Create");
  const [taskList, setTaskList] = React.useState([]);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("t.createdAt");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [taskId, setTaskId] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(10);
  const [expand, setExpand] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [postDetail, setPostDetail] = React.useState({});
  const [viewApproval, setViewApproval] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [postIdSelected, setPostIdSelected] = React.useState();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cid = params.get("id");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    getAllTasks(page, rows, sortOrder, sortCol, filter);
    setOpen(false);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust the width as needed
    backgroundColor: "white",
    boxShadow: 24,
    width: "750px",
  };

  React.useEffect(() => {
    getAllTasks(page, rows, sortOrder, sortCol, filter);
  }, []);

  React.useEffect(() => {
    if (expand?.length === 0 || taskList?.length === 0) {
      return;
    }
    createData(taskList);
  }, [expand, taskList]);

  const handlePostReviewModalClose = () => {
    setViewApproval(false);
  };

  const getAllTasks = async (page, rows, sortOrder, sortCol, filter) => {
    setLoading(true);
    setDataFound(false);
    var data = {
      cid: +cid,
      page: page + 1,
      rows,
      sortCol,
      sortOrder,
      filter: filter,
      account: user.agencyId,
      client: 0,
      timezone: "Asia/Calcutta",
      status: 1,
    };
    const response = await getTasksListForCampaign(data, user, dispatch);
    const {
      response: { campaignList, total_records },
      status,
    } = response;
    if (status && campaignList) {
      setTaskList(campaignList);
      setTotalRows(total_records);
      setDataFound(true);
      setLoading(false);
      createData(campaignList);
      const expandArr = Array.apply(null, Array(campaignList?.length)).map(
        function () {
          return false;
        }
      );
      setExpand(expandArr);
    } else {
      setDataFound(false);
      setLoading(false);
    }
  };
  const expandClick = (row, index) => {
    setSelectedRow(row);
    setSelectedIndex(index);
    changeExpansionArray(index);
  };

  const changeExpansionArray = (index) => {
    const newExpandedRows = [...expand];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpand(newExpandedRows);
  };

  const reviewPost = async (postId) => {
    setPostIdSelected(postId);
    const response = await getDraftDetails(postId, user, dispatch);
    const postDetail = response.response;
    if (postDetail != null) {
      setPostDetail(postDetail);
      setViewApproval(true);
    }
  };
  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);

      const newExpand = expand?.map(() => false);
      setExpand(newExpand);
      getAllTasks(
        event.page,
        event.rowsPerPage,
        event.order,
        event.orderBy,
        filter
      );
    }
  };

  const sendSearchedValue = (event) => {
    setTimeout(() => {
      getAllTasks(page, rows, sortOrder, sortCol, event);
    }, 1000);
  };

  const handleEditOpen = (taskId) => {
    setFormType("View");
    setTaskId(taskId);
    handleOpen();
  };

  const handleStatusChange = async (newValue, rowId, tiId) => {
    // Update the status in your data source (e.g., API call or state update)
    // For demonstration purposes, we'll update the local data array here:
    const payload = { tiId: parseInt(tiId), status: newValue };
    const reponse = await updateAssigneeStatus(payload, user, dispatch);
    // You can use updatedData to update your data source or state
  };
  const createData = (records) => {
    const columns = [];
    records.forEach((data, index) => {
      const circleColor = taskPriorityColor[data.priority];
      const isExpanded = expand[index];
      const influencerList = data.infName?.split(",");
      const statusList = data.status?.split(",");
      const expandedArray = [];

      influencerList.forEach((influencer, idx) => {
        const buttonText =
          statusList[idx] === "Reassigned"
            ? "View Original Draft"
            : "View Draft";
        let expansionObject = {
          infName: influencer,
          status: (
            <StatusDropdown
              value={statusList[idx]}
              rowId={idx}
              tiId={data.tiId} // Assuming tiId is the third column
              onChange={handleStatusChange}
            />
          ),
          chip: (
            <>
              {!data.postId ? (
                <Grid item xs={6}>
                  <Tooltip
                    title={"The influencer has not submitted a post yet."}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        borderRadius: "22.5px",
                        color: "#464e5f",
                        cursor: "help",
                      }}
                    >
                      Waiting for a post
                    </Button>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      borderRadius: "22.5px",
                      color: "#464e5f",
                    }}
                    onClick={() => reviewPost(data.postId)}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              )}
            </>
          ),
        };
        expandedArray.push(expansionObject);
      });

      let object = {
        id: data.id,
        selectedId: index,
        title: (
          <Grid container spacing={1}>
            <Grid item xs={1} style={{ margin: "auto" }}>
              <IconButton onClick={() => expandClick(data, index)}>
                {isExpanded ? <KeyboardArrowDown /> : <ChevronRightOutlined />}
              </IconButton>
            </Grid>
            <Grid
              item
              xs={11}
              className={styles.bodycell}
              style={{ margin: "auto" }}
            >
              {data.title}
            </Grid>
          </Grid>
        ),
        milestone: (
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              className={styles.bodycell}
              style={{ marginTop: 5 }}
            >
              {data.milestone}
            </Grid>
          </Grid>
        ),
        groupName: (
          <Grid container spacing={4}>
            <Grid item className={styles.bodycell}>
              <Typography sx={textColor[data.platform]}>
                {snapshotHeaderIcons[data.platform]}
              </Typography>
            </Grid>
            <Grid item className={styles.bodycell} style={{ marginTop: 5 }}>
              {data.listName}
            </Grid>
          </Grid>
        ),
        totalInfluencers: (
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.bodycell}>
              {data.iid.split(",").length}
            </Grid>
          </Grid>
        ),

        dueDate: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={8} className={styles.bodycell}>
              {data.enddate}
            </Grid>
          </Grid>
        ),
        priority: (
          <Grid container spacing={1}>
            <Grid item xs={3} className={styles.bodycell}>
              <Circle
                htmlColor={circleColor}
                sx={{ width: "20px", height: "20px" }}
              />
            </Grid>
            <Grid
              item
              xs={9}
              className={styles.bodycell}
              style={{ paddingTop: "12px" }}
            >
              {capitalizeFirstLetter(data.priority)}
            </Grid>
          </Grid>
        ),
        action: (
          <IconButton
            id={"Edit-" + data.id}
            onClick={() => {
              handleEditOpen(data.id);
            }}
            // onClick={this.handleClickOpen.bind(this, tableMeta.rowData[0] - 1)}
          >
            <Tooltip title={"View Task"}>
              <RemoveRedEye fontSize="small" />
            </Tooltip>
          </IconButton>
        ),
        expand: [
          {
            colSpan: 7,
            content: (
              <>
                <CustomizedTable
                  tableHeaders={subTableHeaders}
                  bodyColumns={expandedArray}
                  totalRows={expandedArray?.length}
                  pageNo={0}
                  rowsPerPageNo={expandedArray?.length}
                  sortOrder={"desc"}
                  sortOrderBy={"influencer"}
                  pagination={false}
                  nested={true}
                />
              </>
            ),
          },
        ],
      };
      columns.push(object);
    });
    setBodyColumns(columns);
  };

  const handleRowClick = () => {
    // changeExpansionArray(selectedRow, selectedIndex);
  };

  const closeApproval = (refresh) => {
    setViewApproval(false);
    if (refresh) {
      getAllTasks(page, rows, sortOrder, sortCol);
    }
  };

  return (
    <>
      <Box m={2}>
        <Stack direction="row" justifyContent="space-between">
          <SearchItem sendSearchedValue={sendSearchedValue} />

          <StyledStaticButtonGroup
            color="primary"
            exclusive
            aria-label="Campaign"
            size="small"
          >
            <StyledStaticButton
              value="active"
              className={styles.staticButton}
              onClick={() => {
                setFormType("Create");
                handleOpen();
              }}
            >
              <b>+ Create Task</b>
            </StyledStaticButton>
          </StyledStaticButtonGroup>
        </Stack>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent backdrop click from closing the modal
        }}
      >
        <Box sx={modalStyle}>
          <TaskForm
            cid={cid}
            handleClose={handleClose}
            open={open}
            setOpen={setOpen}
            formType={formType}
            setFormType={setFormType}
            taskId={taskId}
          />
        </Box>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-post_review-label"
        aria-describedby="modal-post_review-description"
        open={viewApproval}
        onClose={handlePostReviewModalClose}
        closeAfterTransition
      >
        <>
          <ApprovalDialog
            draft={postDetail}
            draftIds={[postIdSelected]}
            iid={postDetail.iid}
            viewApproval={viewApproval}
            closeApproval={closeApproval}
          />
        </>
      </Modal>
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <div className={styles.background}>
            {/* <p>
              Showing {start} to {end} of {totalRows} groups
            </p> */}
            <CustomizedTable
              tableHeaders={tableHeaders}
              bodyColumns={bodyColumns}
              expand={expand}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
              selectedRow={selectedRow}
              sendInformation={sendInformation}
              staticTable={true}
              onRowClick={handleRowClick}
            />
          </div>
        </>
      ) : (
        <div className={styles.container} style={{ textAlign: "center" }}>
          <div className={styles.child}> No tasks added on this campaign</div>
        </div>
      )}
    </>
  );
};

export default CampaignTasksSnapshot;
