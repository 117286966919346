import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InviteSelection from "./InviteSelection";
import InviteLayout from "./InviteLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../../redux/features/user/userSlice";
import { notify } from "../../../../../redux/features/system/systemAlert";
import { getCampaignName } from "../../../../../utility/sessionData";
import { getAllGroupsListForCampaign } from "../../../../../services/group";

const AddCampaignInfluencer = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState("");
  const isModal = props && Object.keys(props).length > 0;
  const [groupList, setGroupList] = React.useState([]);
  const [campaignName] = React.useState(getCampaignName);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");
  const universal = params.get("universal");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = [
    "How do you want to invite the influencer?",
    "Send out those invites",
  ];

  useEffect(() => {
    getGroupList("all");
  }, []);

  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(params.get("id"), platforms, user, dispatch);
    if (groups.status === true) {
      setGroupList(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const handleBack = () => {
    navigate(`/app/campaigns/overview?id=${campaignId}&universal=${universal}`);
  };

  const moveToNextStep = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const selectedValue = (value) => {
    moveToNextStep();
    setType(value);
  };

  const goback = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  return (
    <>
      <Box m={2} style={{ backgroundColor: "#FAFAFB", padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isModal &&
              <div>
                <h3
                  style={{ fontWeight: "bold", textAlign: "center", paddingTop: "5px" }}
                >
                  Invite Influencers
                </h3>
                <br />
              </div>}
            <div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === 0 && (
                  <InviteSelection selectedValue={selectedValue} />
                )}
                {activeStep === 1 && (
                  <InviteLayout typeOfInvite={type} goback={goback} groupList={props.groupList || groupList} campaignName={props.campaignName || campaignName} isModal={isModal} />
                )}
              </div>

              {!isModal && activeStep === 0 &&
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                      marginBottom: "20px",
                      marginRight: "20px"
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
              }
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}


export default AddCampaignInfluencer;