import { Button, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { sendXlsx } from "../../../../services/campaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import CampaignLinkGraphs from "./CampaignLinkGraphs";
import Loader from "../../../common/loader";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignLinksTable from "./CampaignLinksTable";
import { Card, Typography } from "@mui/material";
import moment from "moment";

const CampaignLinksMetricsSnapshot = ({
  selectedShorturls,
  selectedStartDate,
  selectedEndDate,
  loading,
  dataFound,
  linkClicksY,
  linkClicksX,
  devicesData,
  referresData,
  locationData,
  worldData,
  cityData,
  osData,
  browserData
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [totalUrlsCreated, setTotalUrlsCreated] = useState(0);
  const [shortUrlsClicked, setShortUrlsClicked] = useState(0);
  const [averageClicksPerUrl, setAverageClicksPerUrl] = useState(0);

  const exportXlsx = async () => {
    const exportResponse = await sendXlsx(
      selectedShorturls?.map((value) => value.id),
      getFormattedDate(selectedStartDate, "YYYY-MM-DD"),
      getFormattedDate(selectedEndDate, "YYYY-MM-DD"),
      user,
      dispatch,
      "link/analysis/sendxlsx"
    );

    if (exportResponse.status) {
      dispatch(
        notify({
          message: `The excel file containing link details will be sent to ${user.email} within few mins`,
          type: "success",
        })
      );
    }
  };
  useEffect(() => {
    if (dataFound && selectedShorturls?.length > 0 && linkClicksY?.length > 0) {
      // Total URLs Created
      setTotalUrlsCreated(selectedShorturls.length);

      // Short URLs Clicked
      const totalClicks = linkClicksY.reduce((acc, curr) => acc + curr, 0);
      setShortUrlsClicked(totalClicks);

      // Average Clicks per URL
      setAverageClicksPerUrl(totalClicks / selectedShorturls.length);
    } else {
      setTotalUrlsCreated(0);
      setShortUrlsClicked(0);
      setAverageClicksPerUrl(0);
    }
  }, [dataFound, selectedShorturls, linkClicksY]);

  return (
    <Grid container spacing={2} mt="16px">
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : dataFound ? (
          <Grid container spacing={2}>
            {dataFound && (
              <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Card style={{ padding: 20 }}>
                  <Grid container spacing={2}>
                    {/* First Row: Selected Date Range */}
                    <Grid item xs={6} sm={3}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Selected Date Range
                      </Typography>
                      <Typography variant="body2">
                        {`${moment(selectedStartDate).format("MMM DD, YYYY")} - ${moment(selectedEndDate).format("MMM DD, YYYY")}`}
                      </Typography>
                    </Grid>
            
                    {/* Total URLs Created */}
                    <Grid item xs={6} sm={3}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Total URLs Created
                      </Typography>
                      <Typography variant="h6">{totalUrlsCreated}</Typography>
                    </Grid>
            
                    {/* Short URLs Clicked */}
                    <Grid item xs={6} sm={3}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Short URLs Clicked
                      </Typography>
                      <Typography variant="h6">{shortUrlsClicked}</Typography>
                    </Grid>
            
                    {/* Avg. Clicks per URL */}
                    <Grid item xs={6} sm={3}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Avg. Clicks
                      </Typography>
                      <Typography
                        variant="h6"
                        color={averageClicksPerUrl < 50 ? "error.main" : "success.main"} 
                      >
                        {averageClicksPerUrl ? averageClicksPerUrl.toFixed(2) : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            )}
            <CampaignLinkGraphs
              linkClicksY={linkClicksY}
              linkClicksX={linkClicksX}
              devicesData={devicesData}
              referresData={referresData}
              locationData={locationData}
              worldData={worldData}
              cityData={cityData}
              osData={osData}
              browserData={browserData}
            />
            <AdjustCampaignDates />
            {selectedShorturls?.length > 0 && (
              <Grid item xs={12} sx={{ padding: '0px !important', mt: 4 }}>
                <h4 style={{ paddingLeft: "20px" }}>
                  <b className={styles.title}>Link Details</b>
                </h4>
                <Button
                  variant="contained"
                  style={{
                    background:
                      "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                    borderRadius: "8px",
                    float: "right",
                  }}
                  onClick={exportXlsx}
                  disabled={selectedShorturls?.length === 0}
                >
                  Export XLSX
                </Button>
                <CampaignLinksTable
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  shorturlIds={selectedShorturls?.map((value) => value.id)}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.child}>
                {" "}
                No links added on this campaign
              </div>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CampaignLinksMetricsSnapshot;
