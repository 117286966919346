import { Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { storeDummyCampaignState } from "../../redux/features/user/userSlice";

const DummyCampaign = ({ universal }) => {
  const convertToBoolean = (value) => {
    if (value === 'true' || value === true) return true;
    if (value === 'false' || value === false) return false;
    return Boolean(value);
  };

  const [warning, setWarning] = useState(convertToBoolean(universal));
  const dispatch = useDispatch();

  useEffect(() => {
    setWarning(convertToBoolean(universal));
  }, [universal]);

  const handleWarning = () => {
    setWarning(false);
    dispatch(storeDummyCampaignState());
  };

  return (
    warning && (
      <div
        style={{
          position: "fixed",
          left: "40%",
          zIndex: 1000,
        }}
      >
        <Alert
          sx={{
            width: "35vw",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "#FEAB00",
            borderRadius: "10px",
          }}
          onClose={handleWarning}
          severity="warning"
          variant="filled"
        >
          This is a dummy campaign
        </Alert>
        <br />
      </div>
    )
  );
};

export default DummyCampaign;
