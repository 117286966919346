/* eslint-disable jsx-a11y/click-events-have-key-events */
import { EditorState, convertToRaw, ContentState } from "draft-js";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import sanitizeHtml from 'sanitize-html';
import { useEffect, useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "../../../../styles/internal/PostTextEditor.module.css";
import { FaMagic } from "react-icons/fa"; 

const PostTextEditor = ({
  placeholder,
  maxLength,
  handlePostInput,
  postContent,
  isReadOnly,
  charCount,
  setCharCount,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [textValueLength, setTextValueLength] = useState(charCount || 0);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [error, setError] = useState(null);
  const editorRef = useRef(null);
  const initialized = useRef(false); // Track initialization

  useEffect(() =>{}, [postContent])
  useEffect(() => {
    console.log("PostContent Updated:", postContent);
    if (postContent && !initialized.current) {
      const contentBlock = htmlToDraft(postContent);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        const plainText = contentState.getPlainText();
        setTextValueLength(plainText.length);
        setCharCount(plainText.length);
        initialized.current = true; // Mark as initialized
        console.log("Editor initialized with postContent.");
      }
    }
  }, [postContent, setCharCount]);

  useEffect(() => {
    console.log("Editor State Changed:", editorState);
    setCharCount(textValueLength);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    handlePostInput(markup);
  }, [textValueLength, editorState, handlePostInput, setCharCount]);

  const handlePastedText = (text, html, currentEditorState) => {
    console.log("Pasted Text:", text);
    const existingTextLength = currentEditorState
      .getCurrentContent()
      .getPlainText().length || 0;
    const remainingLength = maxLength - existingTextLength;
    const pastedText = text.slice(0, remainingLength);
    const totalLength = existingTextLength + pastedText.length;

    if (totalLength > maxLength) {
      setError(`You can only paste ${remainingLength} more characters.`);
      return 'handled';
    }

    const newContentState = ContentState.createFromText(
      currentEditorState.getCurrentContent().getPlainText() + pastedText
    );
    const newEditorState = EditorState.push(
      currentEditorState,
      newContentState,
      'insert-characters'
    );
    setEditorState(newEditorState);
    setTextValueLength(totalLength);
    return 'handled';
  };

  const handleInputChange = (state) => {
    const plainText = state.getCurrentContent().getPlainText();
    const textLength = plainText.length;
    setEditorState(state);
    setTextValueLength(textLength);
    setError(null); // Clear any previous errors on input
  };

  const handleEnhanceClick = async () => {
    if (isEnhancing) return; // Prevent multiple enhancements
    setIsEnhancing(true);
    setError(null);
    try {
      const plainText = editorState.getCurrentContent().getPlainText().trim();
      console.log("Enhancing text:", plainText);
      if (!plainText) {
        setError("Editor is empty. Please enter some text to enhance.");
        setIsEnhancing(false);
        return;
      }

      const payload = {
        action: "enhance_text",
        text_value: plainText,
        character_length: maxLength,
      };

      const response = await fetch(
        "https://bsylfsean5.execute-api.us-east-1.amazonaws.com/prod/enhanceText",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Received enhanced text:", data.response);

      if (data && data.response) { 
        let enhancedText = data.response;

        // Sanitize the enhanced text
        const sanitizedText = sanitizeHtml(enhancedText, {
          allowedTags: ['b', 'i', 'u', 'em', 'strong'],
          allowedAttributes: {
          },
          disallowedTagsMode: 'discard', 
        });

        const contentBlock = htmlToDraft(sanitizedText);
        if (contentBlock) {
          const newContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const newEditorState = EditorState.createWithContent(newContentState);
          setEditorState(newEditorState);
          const plainEnhancedText = newContentState.getPlainText();
          setTextValueLength(plainEnhancedText.length);
          console.log("Editor state updated with enhanced text.");
        }
      } else {
        throw new Error("Invalid response from API.");
      }
    } catch (err) {
      console.error("Error enhancing text:", err);
      setError("There was an issue enhancing your text. Please try again.");
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <div className={styles.postTextEditorContainer}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleInputChange}
        handlePastedText={handlePastedText}
        placeholder={placeholder}
        readOnly={isReadOnly || isEnhancing}
        spellCheck
        wrapperClassName={styles.textEditor}
        editorClassName={styles.editor}
        ref={editorRef}
        stripPastedStyles
        toolbar={{
          options: ["inline", "emoji"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
          },
        }}
      />
      <div className={styles.editorFooter}>
        <div className={styles.charCount}>
          Characters Remaining: {maxLength - textValueLength} / {maxLength}
        </div>
        <div className={styles.buttonGroup}>
          <button
            type="button"
            className={styles.enhanceButton}
            onClick={handleEnhanceClick}
            disabled={isEnhancing || isReadOnly}
            title="Enhance Text"
          >
            {isEnhancing ? "Enhancing..." : <FaMagic className={styles.enhanceIcon} />}
          </button>
        </div>
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

PostTextEditor.propTypes = {
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  handlePostInput: PropTypes.func.isRequired,
  postContent: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  charCount: PropTypes.number,
  setCharCount: PropTypes.func,
};

export default PostTextEditor;
